import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { formatNumber } from '../../../../helpers/helpers'
import { ActivityIcon } from '../../ActivityIcon/ActivityIcon'
import { FitbitFineGrainedType, FitbitObjectItem } from '../../interfaces'
import { ActivityGraphSize } from '../ActivityGraph'

const LeftLabel = styled.div<{ $first: boolean }>`
  display: flex;
  padding: 0.25rem; 0.125rem;
  align-items: flex-start;
  gap: 0.25rem;
  position: absolute;
  left: calc(0.25rem + 15px);
  top: ${(props) => (props.$first ? 'calc(0.25rem + 25px)' : '0.25rem')};
  z-index: 1;
  border-radius: 0.125rem;
  background-color: var(--element-bg-80);
  pointer-events: none;
`
const PrimaryLabel = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 0.75rem;
`

const SubLabel = styled.span`
  color: var(--text-lighter);
  font-family: inter;
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 0.75rem;
`
const TotalLabel = styled.span`
  color: var(--green);
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 0.75rem;
  text-align: right;
`
const RightLabel = styled.div<{ $first: boolean }>`
  display: flex;
  padding: 0.25rem; 0.125rem;
  align-items: flex-start;
  gap: 0.25rem;
  position: absolute;
  right: 0.25rem;
  top: ${(props) => (props.$first ? 'calc(0.25rem + 25px)' : '0.25rem')};
  z-index: 1;
  border-radius: 0.125rem;
  background-color: var(--element-bg-80);
  pointer-events: none;
`

export const ActivityLabels = ({
  data,
  first,
  size
}: {
  data: FitbitObjectItem
  first: boolean
  size: ActivityGraphSize
}) => {
  const { t } = useTranslation()
  const getLabelText = () => {
    switch (data.type) {
      case FitbitFineGrainedType.azm:
        return size === ActivityGraphSize.small
          ? t('Activity')
          : t('Active Zone Minutes')
      case FitbitFineGrainedType.steps:
        return t('Steps')
      case FitbitFineGrainedType.distance:
        return t('Distance')
      case FitbitFineGrainedType.calories:
        return t('Calories')
      case FitbitFineGrainedType.floors:
        return t('Floors')
    }
  }
  return (
    <>
      <LeftLabel $first={first}>
        <ActivityIcon type={data.type} />
        <PrimaryLabel data-testid="primaryLabelText">
          {getLabelText()}
        </PrimaryLabel>
        <SubLabel>{t(data.leftLabel ?? '')}</SubLabel>
      </LeftLabel>
      <RightLabel $first={first}>
        <TotalLabel
          style={data.total > 0 ? {} : { color: 'var(--text-lighter)' }}
        >
          {data.total > 0
            ? formatNumber({
                value: data.total,
                decimals: data.type === FitbitFineGrainedType.distance ? 2 : 0
              })
            : '--'}
        </TotalLabel>
        <SubLabel>{t(data.rightLabel ?? '')}</SubLabel>
      </RightLabel>
    </>
  )
}

import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { OverviewResponse } from '../../../containers/Overview/Overview'
import dayjs from '../../../core/dayjs/dayjs'
import { Patient } from '../../../core/entities/patient.entity'
import { PeriodObject } from '../../Header/DevicesAndPeriod/DevicesAndPeriod'
import { GlucoseComponent } from './GlucoseComponent/GlucoseComponent'
import { InfusionComponent } from './InfusionComponent/InfusionComponent'

export enum OverviewGraphType {
  infusion,
  glucose
}
interface OverviewProps {
  days: number
}
interface GraphComponentProps {
  data: OverviewResponse | null
  period: PeriodObject | null
  type: OverviewGraphType
  patient: Patient
  handleUpdateOverview: () => void
}
const Entries = styled.div``
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
`
const GraphComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Days = styled.div`
  margin-left: 15px;
  height: 3rem;
`
const DaysRelative = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const Day = styled.div<OverviewProps>`
  font-family: inter;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--text-primary);
  position: absolute;
  left: ${(props) => 'calc(' + props.days + ' * 8.75rem + (8.75rem * 0.5))'};
  transform: translateX(-50%);
  bottom: 1rem;
  text-transform: capitalize;
`

interface GraphContainerProps {
  type: OverviewGraphType
  entries: JSX.Element[]
}
export const GraphContainer = ({ type, entries }: GraphContainerProps) => {
  const { t } = useTranslation()
  return (
    <GraphComponentWrapper>
      <Days>
        <DaysRelative>
          {[...Array(7).keys()].map((item) => (
            <Day key={item} days={item}>
              {t(
                dayjs(new Date())
                  .isoWeekday(item + 1)
                  .format('dddd')
              )}
            </Day>
          ))}
        </DaysRelative>
      </Days>
      <Entries
        data-testid={
          type === OverviewGraphType.infusion
            ? 'infusiongraphs'
            : 'glucosegraphs'
        }
      >
        {entries}
      </Entries>
    </GraphComponentWrapper>
  )
}

export const GraphComponent = ({
  data,
  period,
  type,
  patient,
  handleUpdateOverview
}: GraphComponentProps) => {
  const screenshotWrapper = useRef<HTMLDivElement | null>(null)
  const { t } = useTranslation()
  if (data && period) {
    return (
      <Wrapper ref={screenshotWrapper}>
        {type === OverviewGraphType.infusion ? (
          <InfusionComponent
            data={data}
            period={period}
            patient={patient}
            screenshotWrapper={screenshotWrapper}
            handleUpdateOverview={handleUpdateOverview}
          />
        ) : (
          <GlucoseComponent
            data={data}
            period={period}
            screenshotWrapper={screenshotWrapper}
          />
        )}
      </Wrapper>
    )
  } else {
    return <div>{t('NO DATA')}</div>
  }
}

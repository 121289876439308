import { faInfoCircle, faTrashCan } from '@fortawesome/pro-regular-svg-icons'
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useState } from 'react'
import BluePen from '../../assets/Pens/NovoPenBlue.png'
import GreyPen from '../../assets/Pens/NovoPenGrey.png'
import { NovoPenObject } from '../../core/api/getDoseRecommendations'
import { usePatientContext } from '../../core/contexts/patient-context'
import { useSessionContext } from '../../core/contexts/session-context'
import dayjs from '../../core/dayjs/dayjs'
import { UserType } from '../../core/entities/user.entity'
import { NovoPenMedicine } from '../Graphs/Insights/interfaces'
import {
  InformationLogoSize,
  InformationTooltip
} from '../InformationTooltip/InformationTooltip'
import { Modal } from '../Modal/Modal'
import { BatteryIcon } from './Battery/Battery'
import {
  ErrorComponent,
  NovoPenErrorCodes
} from './ErrorComponent/ErrorComponent'
import { FindDeviceId } from './FindDeviceId/FindDeviceId'
import {
  getMedicineString,
  MedicineImage,
  MedicineTag
} from './Medicine/Medicine'
import { MorePenInfo } from './MorePenInfo/MorePenInfo'
import { RemovePen } from './RemovePen/RemovePen'
const Wrapper = styled.div`
  padding: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  width: 48.9375rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  width: 100%;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: flex-start;
  flex: 33%;
`

const PenInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
  padding: 0.5rem;
  width: 100%;
`

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
`

const MiddleSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.25rem;
  width: 100%;
`

const BottomSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  flex: 33%;
`

const Key = styled.span`
  font-family: inter;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--text-lighter);
  line-height: 1.5rem;
`

const Value = styled.span`
  font-family: inter;
  font-weight: 600;
  font-size: 1rem;
  color: var(--text-primary);
  line-height: 1.5rem;
`

const ArchivedKey = styled(Key)`
  color: var(--text-lighter);
`
const ArchivedValue = styled(Value)`
  color: var(--text-lighter);
`
const StretchedRow = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
`

const Button = styled.div`
  display: flex;
  height: 2.25rem;
  padding: 0.75rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.75rem;
  background-color: var(--element-bg);
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background-color: var(--element-bg-dark);
  }
`

const InfoIcon = styled(FontAwesomeIcon)`
  color: var(--text-primary);
  font-size: 0.875rem;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`
const Title = styled.span`
  font-family: inter;
  font-weight: 700;
  font-size: 1.25rem;
  color: var(--text-primary);
  line-height: 1.5rem;
`
const TitleIconWrapper = styled.div`
  display: flex;
  height: 1.5rem;
  width: 1.5rem;
  justify-content: center;
  align-items: center;
  color: var(--brand-primary-color);
  &:hover {
    color: var(--brand-darker-color);
  }
`
const QuestionIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: inherit;
`

const TrashIcon = styled(InfoIcon)``

export enum PenImageSize {
  small,
  large
}

const PenVisual = styled.div<{ $size: PenImageSize }>`
  border-radius: 0.5rem;
  background-color: ${(props) =>
    props.$size === PenImageSize.large ? 'var(--element-bg)' : 'none'};
  width: ${(props) => (props.$size === PenImageSize.large ? '6rem' : '4rem')};
  height: ${(props) =>
    props.$size === PenImageSize.large ? '14.75rem' : '10.375rem'};
  position: relative;
`
const PenIMG = styled.img<{ $size: PenImageSize }>`
  width: ${(props) =>
    props.$size === PenImageSize.large ? '9.125rem' : '7.875rem'};
  height: ${(props) =>
    props.$size === PenImageSize.large ? '17.5rem' : '14.75rem'};
  position: absolute;
  right: ${(props) =>
    props.$size === PenImageSize.large ? '-2rem' : '-2.25rem'};
  bottom: ${(props) =>
    props.$size === PenImageSize.large ? '-1.5rem' : '-4rem'};
`
export const PenImage = (
  medicineType: NovoPenMedicine,
  size: PenImageSize,
  penIsArchived?: boolean
) => {
  return (
    <PenVisual
      $size={size}
      style={penIsArchived ? { opacity: 0.5 } : {}}
      data-testid="penVisual"
    >
      <PenIMG
        src={medicineType === NovoPenMedicine.Basal ? BluePen : GreyPen}
        alt={'penIMG'}
        $size={size}
      />
    </PenVisual>
  )
}

interface SmartPenInfoProps {
  smartPen: NovoPenObject
  archived: boolean
  onDeleted: () => void
}
export const SmartPenInfo = ({
  smartPen,
  archived,
  onDeleted
}: SmartPenInfoProps) => {
  const { user } = useSessionContext()
  const { t } = useTranslation()
  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false)
  const [showRemovePen, setShowRemovePen] = useState<boolean>(false)
  const [showFindDeviceId, setShowFindDeviceId] = useState<boolean>(false)
  const { patient } = usePatientContext()

  const getErrors = () => {
    if (smartPen.events && smartPen.events.length > 0) {
      return smartPen.events.map(
        (event) =>
          event in NovoPenErrorCodes && (
            <ErrorComponent key={event} errorCode={event} />
          )
      )
    } else {
      return null
    }
  }

  return (
    <Wrapper data-testid="smartPenInfoWrapper">
      {!archived && (
        <>
          <Modal isOpen={showMoreInfo}>
            <MorePenInfo
              closeFun={() => setShowMoreInfo(false)}
              smartPen={smartPen}
            ></MorePenInfo>
          </Modal>
          <Modal isOpen={showRemovePen}>
            <RemovePen
              closeFun={() => {
                setShowRemovePen(false)
                onDeleted()
              }}
              smartPen={smartPen}
              patientId={patient?.patientId}
            ></RemovePen>
          </Modal>
          <Modal isOpen={showFindDeviceId}>
            <FindDeviceId
              closeFun={() => setShowFindDeviceId(false)}
            ></FindDeviceId>
          </Modal>
        </>
      )}
      <Container>
        {PenImage(smartPen.type, PenImageSize.large, archived)}
        <PenInfo>
          <TopSection>
            <StretchedRow>
              <Key style={archived ? { color: 'var(--text-lighter)' } : {}}>
                {t('Device name')}
              </Key>
              {!archived && (
                <Row>
                  <Button
                    data-testid="smartPenShowMoreInfoModal"
                    onClick={() => setShowMoreInfo(true)}
                  >
                    <InfoIcon icon={faInfoCircle} />
                  </Button>
                  <Button
                    data-testid="deleteSmartPen"
                    onClick={() => setShowRemovePen(true)}
                  >
                    <TrashIcon icon={faTrashCan} />
                  </Button>
                </Row>
              )}
            </StretchedRow>
            <TitleWrapper>
              <Title style={archived ? { color: 'var(--text-lighter)' } : {}}>
                {smartPen.name}
              </Title>
              {!archived && (
                <InformationTooltip
                  paragraph={[
                    {
                      title: t('Device name'),
                      body: t(
                        user?.type === UserType.Patient
                          ? 'The name of the NovoPen set by you.'
                          : 'The name of the NovoPen set by the patient.'
                      )
                    }
                  ]}
                  size={InformationLogoSize.medium}
                  logoStyling={{ color: 'var(--brand-primary-color)' }}
                />
              )}
            </TitleWrapper>
          </TopSection>

          {!archived && (
            <>
              <MiddleSection>
                <Column>
                  <Key>{t('Medicine')}</Key>
                  <TitleWrapper>
                    <Value>{getMedicineString(smartPen.type)}</Value>
                    <MedicineTag medicineType={smartPen.type} />
                    <InformationTooltip
                      paragraph={[
                        {
                          title: t('Medicine'),
                          body: t(
                            'Medicine should not be changed to another type than the one listed here. It is associated with the pen type and color for the duration of the clinical trial.'
                          )
                        }
                      ]}
                      size={InformationLogoSize.medium}
                      logoStyling={{ color: 'var(--brand-primary-color)' }}
                    />
                  </TitleWrapper>
                </Column>
                <MedicineImage medicineType={smartPen.type} />
              </MiddleSection>

              <BottomSection>
                <Column>
                  <Key>{t('Device ID')}</Key>
                  <Row>
                    <Value>{smartPen.hardwareId}</Value>
                    <TitleIconWrapper
                      data-testid="smartPenShowFindDeviceId"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowFindDeviceId(true)}
                    >
                      <QuestionIcon icon={faCircleQuestion} />
                    </TitleIconWrapper>
                  </Row>
                </Column>
                <Column>
                  <Key>{t('Last connection')}</Key>
                  <Row>
                    <Value>
                      {dayjs(smartPen.lastConnectionZoned).fromNow()}
                    </Value>
                  </Row>
                </Column>
                <Column>
                  <Key>{t('Battery status')}</Key>
                  <Row>
                    <Value>{smartPen.batteryStatus}</Value>
                    <BatteryIcon batteryStatus={smartPen.batteryStatus} />
                  </Row>
                </Column>
              </BottomSection>
            </>
          )}
          {archived && (
            <BottomSection data-testid="smartPenInfoArchivedSection">
              <Column>
                <ArchivedKey>{t('Device ID')}</ArchivedKey>
                <Row>
                  <ArchivedValue>{smartPen.hardwareId}</ArchivedValue>
                </Row>
              </Column>
              <Column>
                <ArchivedKey>{t('Removed on')}</ArchivedKey>
                <Row>
                  <ArchivedValue>
                    {dayjs(smartPen.lastConnectionZoned).format('DD/M/YYYY')}
                  </ArchivedValue>
                </Row>
              </Column>
            </BottomSection>
          )}
        </PenInfo>
      </Container>
      {getErrors()}
    </Wrapper>
  )
}

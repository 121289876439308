import { faCodeSimple } from '@fortawesome/pro-solid-svg-icons'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getUploadEvents } from '../../core/api/get-upload-events'
import { saveAsFile } from '../../helpers/saveAsFile'
import { SnackBar, SnackBarType } from '../../libraries/Toast/SnackBar'
import { ContextMenuItem } from '../ContextMenu/ContextMenu'
import { Upload } from './constants'

export function DownloadUploadEventsMenuItem({ upload }: { upload: Upload }) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const getUploadEventsForPatient = useCallback(async () => {
    if (loading) return
    setLoading(true)
    try {
      const response = await getUploadEvents(
        upload.patientId as string,
        upload.id
      )
      saveAsFile(`${upload.id}.json`, JSON.stringify(response))
    } catch (err) {
      SnackBar({
        type: SnackBarType.Error,
        message: t('An error has occurred')
      })
    } finally {
      setLoading(true)
    }
  }, [loading, upload.patientId, upload.id, t])

  return (
    <>
      <ContextMenuItem
        onClick={getUploadEventsForPatient}
        icon={faCodeSimple}
        loading={loading}
      >
        {t('Download as JSON')}
      </ContextMenuItem>
    </>
  )
}

import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ProviderPatient } from '../../containers/Integrations/Integrations'
import { fetchDataApi } from '../../core/api/fetchData-api'
import { SnackBar, SnackBarType } from '../../libraries/Toast/SnackBar'

const FetchButton = styled.div<{ $isFetching: boolean }>`
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  display: flex;
  box-sizing: border-box;
  height: 2rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: ${(props) => (props.$isFetching ? 'default' : 'pointer')};
  border: 0.0625rem solid var(--text-lightest);
  color: var(--text-primary);
  &:hover {
    background-color: ${(props) =>
      props.$isFetching ? 'none' : 'var(--element-bg)'};
    color: ${(props) =>
      props.$isFetching ? 'var(--text-primary)' : 'var(--brand-primary-color)'};
  }
`
const FetchIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  line-height: 1rem;
  color: inherit;
`
const FetchText = styled.div`
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 500;
  color: inherit;
`

export const FetchDexcomData = ({
  updateFetch,
  providerPatient
}: {
  updateFetch: () => void
  providerPatient: ProviderPatient
}) => {
  const { t } = useTranslation()
  const [isFetching, setIsFetching] = useState<boolean>(false)

  const fetchData = async () => {
    try {
      setIsFetching(true)
      await fetchDataApi(providerPatient.id, providerPatient.providerType)
    } catch {
      SnackBar({
        type: SnackBarType.Error,
        message: t('Error fetching dexcom data')
      })
    } finally {
      setIsFetching(false)
      updateFetch()
    }
  }

  return (
    <FetchButton
      onClick={isFetching ? () => null : fetchData}
      data-testid="fetchDexcomDataButton"
      $isFetching={isFetching}
    >
      <FetchIcon
        data-testid="fetchDexcomDataIcon"
        icon={faArrowsRotate}
        className={isFetching ? 'fa-spin' : ''}
      />
      <FetchText>{t(isFetching ? 'Fetching data...' : 'Fetch data')}</FetchText>
    </FetchButton>
  )
}

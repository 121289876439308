import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import da from './da.json'
import de from './de.json'
import en from './en.json'
import es from './es.json'
import fo from './fo.json'
import no from './no.json'
import sv from './sv.json'
const FALLBACK_LANG = 'en'
const DEFAULT_LANG = 'da'

const resources = {
  da: {
    translation: da
  },
  en: {
    translation: en
  },
  sv: {
    translation: sv
  },
  no: {
    translation: no
  },
  de: {
    translation: de
  },
  es: {
    translation: es
  },
  fo: {
    translation: fo
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: FALLBACK_LANG,
    lng: getLanguage(),
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n

/**
 * Store the language in local storage
 *
 * @param lang Language to set
 */
export function setLanguage(lang: string) {
  window.localStorage.setItem('lang', lang)
}

/**
 * Get the language from local storage, if nothing is defined, it returns the
 * default language.
 *
 * @returns Stored language
 */
export function getLanguage() {
  return window.localStorage.getItem('lang') || DEFAULT_LANG
}

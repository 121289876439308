import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faEllipsis, faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ComponentProps, ReactNode, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`

const Button = styled.button`
  background: none;
  border-radius: 0.5rem;
  border: 0;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: var(--element-bg);
    color: var(--brand-primary-color);
  }
`

const FloatingContextMenu = styled.div<{ $visible: boolean }>`
  position: absolute;
  top: 2.4rem;
  right: 0;
  background: white;
  border: 1px solid var(--text-lightest);
  border-radius: 0.5rem;
  box-shadow: 0.125rem 0.125rem 0.3125rem var(--text-lightest);
  display: ${(props) => (props.$visible ? 'flex' : 'none')};
  flex-direction: column;
  font-size: 0.875rem;
  z-index: 1;
  font-weight: 600;
`

const IconContainer = styled.div`
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ContextMenuIcon = styled(FontAwesomeIcon)`
  line-height: 2rem;
`

function ContextMenuItemWrapper({
  className,
  children,
  icon,
  loading = false,
  ...props
}: {
  className?: string
  children: ReactNode
  icon?: IconProp
  loading?: boolean
} & ComponentProps<'button'>) {
  return (
    <button className={className} {...props}>
      {!loading && (
        <IconContainer>{icon && <ContextMenuIcon icon={icon} />}</IconContainer>
      )}
      {loading && (
        <IconContainer>
          <ContextMenuIcon icon={faSpinner} spin={true} pulse={true} />
        </IconContainer>
      )}
      {children}
    </button>
  )
}

export const ContextMenuItem = styled(ContextMenuItemWrapper)<{
  $destructive?: boolean
}>`
  background: none;
  border: 0;
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  width: 16.25rem;
  padding: 1rem 1rem 1rem 0;
  color: ${(props) =>
    props.$destructive === true ? 'var(--destructive)' : 'var(--text-primary)'};

  &:hover {
    color: ${(props) =>
      props.$destructive === true
        ? 'var(--destructive-darker)'
        : 'var(--brand-primary-color)'};
  }
`

export function ContextMenu({ items }: { items: ReactNode[] }) {
  const [open, setOpen] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (ref && ref.current && !(ref.current as any).contains(event.target)) {
        setOpen(false)
      }
    }
    document.addEventListener('click', handleOutsideClick)
    return () => document.removeEventListener('click', handleOutsideClick)
  }, [])

  const handleClick = () => {
    setOpen((state) => !state)
  }

  return (
    <Wrapper ref={ref}>
      <Button onClick={handleClick}>
        <FontAwesomeIcon icon={faEllipsis} />
      </Button>
      <FloatingContextMenu $visible={open}>
        {items.map((item, index) => (
          <div key={`item` + index} onClick={() => setOpen(false)}>
            {item}
          </div>
        ))}
      </FloatingContextMenu>
    </Wrapper>
  )
}

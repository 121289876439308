import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FitbitActivityMaxes } from '../../../containers/StackedDaily/Interfaces/Interfaces'
import dayjs from '../../../core/dayjs/dayjs'
import { ModalComponent } from '../../Modal/Modal'
import {
  ActivityGraph,
  ActivityGraphSize
} from '../ActivityGraph/ActivityGraph'
import { FitbitObject } from '../interfaces'
import { NoSleepData } from '../SleepComponent/NoSleepData/NoSleepData'
import { SleepComponent } from '../SleepComponent/SleepComponent'

interface ActivityModalProps {
  data: FitbitObject
  closeFun: () => void
  fitbitActivityMaxes: FitbitActivityMaxes
  day: string
}

const Wrapper = styled.div`
  display: flex;
  width: 50rem;
  padding: 2rem 3rem;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  border-radius: 1.5rem;
  background-color: var(--white-color);
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-width: 90vw;
  max-height: 90vh;
`

const GraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
`
const Header = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`
const HeaderMainText = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2.25rem;
  color: var(--text-primary);
`

const InfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.5rem;
  background-color: var(--element-bg);
`
const InfoWrapper = styled.div`
  display: flex;
  padding: 1.5rem;
  align-items: flex-start;
  gap: 0.75rem;
`

const InfoIcon = styled(FontAwesomeIcon)`
  color: var(--blue);
  font-size: 1.125rem;
  line-height: 1.25rem;
`

const SectionTitle = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 2rem;
`
const MainBodyText = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: var(--text-primary);
  white-space: pre;
`
const LinkText = styled.a`
  font-size: 0.875rem;
  font-weight: 600;
  font-family: inter;
  text-decoration: none;
  margin-left: 0.25rem;
  color: var(--brand-primary-color);
  &:visited {
    color: var(--brand-primary-color);
  }
`
export const ActivityModal = ({
  data,
  closeFun,
  fitbitActivityMaxes,
  day
}: ActivityModalProps) => {
  const { t } = useTranslation()

  const renderSleepComponent = () => {
    if (data.sleep === null) {
      return <NoSleepData />
    } else {
      return data.sleep.slices.map((slice) => <SleepComponent data={slice} />)
    }
  }
  return (
    <ModalComponent closeFun={closeFun} closetestid={'CloseActivityModal'}>
      <Wrapper>
        <Header>
          <HeaderMainText style={{ textTransform: 'capitalize' }}>
            {dayjs(day).format('dddd')}
          </HeaderMainText>
          <HeaderMainText style={{ textTransform: 'uppercase' }}>
            {dayjs(day).format('D MMM')}
          </HeaderMainText>
        </Header>
        <InfoContainer>
          <InfoWrapper>
            <InfoIcon icon={faInfoCircle}></InfoIcon>
            <MainBodyText>
              {t('All of the below data comes from Fitbit. Read about')}
              <LinkText
                href="https://www.fitbit.com/global/us/technology/active-zone-minutes"
                target="_blank"
              >
                {t('AZM here.')}
              </LinkText>
            </MainBodyText>
          </InfoWrapper>
        </InfoContainer>
        <SectionTitle>{t('Sleep')}</SectionTitle>
        {renderSleepComponent()}
        <SectionTitle>{t('Activity')}</SectionTitle>
        <GraphWrapper>
          {Object.entries(data).map(
            (activity, idx) =>
              activity[0] !== 'sleep' && (
                <ActivityGraph
                  data={activity[1]}
                  fitbitActivityMaxes={fitbitActivityMaxes}
                  size={ActivityGraphSize.big}
                  idx={idx}
                  day={''}
                  key={activity[0]}
                  graphIsClickable={false}
                />
              )
          )}
        </GraphWrapper>
      </Wrapper>
    </ModalComponent>
  )
}

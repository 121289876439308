import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import dayjs from '../../../core/dayjs/dayjs'
import { FitbitSleepSlice, FitbitSleepType, SleepValues } from './interfaces'
import { SleepValue } from './SleepValue/SleepValue'

const Wrapper = styled.div`
  display: flex;
  padding: 1rem 1rem 1.5rem 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  background: var(--white-color);
  width: 100%;
  box-sizing: border-box;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  flex: 1 0 0;
`

const HeaderSectionTitle = styled.span`
  color: var(--text-lighter);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
`

const HeaderSectionRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
const HeaderSectionKey = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
`
const HeaderSectionValue = styled.span`
  color: var(--text-lighter);
  font-family: inter;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
`

interface SleepComponentProps {
  data: FitbitSleepSlice
}
export const SleepComponent = ({ data }: SleepComponentProps) => {
  const { t } = useTranslation()

  const renderSleepDuration = () => {
    if (data.hours < 1) {
      return (
        <>
          <HeaderSectionKey>{data.minutes}</HeaderSectionKey>
          <HeaderSectionValue>min</HeaderSectionValue>
        </>
      )
    } else {
      return (
        <>
          <HeaderSectionKey>{data.hours}</HeaderSectionKey>
          <HeaderSectionValue>h</HeaderSectionValue>
          <HeaderSectionKey>{data.minutes}</HeaderSectionKey>
          <HeaderSectionValue>m</HeaderSectionValue>
        </>
      )
    }
  }

  const renderSleepBody = () => {
    if (data.type === FitbitSleepType.classic) {
      return Object.entries(data.classic).map(([key, value]) => (
        <SleepValue
          type={key as SleepValues}
          data={value}
          key={'sleepValue' + key + value.percentage}
        />
      ))
    } else {
      return Object.entries(data.stages).map(([key, value]) => (
        <SleepValue
          type={key as SleepValues}
          data={value}
          key={'sleepValue' + key + value.percentage}
        />
      ))
    }
  }
  return (
    <Wrapper>
      <Header>
        <HeaderSection>
          <HeaderSectionTitle>{t('Sleep duration')}</HeaderSectionTitle>
          <HeaderSectionRow>{renderSleepDuration()}</HeaderSectionRow>
        </HeaderSection>
        <HeaderSection>
          <HeaderSectionTitle>{t('Sleep timeline')}</HeaderSectionTitle>
          <HeaderSectionKey>
            {`${dayjs(data.start).format('HH:mm')} – ${dayjs(data.end).format(
              'HH:mm'
            )}`}
          </HeaderSectionKey>
        </HeaderSection>
      </Header>
      <Body>{renderSleepBody()}</Body>
    </Wrapper>
  )
}

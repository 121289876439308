import { ReactNode } from 'react'
import styled from 'styled-components'
import { Td } from './DataTableRow'

const PlaceholderTr = styled.tr`
  &:hover {
    background: initial !important;
  }
  td {
    height: auto !important;
    padding: 0;
  }
`

const BodyHeightDiv = styled.div<{ $bodyHeight: number | null | undefined }>`
  display: flex;
  justify-content: center;
  min-height: calc(3rem * ${(props) => props.$bodyHeight});
`

export const RowPlaceholder = ({
  children,
  colSpan,
  currentPageRowCount,
  rowsPerPage
}: {
  children?: ReactNode
  colSpan: number
  currentPageRowCount: number
  rowsPerPage: number
}) => {
  const bodyHeight = rowsPerPage - currentPageRowCount

  if (bodyHeight === 0) {
    return null
  }

  return (
    <PlaceholderTr>
      <Td colSpan={colSpan}>
        <BodyHeightDiv $bodyHeight={bodyHeight}>
          {children ?? <></>}
        </BodyHeightDiv>
      </Td>
    </PlaceholderTr>
  )
}

import {
  faHospitalUser,
  faUserCircle,
  faUserMd
} from '@fortawesome/pro-regular-svg-icons'
import { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { ChangeLanguage } from '../../containers/Login/ChangeLanguage/ChangeLanguage'
import { User, UserType } from '../../core/entities/user.entity'
import { FlexColumnWrapper } from '../FlexWrappers/FlexColumnWrapper'
import { FlexRowWrapper } from '../FlexWrappers/FlexRowWrapper'
import { HeaderButton } from '../HeaderButton/HeaderButton'
import { Modal } from '../Modal/Modal'
import { TitleAndLogo } from '../TitleAndLogo/TitleAndLogo'
import { UserModal } from '../UserModal/SignOut'

const HeaderComponent = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  border-bottom: 0.0625rem solid var(--text-lightest);
  backdrop-filter: blur(0.5rem);
  background-color: var(--white-color);
  height: 4.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
`

const RelativeWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
`

const TitleAndPatientSearchWrapper = styled.div`
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  flex-direction: row;
`

const DropdownContainer = styled(FlexColumnWrapper)`
  justify-content: center;
  align-items: center;
  position: relative;
`

type HeaderProps = {
  user: User | null
  logout: any
  leftContent?: ReactNode
  rightContent?: ReactNode
}

const getUserLogo = (type: UserType) => {
  switch (type) {
    case UserType.HCP:
      return faUserMd
    case UserType.HCPAdmin:
      return faHospitalUser
    case UserType.Patient:
      return faUserCircle
  }
}
export const Header = ({
  user,
  logout,
  leftContent,
  rightContent
}: HeaderProps) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  const handleDropdown = () => {
    setShowDropdown(!showDropdown)
  }

  return (
    <HeaderComponent data-testid="mainHeader">
      <RelativeWrapper>
        <TitleAndPatientSearchWrapper>
          <TitleAndLogo isPatient={user?.type === UserType.Patient} />
          {leftContent}
        </TitleAndPatientSearchWrapper>
        <FlexRowWrapper style={{ marginRight: '1rem' }}>
          {rightContent}
          <DropdownContainer>
            {user && (
              <HeaderButton
                icon={getUserLogo(user.type)}
                onClick={handleDropdown}
              >
                {user.name}
              </HeaderButton>
            )}
          </DropdownContainer>
          <ChangeLanguage />
        </FlexRowWrapper>
        {user && showDropdown && (
          <Modal isOpen={showDropdown}>
            <UserModal
              username={user.name}
              onLogout={() => {
                logout({ redirectUri: '/' })
              }}
              closeDropdown={() => setShowDropdown(false)}
            ></UserModal>
          </Modal>
        )}
      </RelativeWrapper>
    </HeaderComponent>
  )
}

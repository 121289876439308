import { IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 0.75rem;
  display: inline-flex;
  gap: 0.375rem;
  height: 2.5rem;
  box-sizing: border-box;
  align-items: center;
  border-radius: 0.5rem;
  background-color: var(--element-bg-80);
  color: var(--text-primary);
  cursor: pointer;
  &:hover {
    color: var(--brand-primary-color);
    background-color: var(--element-bg-dark);
  }
`

export const Icon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  line-height: 1rem;
  color: inherit;
`
export const Text = styled.span`
  color: inherit;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1rem;
`

const FilterCountText = styled.span`
  width: 1.125rem;
  height: 1.125rem;
  background-color: var(--blue);
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--text-primary);
`

interface FunctionButtonProps {
  icon?: IconDefinition
  text?: string
  filtersCount?: number
  handleClick: () => void
}
export const FilterFunctionButton = ({
  icon,
  text,
  filtersCount,
  handleClick
}: FunctionButtonProps) => {
  return (
    <Wrapper onClick={handleClick}>
      {icon && <Icon icon={icon}></Icon>}
      {text && <Text>{text}</Text>}
      {filtersCount && <FilterCountText>{filtersCount}</FilterCountText>}
    </Wrapper>
  )
}

import {
  OrderEnum,
  SortEnum
} from '../../components/ProviderPatients/ProviderPatients'
import { ProviderPatient } from '../../containers/Integrations/Integrations'
import config from '../config'
import { ProviderPatientProviderType } from '../testdata/ProviderPatientTestData'
import { AxiosRequest } from './axios-request'

interface CmiSearchBy {
  todo: boolean
  linked: boolean
  ignored: boolean
  searchString: string | null
  providerId: string | null
  providerTypes: ProviderPatientProviderType[]
}
interface CmiSearchSelection {
  sortBy: OrderEnum | null
  order: SortEnum
  searchBy: CmiSearchBy
}
export interface ProviderPatientsFilters {
  selection: CmiSearchSelection
  size: number
}
interface ProviderPatientsResponse {
  providerPatients: Array<ProviderPatient>
  size: number
}

export async function getProviderPatients(
  filters: ProviderPatientsFilters
): Promise<ProviderPatientsResponse> {
  const response = await AxiosRequest.create().post(
    `${config.API_URL}/cmi/patients`,
    filters
  )
  return response.data
}

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Spinner, spinnerSize } from '../../components/Spinner/Spinner'
import { NovoPenObject } from '../../core/api/getDoseRecommendations'
import { usePatientContext } from '../../core/contexts/patient-context'
import { usePeriodContext } from '../../core/contexts/period-context'
import { useSiteSettingsContext } from '../../core/contexts/site-settings-context'
import { Capabilities } from '../../core/entities/siteSettings.entity'
import { usePatientData } from '../../hooks/GetPatientData'
import { NovoPenSettings } from './NovoPenSettings/NovoPenSettings'
import { PumpSettings } from './PumpSettings/PumpSettings'

export interface DeviceSettingsTarget {
  low: number | null
  high: number | null
  target: number | null
}
export interface ProfileEntry {
  basalRate: number | null
  carbRatio: number | null
  correctionFactor: number | null
  start: string
  target: DeviceSettingsTarget
}
export interface BolusSettings {
  maxBolus: number | null
  activeInsulin: number | null
}
export interface Profile {
  profileName: string
  current: boolean
  bolusSettings: BolusSettings
  entries: Array<ProfileEntry>
  totalBasal: number | null
}
export interface PumpSettingsObject {
  profiles: Array<Profile>
}
export interface CurrentDeviceSettings {
  sensorSettings: PumpSettingsObject | null
  pumpSettings: PumpSettingsObject | null
  deviceDisplayName: string
  serialnumber: string
  time: string
}

export interface DeviceSettingsResponse {
  type: 'devicesettings'
  hasMore: boolean
  currentDeviceSettings: CurrentDeviceSettings
  deviceSettingsNovoPens: NovoPenObject[] | null
  previousDeviceSettingsNovoPens: NovoPenObject[] | null
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  z-index: 1;
  @media (max-width: 68.75rem) {
    align-items: flex-start;
  }
  padding-bottom: 6rem;
`

const Container = styled.div`
  width: 68.75rem;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`
const Title = styled.div`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
`

const SubTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
`

const MainText = styled.span`
  color: var(--text-primary);
`

function isDeviceSettingsData(
  response: any
): response is DeviceSettingsResponse {
  return response.type === 'devicesettings'
}
export const DeviceSettings = () => {
  const [update, setUpdate] = useState<boolean>(false)
  const { patient } = usePatientContext()
  const { period } = usePeriodContext()
  const { t } = useTranslation()
  const { siteSettings } = useSiteSettingsContext()
  const { patientViewData, patientViewError } = usePatientData(
    patient,
    period,
    {
      type: 'devicesettings',
      days: null
    },
    update
  )
  if (patientViewError) {
    return (
      <div data-testid="deviceSettingsError">{patientViewError.message}</div>
    )
  } else if (!period) {
    return (
      <Wrapper data-testid="deviceSettingsNoPeriod">
        <Title>{t('Device Settings')}</Title>
        <SubTitleWrapper style={{ margin: '1rem' }}>
          {t('There are no device settings')}
        </SubTitleWrapper>
      </Wrapper>
    )
  } else if (patientViewData && isDeviceSettingsData(patientViewData)) {
    return (
      <Wrapper data-testid="deviceSettingsData">
        <Container>
          {patientViewData.currentDeviceSettings.pumpSettings === null &&
            patientViewData.deviceSettingsNovoPens === null &&
            !siteSettings?.capabilities.includes(Capabilities.adaptt2d) && (
              <SubTitleWrapper>
                <MainText>{t('There are no device settings')}</MainText>
              </SubTitleWrapper>
            )}

          {siteSettings?.capabilities.includes(Capabilities.adaptt2d) && (
            <NovoPenSettings
              deviceSettingsNovoPens={patientViewData.deviceSettingsNovoPens}
              previousDeviceSettingsNovoPens={
                patientViewData.previousDeviceSettingsNovoPens
              }
              update={() => setUpdate(!update)}
            />
          )}

          {!siteSettings?.capabilities.includes(Capabilities.adaptt2d) &&
            patientViewData.currentDeviceSettings.pumpSettings !== null && (
              <PumpSettings
                currentDeviceSettings={patientViewData.currentDeviceSettings}
                pumpSettings={
                  patientViewData.currentDeviceSettings.pumpSettings
                }
              />
            )}
        </Container>
      </Wrapper>
    )
  } else {
    return (
      <div data-testid="deviceSettingsLoading">
        <Spinner spinnersize={spinnerSize.large} />
      </div>
    )
  }
}

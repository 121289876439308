import { Upload } from '../../components/Uploads/Uploads'
import config from '../config'
import { AxiosRequest } from './axios-request'
import { PaginatedRequest, PaginatedResponse } from './pagination'

export interface UploadParams extends PaginatedRequest {
  manufacturers?: string
  types?: string
  cutoff?: string
  searchString?: string
}

export async function getUploads(
  params: UploadParams,
  options: object = {}
): Promise<PaginatedResponse<Upload>> {
  const url = `${config.API_URL}/upload_activity`

  const response = await AxiosRequest.create().get(url, {
    ...options,
    params,
    paramsSerializer: {
      indexes: null
    }
  })

  return response.data as PaginatedResponse<Upload>
}

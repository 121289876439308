import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ContextMenuItem } from '../ContextMenu/ContextMenu'

export function GoToPatientMenuItem({ patientId }: { patientId: string }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const goToPatient = () => {
    navigate(`/portal/patients/${patientId}/insights`)
  }

  return (
    <ContextMenuItem onClick={() => goToPatient()} icon={faUser}>
      {t('Go to patient')}
    </ContextMenuItem>
  )
}

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 12.5rem;
  padding: 0rem 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  box-sizing: border-box;
`

const Title = styled.span`
  font-family: inter;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: var(--text-medium);
`

const BodyText = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 150%;
  color: var(--text-lighter);
`

export const NoActivePen = () => {
  const { t } = useTranslation()
  return (
    <Wrapper data-testid="noActiveBasalpen">
      <Title>{t('No active basal pen')}</Title>
      <BodyText>
        {t('Dose recommendation needs a connected basal pen to function.')}
      </BodyText>
    </Wrapper>
  )
}

import { useRef, useState } from 'react'
import styled from 'styled-components'
import { DeviceEventList } from '../../../components/DeviceEvent/DeviceEventList/DeviceEventList'
import { DeviceEventType } from '../../../components/DeviceEvent/Interfaces'
import { Modal, ModalComponent } from '../../../components/Modal/Modal'
import dayjs from '../../../core/dayjs/dayjs'
import { DaysObject, FitbitActivityMaxes } from '../Interfaces/Interfaces'
import { StackedDayGraphs } from './StackedDayGraphs/StackedDayGraphs'
import { StackedDayHeader } from './StackedDayHeader/StackedDayHeader'
import { StackedDayWidgets } from './StackedDayWidgets/StackedDayWidgets'

const Page = styled.div`
  display: flex;
  flex-direction: column;
  scroll-margin-top: -20rem;
`

const Wrapper = styled.div`
  position: relative;
  max-width: calc(1134px + 18.25rem - 1rem);
  min-width: calc(700px + 18.25rem - 1rem);
`

const GraphWidgetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  justify-content: center;
`

const Seperator = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  height: 0.125rem;
  background-color: var(--text-lightest);
`

interface StackedDaysEntryProps {
  index: number
  day: string
  loading: boolean
  maxBolus: number | null
  maxBasal: number | null
  maxTotalInsulin: number | null
  maxPenInjection: number | null
  deviceEventTypes: Array<DeviceEventType>
  item: DaysObject | null
  fitbitActivityMaxes: FitbitActivityMaxes
}

export const StackedDay = ({
  index,
  day,
  loading,
  maxBolus,
  maxBasal,
  maxTotalInsulin,
  maxPenInjection,
  deviceEventTypes,
  item,
  fitbitActivityMaxes
}: StackedDaysEntryProps) => {
  const screenShotWrapper = useRef<HTMLDivElement | null>(null)
  const [showScreenShot, setShowScreenShot] = useState<boolean>(false)
  const [showAlarmList, setShowAlarmList] = useState<boolean>(false)

  return (
    <Page
      data-testid={
        loading ? 'stackedCard' + index + 'loading' : 'stackedCard' + index
      }
      onMouseEnter={() => setShowScreenShot(true)}
      onMouseLeave={() => setShowScreenShot(false)}
      id={dayjs(day).format('DMMM')}
    >
      {showAlarmList && item && (
        <Modal isOpen={showAlarmList}>
          <ModalComponent
            closeFun={() => setShowAlarmList(false)}
            closetestid="closeDeviceEventList"
          >
            <DeviceEventList
              deviceEvents={item.deviceEvents}
              eventFilters={deviceEventTypes}
              day={item.day}
            />
          </ModalComponent>
        </Modal>
      )}
      <Wrapper id={'snapshot' + (index + 1)} ref={screenShotWrapper}>
        <StackedDayHeader
          day={day}
          showScreenShot={showScreenShot}
          screenShotWrapper={screenShotWrapper}
          index={index}
        />
        <GraphWidgetWrapper>
          <StackedDayGraphs
            item={item}
            setShowAlarmList={setShowAlarmList}
            maxBasal={maxBasal}
            maxBolus={maxBolus}
            maxPenInjection={maxPenInjection}
            loading={loading}
            fitbitActivityMaxes={fitbitActivityMaxes}
          />
          <StackedDayWidgets
            item={item}
            loading={loading}
            maxTotalInsulin={maxTotalInsulin}
            fitbitActivityMaxes={fitbitActivityMaxes}
          />
        </GraphWidgetWrapper>
      </Wrapper>
      <Seperator></Seperator>
    </Page>
  )
}

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  getInfoLogo,
  TitleComponent,
  TitleWrapperComponent
} from '../../DataWidget/Shared/Header/Header'
import { WidgetState } from '../../DataWidget/Shared/Interfaces/Interfaces'
import {
  getInformationTooltipParagraph,
  TooltipType
} from '../../InformationTooltip/InformationTooltipParagraphs'
import {
  Container,
  getActualState,
  Wrapper
} from '../../SingleWidget/SingleWidget'
import { AvgAmount } from './AvgAmount/AvgAmount'
import { AvgInjections } from './AvgInjections/AvgInjections'

export interface BolusInjectionWidgetData {
  avgDailyInjections: number
  avgDailyAmount: number
}
interface BolusInjectionProps {
  state: WidgetState
  data: BolusInjectionWidgetData
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
`

export const BolusInjection = ({ state, data }: BolusInjectionProps) => {
  const { t } = useTranslation()
  const actualState = getActualState(state, data)
  return (
    <Container>
      <Wrapper>
        <TitleWrapperComponent>
          <TitleComponent>{t('Bolus Doses')}</TitleComponent>
          {getInfoLogo(
            getInformationTooltipParagraph(TooltipType.bolusInjections, t),
            actualState,
            undefined,
            true
          )}
        </TitleWrapperComponent>
      </Wrapper>
      <Body>
        <AvgInjections data={data} />
        <AvgAmount data={data} />
      </Body>
    </Container>
  )
}

import { useRef } from 'react'
import styled from 'styled-components'
import { CGMWidget } from '../../components/CGMWidget/CGMWidget'
import {
  SingleWidget,
  SingleWidgetTypeEnum
} from '../../components/SingleWidget/SingleWidget'
import {
  AverageCarbs,
  TimeInActivityObject
} from '../../containers/Overview/Overview'

import {
  AverageGlucoseObject,
  PumpActivity,
  TimeInAutomationObject,
  TimeInRangeObject,
  TotalInsulinObject
} from '../../containers/StackedDaily/Interfaces/Interfaces'
import { usePatientViewsContext } from '../../core/contexts/patient-view-context'
import { useSiteSettingsContext } from '../../core/contexts/site-settings-context'
import { Capabilities } from '../../core/entities/siteSettings.entity'
import { getBars } from '../../helpers/getBars'
import {
  ActivityWidget,
  ActivityWidgetType
} from '../ActivityComponents/ActivityWidget/ActivityWidget'
import { FitbitAverageWidget } from '../ActivityComponents/interfaces'
import {
  SizeEnum,
  WidgetState
} from '../DataWidget/Shared/Interfaces/Interfaces'
import { TimeInActivity } from '../DataWidget/TimeInActivity/TimeInActivity'
import { TimeInAutomation } from '../DataWidget/TimeInAutomation/TimeInAutomation'
import { TimeInRange } from '../DataWidget/TimeInRange/TimeInRange'
import { TotalInsulin } from '../DataWidget/TotalInsulin/TotalInsulin'
import {
  BasalInjection,
  BasalInjectionWidgetData
} from '../InjectionWidgets/BasalInjection/BasalInjection'
import {
  BolusInjection,
  BolusInjectionWidgetData
} from '../InjectionWidgets/BolusInjection/BolusInjection'

const WidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  z-index: 1;
`
export interface InjectionWidgetsObject {
  bolusInjections: BolusInjectionWidgetData
  basalInjections: BasalInjectionWidgetData
}
export interface StandardDeviation {
  stdDev: number
  upper: number
  lower: number
}
interface WidgetsStackProps {
  timeInRangeBars: TimeInRangeObject | null
  totalInsulin: TotalInsulinObject | null
  averageGlucose: AverageGlucoseObject | null
  sensorUsage: number | null
  standardDeviation: StandardDeviation | null
  totalCarbs: AverageCarbs | null
  timeInAutomation: TimeInAutomationObject | null
  timeInActivity: TimeInActivityObject | null
  pumpActivity: PumpActivity | null
  cv: number | null
  injectionWidgets: InjectionWidgetsObject | null
  fitbitAverageWidget: FitbitAverageWidget | null
  state: WidgetState
}
export const WidgetsStack = ({
  timeInRangeBars,
  totalInsulin,
  averageGlucose,
  sensorUsage,
  standardDeviation,
  totalCarbs,
  timeInAutomation,
  pumpActivity,
  timeInActivity,
  cv,
  injectionWidgets,
  fitbitAverageWidget,
  state
}: WidgetsStackProps) => {
  const { siteSettings } = useSiteSettingsContext()
  const { patientViews } = usePatientViewsContext()
  const screenshotWrapper = useRef<HTMLDivElement | null>(null)
  const bars = getBars({
    activity: pumpActivity,
    timeInRange: timeInRangeBars,
    automation: timeInAutomation,
    totalInsulin,
    maxTotalInsulin: 0,
    widgetStack: true
  })
  return (
    <WidgetWrapper>
      <TimeInRange
        wrapperRef={screenshotWrapper}
        size={SizeEnum.Medium}
        data={bars.timeInRangeBars}
        state={state}
      ></TimeInRange>
      <CGMWidget
        avg={averageGlucose !== null ? averageGlucose.average : null}
        std={
          standardDeviation
            ? {
                stdDev: standardDeviation.stdDev,
                lower: standardDeviation.lower,
                upper: standardDeviation.upper
              }
            : null
        }
        cv={cv !== null ? Math.round(cv) : null}
        state={state}
      ></CGMWidget>
      <SingleWidget
        type={SingleWidgetTypeEnum.SensorUsage}
        value={sensorUsage !== null ? Math.round(sensorUsage) : null}
        state={state}
      ></SingleWidget>
      {injectionWidgets === null && (
        <TotalInsulin
          size={SizeEnum.Medium}
          state={state}
          data={{
            bars: bars.TotalInsulinBars,
            total: totalInsulin ? totalInsulin.total : null,
            basedOn: totalInsulin
              ? {
                  selectedDays: totalInsulin.selectedDays,
                  daysWithData: totalInsulin.daysWithData
                }
              : null
          }}
          max={null}
        />
      )}
      {bars.AutomationBars !== null && (
        <TimeInAutomation
          data={bars.AutomationBars}
          state={state}
          size={SizeEnum.Medium}
        ></TimeInAutomation>
      )}
      {bars.ActivityBars !== null && (
        <TimeInActivity
          data={bars.ActivityBars}
          state={state}
          size={SizeEnum.Medium}
        ></TimeInActivity>
      )}
      {fitbitAverageWidget !== null && (
        <ActivityWidget
          data={fitbitAverageWidget}
          fitbitActivityMaxes={undefined}
          day={undefined}
          type={ActivityWidgetType.avg}
          state={state}
        />
      )}
      {totalCarbs !== null && (
        <SingleWidget
          type={SingleWidgetTypeEnum.AvgCarbs}
          value={{
            data: totalCarbs.avgCarbs,
            selectedDays: totalCarbs.selectedDays,
            daysWithData: totalCarbs.daysWithData
          }}
          state={state}
          last={true}
        ></SingleWidget>
      )}
      {siteSettings?.capabilities.includes(Capabilities.adaptt2d) && (
        <>
          {injectionWidgets !== null && (
            <>
              <BasalInjection
                state={state}
                data={injectionWidgets.basalInjections}
              />
              <BolusInjection
                state={state}
                data={injectionWidgets.bolusInjections}
              />
            </>
          )}
        </>
      )}
    </WidgetWrapper>
  )
}

import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { deleteUpload } from '../../core/api/delete-upload-api'
import { SnackBar, SnackBarType } from '../../libraries/Toast/SnackBar'
import { ContextMenuItem } from '../ContextMenu/ContextMenu'
import { DeleteModal } from '../DeleteModal/DeleteModal'
import { Modal } from '../Modal/Modal'
import { Upload } from './constants'

export function DeleteUploadMenuItem({
  upload,
  onDelete
}: {
  upload: Upload
  onDelete: (id: string) => void
}) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const deleteUploadfForPatient = useCallback(async () => {
    if (loading) return
    setLoading(true)
    try {
      await deleteUpload(upload.patientId, upload.id)
      onDelete(upload.id)
      setLoading(false)
      setShowDeleteModal(false)
    } catch (err) {
      SnackBar({
        type: SnackBarType.Error,
        message: t('An error has occurred')
      })
    } finally {
      setLoading(true)
    }
  }, [loading, upload.patientId, upload.id, onDelete, t])

  return (
    <>
      <Modal isOpen={showDeleteModal}>
        <DeleteModal
          title={t('Are you sure you want to delete this upload?')}
          body={t(
            'This should only be done if the upload was made by mistake. This action can not be undone.'
          )}
          buttonText={t('Delete upload')}
          acceptfn={() => deleteUploadfForPatient()}
          cancelfn={() => setShowDeleteModal(false)}
        ></DeleteModal>
      </Modal>
      <ContextMenuItem
        onClick={() => setShowDeleteModal(true)}
        icon={faTrash}
        $destructive
      >
        {t('Delete upload')}
      </ContextMenuItem>
    </>
  )
}

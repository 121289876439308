/* eslint-disable prettier/prettier */
import { createContext, useContext } from 'react'

export enum PatientViewTypes {
  Trends = 'Trends',
  Overview = 'Overview',
  Daily = 'Daily',
  BgLog = 'BgLog',
  DeviceSettings = 'DeviceSettings',
  More = 'More',
  PenDosing = 'PenDosing'
}

export enum PatientViewDisplays {
  TimeInAutomationWidget = 'TimeInAutomationWidget',
  TotalCarbsWidget = 'TotalCarbsWidget',
  InsulinWidget = 'InsulinWidget',
  PumpActivityWidget = 'PumpActivityWidget',
  Infusion = 'Infusion',
  TimeInRangeWidget = 'TimeInRangeWidget',
  TimeInActivityWidget = 'TimeInActivityWidget',
  AvgGlucoseWidget = 'AvgGlucoseWidget',
  CvWidget = 'CvWidget',
  STDWidget = 'STDWidget',
  SensorUsageWidget = 'SensorUsageWidget',
  Events = 'Events',
  FitbitAverageWidget = 'FitbitAverageWidget',
  FitbitWidget = 'FitbitWidget'
}

export interface PatientView {
  page: PatientViewTypes
  subViews: PatientViewDisplays[]
}

export type PatientPagesObjectType = {
  [key in PatientViewTypes]: { show: boolean; subViews: PatientViewDisplays[] }
}

type PatientViewsContextState = {
  patientViews: PatientPagesObjectType | null
}

export const defaultPatientPagesObject = {
  Trends: { show: false, subViews: [] },
  Overview: { show: false, subViews: [] },
  Daily: { show: false, subViews: [] },
  BgLog: { show: false, subViews: [] },
  DeviceSettings: { show: false, subViews: [] },
  More: { show: false, subViews: [] },
  PenDosing: { show: false, subViews: [] }
}

export const PatientViewsContext = createContext<PatientViewsContextState>({
  patientViews: defaultPatientPagesObject
})

export function usePatientViewsContext() {
  return useContext(PatientViewsContext)
}
export default PatientViewsContext

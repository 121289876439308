import { FitbitSleepWidget } from './SleepComponent/interfaces'

export enum FitbitFineGrainedType {
  azm = 'azm',
  steps = 'steps',
  distance = 'distance',
  calories = 'calories',
  floors = 'floors'
}

export interface FitbitFineGrainedSlice {
  start: string
  end: string
  total: number
}

export interface FitbitObjectItem {
  total: number
  type: FitbitFineGrainedType
  timeSlices: Array<FitbitFineGrainedSlice>
  leftLabel: string | null
  rightLabel: string | null
}

export interface FitbitObject {
  sleep: FitbitSleepWidget | null
  azm: FitbitObjectItem
  calories: FitbitObjectItem
  steps: FitbitObjectItem
  distance: FitbitObjectItem
  floors: FitbitObjectItem
}

export interface FitbitAverageItem {
  total: number
  type: FitbitFineGrainedType
  rightLabel: string | null
}
export interface FitbitAverageWidget {
  azm: FitbitAverageItem
  calories: FitbitAverageItem
  steps: FitbitAverageItem
  distance: FitbitAverageItem
  floors: FitbitAverageItem
}

import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { NoSmartPen } from '../../../components/SmartPenInfo/NoSmartPen/NoSmartPen'
import { SmartPenInfo } from '../../../components/SmartPenInfo/SmartPenInfo'
import { NovoPenObject } from '../../../core/api/getDoseRecommendations'
import { useSessionContext } from '../../../core/contexts/session-context'
import { UserType } from '../../../core/entities/user.entity'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`
const Title = styled.div`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
`

const InfoBox = styled.div`
  display: flex;
  padding: 0.5rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: var(--element-bg);
`
const InfoCircle = styled(FontAwesomeIcon)`
  color: var(--blue);
  font-size: 0.875rem;
  line-height: 1.25rem;
`

const InfoText = styled.span`
  font-family: inter;
  font-size: 0.75rem;
  color: var(--text-primary);
  font-weight: 600;
  line-height: 1.25rem;
`
interface NovoPenSettingsProps {
  deviceSettingsNovoPens: NovoPenObject[] | null
  previousDeviceSettingsNovoPens: NovoPenObject[] | null
  update: () => void
}
export const NovoPenSettings = ({
  deviceSettingsNovoPens,
  previousDeviceSettingsNovoPens,
  update
}: NovoPenSettingsProps) => {
  const { t } = useTranslation()
  const { user } = useSessionContext()
  return (
    <Wrapper>
      <Section>
        <Title>{t('Smart Pens - Active')}</Title>
        {deviceSettingsNovoPens !== null ? (
          deviceSettingsNovoPens.map((smartPen) => (
            <SmartPenInfo
              key={smartPen.id}
              smartPen={smartPen}
              archived={false}
              onDeleted={update}
            />
          ))
        ) : (
          <NoSmartPen />
        )}
      </Section>

      {previousDeviceSettingsNovoPens !== null && (
        <Section data-testid="archivedSmartPens">
          <TitleWrapper>
            <Title>{t('Smart Pens - Archived')}</Title>
            <InfoBox>
              <InfoCircle icon={faInfoCircle} />
              <InfoText data-testid="archivedSmartPensMessage">
                {t(
                  `The devices below are no longer linked to ${
                    user?.type === UserType.Patient ? 'you' : 'the patient'
                  }`
                )}
              </InfoText>
            </InfoBox>
          </TitleWrapper>
          {previousDeviceSettingsNovoPens.map((smartPen) => (
            <SmartPenInfo
              key={smartPen.id}
              smartPen={smartPen}
              archived={true}
              onDeleted={update}
            />
          ))}
        </Section>
      )}
    </Wrapper>
  )
}

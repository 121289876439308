import { faArrowUpFromArc } from '@fortawesome/pro-regular-svg-icons'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Patient } from '../../../core/entities/patient.entity'
import { User } from '../../../core/entities/user.entity'
import { Capability, useCapabilities } from '../../../hooks/use-capabilities'
import { DevicesAndPeriod } from '../../Header/DevicesAndPeriod/DevicesAndPeriod'
import { Header } from '../../Header/Header'
import { NameComponent } from '../../Header/NameComponent/NameComponent'
import { UploaderButton } from '../../HeaderButton/UploaderButton'
import { AccessEnum } from '../PortalLayout/PortalLayout'

interface ComponentProps {
  patient: Patient | null
  user: User | null
  wards: Patient[] | null
  onLogout: () => void
  viewedPatientIsBlinded: boolean
  setViewedPatientIsBlinded: Dispatch<SetStateAction<boolean>>
}

export const PatientHeader = ({
  patient,
  user,
  wards,
  onLogout,
  viewedPatientIsBlinded,
  setViewedPatientIsBlinded
}: ComponentProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { isCapabilitySupportedBySite } = useCapabilities()
  const uploaderIsActive = location.pathname.includes('uploader')

  if (!patient) return null

  const getCurrentlySelectedPatient = () => {
    if (patient.patientId === AccessEnum.noAccess) {
      if (wards && wards.length > 0) {
        return wards[0]
      }
      return null
    } else {
      return patient
    }
  }
  const currentlySelectedPatient = getCurrentlySelectedPatient()
  return (
    <>
      {currentlySelectedPatient === null ? null : (
        <Header
          user={user}
          logout={onLogout}
          leftContent={
            <>
              <NameComponent
                patient={currentlySelectedPatient}
                wards={wards}
                blinded={false}
              ></NameComponent>
              {user?.blinded ? null : (
                <DevicesAndPeriod
                  patient={currentlySelectedPatient}
                  setViewedPatientIsBlinded={setViewedPatientIsBlinded}
                />
              )}
            </>
          }
          rightContent={
            isCapabilitySupportedBySite(Capability.UploaderInPortal) && (
              <UploaderButton
                inUploader={uploaderIsActive}
                icon={faArrowUpFromArc}
                onClick={() => navigate('/portal/uploader')}
              >
                {t('Upload')}
              </UploaderButton>
            )
          }
        />
      )}
    </>
  )
}

import { useMemo } from 'react'

export function useFiltersCount(filters: { [key: string]: string[] }) {
  const count = useMemo(() => {
    return Object.values(filters)
      .map((value) => {
        if (Array.isArray(value) && value.length > 0) return 1
        return 0
      })
      .reduce((acc: number, current: number) => acc + current, 0)
  }, [filters])

  return count
}

import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled, { keyframes } from 'styled-components'
import { useSiteSettingsContext } from '../../core/contexts/site-settings-context'
import { getValidation } from '../../helpers/MRNValidation/getValidation'
import { MRNTypes } from '../../helpers/MRNValidation/interfaces'
import { SearchButton } from '../SearchButton/SearchButton'
import { Spinner, spinnerSize } from '../Spinner/Spinner'

interface CssProps {
  focusState: boolean | null
}

const SearchBarComponent = styled.form<CssProps>`
  border: 1px solid;
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.4375rem;
  position: relative;
  padding: 0.125rem;
  border-color: ${(props) =>
    props.focusState ? 'var(--brand-primary-color)' : 'var(--text-lightest)'};
  box-shadow: ${(props) =>
    props.focusState
      ? '0rem 0rem 0rem 0.375rem var(--brand-primary-16-color)'
      : 'var(--text-lightest)'};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`
const SearchLogo = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--brand-primary-color);
  margin-left: 1.25rem;
`
const DeleteLogo = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  font-weight: 900;
  color: var(--text-lightest);
  &:hover {
    color: var(--brand-primary-color);
  }
`
const moveLabel = keyframes`
from {
  bottom:11.5%;
  left:20%;
}
to {
  bottom:250%;
  left:0%;
}
`
const moveLabelBack = keyframes`
from {
  bottom:250%;
  left:0%;
}
to {
  bottom:11.5%;
  left:20%;
}
`

const SearchBarLabel = styled.div<CssProps>`
  font-family: inter;
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--text-primary);
  position: absolute;
  pointer-events: none;
  line-height: 1.5rem;
  bottom: 11.5%;
  left: 20%;
  &.move {
    animation: ${moveLabel} 0.3s forwards 1;
  }

  &.moveBack {
    animation: ${moveLabelBack} 0.3s forwards 1;
  }
`
const InputWrapper = styled.div`
  gap: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
const DeleteButton = styled.button`
  border: none;
  background: none;
  margin: 0 auto;
  padding: 0;
  cursor: pointer;
  &:disabled {
    opacity: 0;
    cursor: default;
  }
`
const DeleteButtonWrapper = styled.div`
  width: 1.75rem;
  max-width: 1.75rem;
`
const SearchBarInput = styled.input`
  color: var(--text-primary);
  font-family: inter;
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: 0.1875rem;
  border: none;
  width: 13.25rem;
  &:focus {
    outline: none;
  }
`

export const ErrorContainer = styled.div`
  position: absolute;
  color: var(--white-color);
  padding: 1.75rem;
  background: red;
  border-radius: 0.875rem;
  top: 125%;
  left: 50%;
  transform: translateX(-50%);
`

export type FormValues = {
  MRN: string
}

type SearchBarProps = {
  children: ReactNode
  onSuccess: (data: FormValues) => void
  patientError: string | undefined
  setPatientError: Dispatch<SetStateAction<string | undefined>>
  searching: boolean
}

export const SearchBar = ({
  children,
  onSuccess,
  patientError,
  setPatientError,
  searching
}: SearchBarProps) => {
  const {
    register,
    getValues,
    resetField,
    formState: { isValid, isDirty },
    setValue,
    handleSubmit
  } = useForm<FormValues>({ mode: 'onChange', defaultValues: { MRN: '' } })
  const onSubmit: SubmitHandler<FormValues> = (data) => onSuccess(data)
  const [focusState, setFocusState] = useState<boolean | null>(true)
  const [currentMRN, setCurrentMRN] = useState('')
  const { siteSettings } = useSiteSettingsContext()
  const { t } = useTranslation()
  const validation = getValidation({
    mrntype: siteSettings?.mrnType ?? MRNTypes.dkcpr,
    setPatientError,
    setValue,
    getValues,
    setCurrentMRN,
    currentMRN
  })
  const { ref, ...reg } = register('MRN', {
    onChange: () => {
      validation.changeFunction()
    },
    required: t(`${siteSettings?.mrnType}.mrn-required`),
    pattern: {
      value: validation.pattern,
      message: 'Wrong format'
    }
  })

  const inputRef = useRef<HTMLInputElement | null>(null)
  function handleOnFocus() {
    setFocusState(true)
  }
  function handleOnBlur() {
    setFocusState(isDirty)
  }

  function clearField() {
    resetField('MRN')
    setPatientError(undefined)
    setCurrentMRN('')
  }
  useEffect(() => {
    if (inputRef.current) inputRef.current.select()
  }, [inputRef])

  return (
    <SearchBarComponent
      focusState={focusState}
      onSubmit={handleSubmit(onSubmit)}
      data-testid="searchBar"
    >
      <InputWrapper>
        <SearchBarLabel
          className={
            focusState !== null
              ? focusState === true
                ? 'move'
                : 'moveBack'
              : ''
          }
          focusState={focusState}
          data-testid="SearchBarLabel"
        >
          {t(`${siteSettings?.mrnType}.mrn-enter`)}
        </SearchBarLabel>
        <SearchLogo icon={faSearch}></SearchLogo>
        <SearchBarInput
          {...reg}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          autoComplete="off"
          data-testid="SearchBarInput"
          ref={(e) => {
            ref(e)
            inputRef.current = e
          }}
        ></SearchBarInput>
        <DeleteButtonWrapper>
          {!searching && (
            <DeleteButton
              type="reset"
              disabled={!isDirty}
              onClick={clearField}
              data-testid="clearFieldButton"
            >
              <DeleteLogo icon={faTimesCircle}></DeleteLogo>
            </DeleteButton>
          )}
          {searching && <Spinner spinnersize={spinnerSize.medium}></Spinner>}
        </DeleteButtonWrapper>
      </InputWrapper>
      <SearchButton
        type="submit"
        disabled={!isValid}
        data-testid="submitButton"
      >
        {children}
      </SearchButton>
      {patientError && <ErrorContainer>{patientError}</ErrorContainer>}
    </SearchBarComponent>
  )
}

import { KeycloakLoginOptions, KeycloakLogoutOptions } from 'keycloak-js'
import { useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { Clinic } from '../../core/entities/clinic.entity'
import { User } from '../../core/entities/user.entity'

export const TIMEOUT = 1200000
export const PROMPT_BEFORE_IDLE = 89000

export const useIdleDetection = (session: {
  isAuthenticated: boolean
  user: User | null
  userError: boolean
  clinic: Clinic | null
  clinicError: boolean
  login: (options?: KeycloakLoginOptions | undefined) => Promise<void>
  logout: (options?: KeycloakLogoutOptions | undefined) => Promise<void>
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const onIdle = () => {
    setOpen(false)
    session.isAuthenticated && session.logout({ redirectUri: '/' })
  }
  const onActive = () => {
    setOpen(false)
  }
  const onPrompt = () => {
    setOpen(true)
  }
  const { activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout: TIMEOUT,
    promptBeforeIdle: PROMPT_BEFORE_IDLE,
    throttle: 500
  })

  const handleStillHere = () => {
    activate()
  }
  return { open, handleStillHere }
}

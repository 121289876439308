import { faRotateRight } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { UseFilterHook } from '../../hooks/useDataFilter'
import { CheckBox, CheckBoxSize, CheckBoxState } from '../CheckBox/CheckBox'
import {
  List,
  ListHeader,
  ListHeaderText,
  ListItem,
  ListItemText,
  ListWrapper,
  NotificationDot,
  SelectAllListItemText
} from '../TableComponents/FilterCategoryComponents/FilterCategoryComponents'
import { FilterFunctionButton } from '../TableComponents/FilterFunctionButton/FilterFunctionButton'

export function DataFilter({
  title,
  filter,
  slug
}: Readonly<{
  title: string
  filter: UseFilterHook
  slug: string
}>) {
  const { t } = useTranslation()

  return (
    <ListWrapper>
      <ListHeader>
        {filter.selected.length > 0 ? <NotificationDot /> : null}
        <ListHeaderText>{title}</ListHeaderText>
        <FilterFunctionButton
          icon={faRotateRight}
          handleClick={() => filter.reset()}
        />
      </ListHeader>
      <List>
        <ListItem key="all" onClick={() => filter.toggleAll()}>
          <CheckBox
            state={
              filter.allSelected ? CheckBoxState.check : CheckBoxState.unchecked
            }
            size={CheckBoxSize.medium}
          />
          <SelectAllListItemText>
            {t('filter.selection-count', { count: filter.selected.length })}
          </SelectAllListItemText>
        </ListItem>
        {filter.data.map((value) => (
          <ListItem key={value} onClick={() => filter.toggle(value)}>
            <CheckBox
              state={
                filter.selected?.includes(value)
                  ? CheckBoxState.check
                  : CheckBoxState.unchecked
              }
              size={CheckBoxSize.medium}
            />
            <ListItemText>
              {t(
                `data-filter.${slug.toLowerCase()}.${value.toLowerCase()}`,
                value
              )}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </ListWrapper>
  )
}

import { useEffect, useState } from 'react'
import { StackedDailyTableDataObject } from '../components/DailyTable/DailyTable'
import { InsightViewObject } from '../components/Graphs/Insights/interfaces'
import { PeriodObject } from '../components/Header/DevicesAndPeriod/DevicesAndPeriod'
import { TrendsGraphProps } from '../components/TrendsComponents/TrendsGraph/TrendsGraph'
import {
  InjectionWidgetsObject,
  StandardDeviation
} from '../components/WidgetsStack/WidgetsStack'
import { DeviceSettingsResponse } from '../containers/DeviceSettings/DeviceSettings'
import {
  AverageCarbs,
  OverviewResponse,
  TimeInActivityObject
} from '../containers/Overview/Overview'

import { FitbitAverageWidget } from '../components/ActivityComponents/interfaces'
import {
  AverageGlucoseObject,
  DataObject,
  PumpActivity,
  TimeInAutomationObject,
  TimeInRangeObject,
  TotalInsulinObject
} from '../containers/StackedDaily/Interfaces/Interfaces'
import { PatientViewProps, getPatientView } from '../core/api/get-patient-view'
import { Patient } from '../core/entities/patient.entity'

export interface TrendsData {
  type: string
  slices: Array<TrendsGraphProps> | null
  totalCarbs: AverageCarbs | null
  sensorUsage: number
  timeInRange: TimeInRangeObject | null
  totalInsulin: TotalInsulinObject | null
  averageGlucose: AverageGlucoseObject | null
  cv: number | null
  standardDeviation: StandardDeviation | null
  timeInAutomation: TimeInAutomationObject | null
  timeInActivity: TimeInActivityObject | null
  pumpActivity: PumpActivity | null
  insightsView: InsightViewObject | null
  injectionWidgets: InjectionWidgetsObject | null
  maxPenInjection: number | null
  fitbitAverageWidget: FitbitAverageWidget | null
}

export function usePatientData(
  patient: Patient | null,
  period: PeriodObject | null,
  props: PatientViewProps,
  update: any | null
) {
  const [patientViewData, setPatientViewData] = useState<
    | StackedDailyTableDataObject
    | TrendsData
    | DataObject
    | OverviewResponse
    | DeviceSettingsResponse
    | null
  >(null)
  const [patientViewError, setPatientViewError] = useState<Error | null>(null)
  const [patientViewLoading, setPatientViewLoading] = useState<boolean>(false)
  useEffect(() => {
    if (!patient || !period || !period.endDate) return
    setPatientViewError(null)
    setPatientViewLoading(true)
    setPatientViewData(null)
    const controller = new AbortController()
    getPatientView(
      patient.patientId,
      {
        start: new Date(
          new Date(period.endDate).setDate(
            period.endDate.getDate() - period.lengthOfPeriod + 1
          )
        ),
        end: new Date(
          new Date(period.endDate).setDate(period.endDate.getDate())
        )
      },
      period.deviceID,
      { type: props.type, days: props.days },
      controller.signal
    )
      .then((response) => {
        setPatientViewLoading(false)
        setPatientViewData(response.data)
      })
      .catch((error) => {
        setPatientViewLoading(false)
        if (!controller.signal.aborted) setPatientViewError(error)
      })

    return () => {
      controller.abort()
    }
  }, [patient, period, props.days, props.type, update])

  return {
    patientViewData,
    patientViewError,
    patientViewLoading
  }
}

import { faDiagramCells, faTableList } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { usePatientContext } from '../../core/contexts/patient-context'
import { usePeriodContext } from '../../core/contexts/period-context'
import dayjs from '../../core/dayjs/dayjs'
import { usePatientData } from '../../hooks/GetPatientData'
import { DataObject } from './Interfaces/Interfaces'
import { StackedDay } from './StackedDay/StackedDay'

export enum viewStateEnum {
  table,
  cards
}
interface ViewStateProps {
  viewState: viewStateEnum
  setViewState: Dispatch<SetStateAction<viewStateEnum>>
}
interface ViewStatePropsCss {
  isActive: boolean
}
const PageWrapper = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1400px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
`

const ViewOptions = styled.div`
  position: fixed;
  z-index: 3;
  top: 6.375rem;
  right: 2rem;
  display: flex;
  padding: 0.25rem;
  gap: 0.25rem;
  flex-direction: row;
  background-color: var(--white-color);
  border-radius: 0.75rem;
  border: 0.0625rem solid var(--text-lightest);
`
const ViewButton = styled.div<ViewStatePropsCss>`
  padding: 0.75rem 1rem 0.75rem 0.875rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) =>
    props.isActive ? 'var(--brand-primary-color)' : 'var(--text-medium)'};
  background-color: ${(props) =>
    props.isActive ? 'var(--element-bg-dark)' : 'none'};
  cursor: ${(props) => (props.isActive ? 'default' : 'pointer')};
`
const ViewLogo = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: inherit;
`

const maxBolusStandard = 15
const maxBasalStandard = 2
const maxTotalInsulinStandard = 50
const maxPenInjectionStandard = 65

export const ViewOptionsComponent = ({
  viewState,
  setViewState
}: ViewStateProps) => {
  return (
    <ViewOptions>
      <ViewButton
        data-testid="stackedCardsButton"
        isActive={viewState === viewStateEnum.cards}
        onClick={() => setViewState(viewStateEnum.cards)}
      >
        <ViewLogo icon={faDiagramCells}></ViewLogo>
      </ViewButton>
      <ViewButton
        data-testid="stackedTableButton"
        isActive={viewState === viewStateEnum.table}
        onClick={() => setViewState(viewStateEnum.table)}
      >
        <ViewLogo icon={faTableList}></ViewLogo>
      </ViewButton>
    </ViewOptions>
  )
}
export const anchorClick = (id: string) => {
  document.getElementById('daily_nav')?.click()
  setTimeout(() => {
    const anchor = document.createElement('a')
    anchor.href = '#' + id
    anchor.click()
    anchor.remove()
  }, 500)
}

function isStackedDailyData(object: any): object is DataObject {
  return object.name === 'stacked'
}
export function StackedDays() {
  const { patient } = usePatientContext()
  const { period } = usePeriodContext()
  let entries = null
  const { patientViewData, patientViewError, patientViewLoading } =
    usePatientData(
      patient,
      period,
      {
        type: 'stackeddaily',
        days: null
      },
      null
    )

  if (patientViewData && isStackedDailyData(patientViewData)) {
    entries = patientViewData.days
      .slice(0)
      .reverse()
      .map((item, index) => (
        <StackedDay
          key={index}
          index={index}
          day={item.day}
          loading={patientViewLoading}
          maxBolus={patientViewData.maxBolusInsulin}
          maxBasal={patientViewData.maxBasal}
          maxTotalInsulin={patientViewData.maxTotalInsulin}
          maxPenInjection={patientViewData.maxPenInjection}
          deviceEventTypes={patientViewData.deviceEventTypes}
          item={item}
          fitbitActivityMaxes={patientViewData.fitbitActivityMaxes}
        ></StackedDay>
      ))
    return (
      <PageWrapper data-testid={'stackedDailyDataWrapper'}>
        {entries}
      </PageWrapper>
    )
  } else if (patientViewError) {
    return (
      <div data-testid="noDataStackedDaily">{patientViewError.message}</div>
    )
  } else if (period === null || patient === null) {
    return <div data-testid="noDataStackedDaily">this patient has no data</div>
  } else {
    entries = new Array(period.lengthOfPeriod)
      .fill(period.endDate)
      .map((item, index) => (
        <StackedDay
          key={(Math.random() + 1).toString(36).substring(7)}
          index={index}
          day={dayjs(item).subtract(index, 'd').format('YYYY-MM-DD')}
          loading={patientViewLoading}
          maxBolus={maxBolusStandard}
          maxBasal={maxBasalStandard}
          maxTotalInsulin={maxTotalInsulinStandard}
          maxPenInjection={maxPenInjectionStandard}
          deviceEventTypes={[]}
          fitbitActivityMaxes={{
            azm: 0,
            calories: 0,
            distance: 0,
            floors: 0,
            steps: 0
          }}
          item={null}
        ></StackedDay>
      ))
    return (
      <PageWrapper data-testid="stackedDailySkeletonWrapper">
        {entries}
      </PageWrapper>
    )
  }
}

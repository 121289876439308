import { useState } from 'react'
import { NovoPenMedicine } from '../../Insights/interfaces'

export const CustomNovoPenBar = (props: any) => {
  const {
    x,
    x1,
    x2,
    y,
    y1,
    y2,
    fill,
    fillOpacity,
    handleOnMouseEnter,
    handleOnMouseLeave,
    medicine,
    height
  } = props
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const hoverColor =
    medicine === NovoPenMedicine.Basal
      ? 'var(--basal-darker-color)'
      : 'var(--bolus-darker-color)'

  return (
    <g>
      <rect
        data-testid="CustomNovoPenBar"
        fill={isHovered ? hoverColor : fill}
        fillOpacity={fillOpacity}
        x={x}
        y={y}
        x1={x1}
        x2={x2}
        y1={y1}
        y2={y2}
        width={8}
        height={height}
        stroke={'none'}
        strokeOpacity={0}
        onMouseEnter={() => {
          handleOnMouseEnter()
          setIsHovered(true)
        }}
        onMouseLeave={() => {
          handleOnMouseLeave()
          setIsHovered(false)
        }}
      />
    </g>
  )
}

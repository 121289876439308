import { faAddressCard } from '@fortawesome/pro-regular-svg-icons'
import { t } from 'i18next'
import { useState } from 'react'
import { Modal } from '../../../components/Modal/Modal'
import { useSiteSettingsContext } from '../../../core/contexts/site-settings-context'
import { Patient } from '../../../core/entities/patient.entity'
import { User, UserType } from '../../../core/entities/user.entity'
import { InfoBox } from '../InfoBox/InfoBox'
import { SectionComponent } from '../SectionComponent/SectionComponent'
import { InfoFieldComponent } from '../infoFieldComponent/infoFieldComponent'
import { NameChangeModal } from './NameChangeModal/NameChangeModal'

interface BasicInfoProps {
  user: User | null
  patient: Patient | null
}
export const BasicInfo = ({ user, patient }: BasicInfoProps) => {
  const [showChangeNameModal, setShowNameChangeModal] = useState(false)
  const { siteSettings } = useSiteSettingsContext()

  const getInfoBox = () => {
    if (user?.type === UserType.Patient) {
      return (
        <InfoBox
          testid="infoBoxPatient"
          paragraph={t('centralRegistryPatientLocked', {
            regionalLoginSolution: 'MitID'
          })}
        />
      )
    } else if (patient?.setPatientName) {
      return (
        <InfoBox
          testid="infoBoxHCPEDIT"
          paragraph={t('centralRegistryHCPEdit', {
            regionalLoginSolution: 'MitID'
          })}
        />
      )
    } else {
      return (
        <InfoBox
          testid="infoBoxHCPLOCKED"
          paragraph={t('centralRegistryHCPLocked', {
            regionalLoginSolution: 'MitID'
          })}
        />
      )
    }
  }
  return (
    <>
      {showChangeNameModal && patient && (
        <Modal isOpen={showChangeNameModal}>
          <NameChangeModal
            patient={patient}
            closeModal={() => setShowNameChangeModal(false)}
          />
        </Modal>
      )}
      <SectionComponent title={t('Basic Information')} icon={faAddressCard}>
        {getInfoBox()}
        <InfoFieldComponent
          label={t(`${siteSettings?.mrnType}.mrn-title`)}
          value={
            patient
              ? patient.mrn.substring(0, 6) + '-' + patient.mrn.substring(6, 11)
              : ''
          }
          disabled={true}
          edit={false}
        />
        <InfoFieldComponent
          label={t('Full name')}
          value={patient?.name}
          disabled={true}
          edit={patient?.setPatientName ?? false}
          handleEdit={() => setShowNameChangeModal(true)}
        />
      </SectionComponent>
    </>
  )
}

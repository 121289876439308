import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons'
import { NovoPenMedicine } from '../Graphs/Insights/interfaces'

export enum NovoPenDeviceEvent {
  ST_WARNING_EOL = 'ST_WARNING_EOL',
  ST_SENSOR_ERR = 'ST_SENSOR_ERR',
  ST_INTERRUPTED_DOSE = 'ST_INTERRUPTED_DOSE',
  ST_RECOVERABLE_ERR = 'ST_RECOVERABLE_ERR',
  ST_UNRECOVERABLE_ERR = 'ST_UNRECOVERABLE_ERR',
  ST_BIG_DOSE = 'ST_BIG_DOSE',
  ST_CRC_CORRUPTED = 'ST_CRC_CORRUPTED',
  ST_EXP_END_OF_LIFE = 'ST_EXP_END_OF_LIFE',
  ST_DOSE_IN_PROGRESS = 'ST_DOSE_IN_PROGRESS'
}
export enum DeviceEventType {
  GlucoseSensor = 'GlucoseSensor',
  Comments = 'Comments',
  InsulinPump = 'InsulinPump',
  InsulinPen = 'InsulinPen'
}
export enum DeviceEventSubType {
  Calibration = 'Calibration',
  NoInsulin = 'NoInsulin',
  NoDelivery = 'NoDelivery',
  SiteOcclusion = 'SiteOcclusion',
  TimeChange = 'TimeChange',
  Prime = 'Prime',
  ReservoirChange = 'ReservoirChange',
  FillCannula = 'FillCannula',
  FillTube = 'FillTube',
  LowPower = 'LowPower',
  Note = 'Note',
  HighSoon = 'HighSoon',
  LowSoon = 'LowSoon',
  UrgentLow = 'UrgentLow',
  OutOfRange = 'OutOfRange',
  NoReadings = 'NoReadings',
  NovoPenEvent = 'NovoPenEvent',
  Suspended = 'Suspended',
  Resumed = 'Resumed',
  SensorConnected = 'SensorConnected',
  ChangeSensor = 'ChangeSensor',
  NoPower = 'NoPower',
  Unknown = 'Unknown',
  AutoOff = 'AutoOff',
  AppUpload = 'AppUpload'
}

export interface AbstractDeviceEvent {
  time: string | null
  localTime: string | null
  device: string | null
  deviceEventType: DeviceEventType
  deviceEventSubType: DeviceEventSubType
  deviceContext: string
  id: string
}

export interface StatusEvent extends AbstractDeviceEvent {
  duration: number | null
  reasonSuspended: string | null
  reasonResumed: string | null
}
export interface CalibrationEvent extends AbstractDeviceEvent {
  value: number
  units: string
  errorMessage: string | null
}

export interface TimeChangeEvent extends AbstractDeviceEvent {
  from: string
  to: string
  agent: string
}

export interface PrimeEvent extends AbstractDeviceEvent {
  primeTarget: string
  volume: number | null
}

export interface ReservoirChangeEvent extends AbstractDeviceEvent {
  status: string | null
}

export interface DeviceNoteEvent extends AbstractDeviceEvent {
  note: string
}

export interface NovoPenEvent extends AbstractDeviceEvent {
  novoPenEventType: NovoPenDeviceEvent
  penName: string
  penId: string
}

export interface AppUploadEvent extends AbstractDeviceEvent {
  penActivation: string
  penActivationLocal: string
  penId: string
  penName: string
  medicine: NovoPenMedicine
}

export interface DeviceEventConfig {
  icon: IconDefinition
  colors: string
  text: string
}

import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { formatNumber } from '../../../../helpers/helpers'
import { FitbitLevelCalculations, SleepValues } from '../interfaces'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
`
const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`

const HeaderTitle = styled.span`
  font-family: inter;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: var(--text-primary);
`
const HeaderValue = styled.span`
  color: var(--text-lighter);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
`
const Seperator = styled(FontAwesomeIcon)`
  font-size: 0.5rem;
  color: var(--text-lightest);
`

const BarWrapper = styled.div`
  border-radius: 0.125rem;
  background-color: var(--element-bg-dark);
  position: relative;
  width: 100%;
  height: 0.25rem;
`
const Bar = styled.div<{ $width: number; $color: string }>`
  border-radius: 0.25rem;
  width: ${(props) => props.$width}%;
  background-color: ${(props) => props.$color};
  left: 0;
  top: -0.125rem;
  height: 0.5rem;
  position: absolute;
`

const getSleepValueConfig = (type: SleepValues) => {
  switch (type) {
    case SleepValues.awake:
      return { color: 'var(--yellow)', title: 'Awake' }
    case SleepValues.rem:
      return { color: 'var(--pink)', title: 'REM' }
    case SleepValues.light:
      return { color: 'var(--purple)', title: 'Light' }
    case SleepValues.deep:
      return { color: 'var(--text-primary)', title: 'Deep' }
    case SleepValues.asleep:
      return { color: 'var(--purple)', title: 'Asleep' }
    case SleepValues.restless:
      return { color: 'var(--pink)', title: 'Restless' }
  }
}

interface SleepValueProps {
  type: SleepValues
  data: FitbitLevelCalculations
}

export const SleepValue = ({ type, data }: SleepValueProps) => {
  const config = getSleepValueConfig(type)
  const { t } = useTranslation()
  const renderTime = () => {
    if (data.hours < 1) {
      return `${data.minutes} min`
    } else return `${data.hours} h ${data.minutes} m`
  }
  return (
    <Wrapper>
      <Header>
        <HeaderTitle data-testid={`sleepValueTitle${type}`}>
          {t(config.title)}
        </HeaderTitle>
        <Seperator icon={faCircleSmall} />
        <HeaderValue>
          {formatNumber({ value: data.percentage * 100, decimals: 0 })}%
        </HeaderValue>
        <Seperator icon={faCircleSmall} />
        <HeaderValue>{renderTime()}</HeaderValue>
      </Header>
      <BarWrapper>
        <Bar $color={config.color} $width={data.percentage * 100}></Bar>
      </BarWrapper>
    </Wrapper>
  )
}

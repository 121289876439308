import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IntegrationComponent } from '../IntegrationComponent/IntegrationComponent'
import { UploadOrigin } from '../IntegrationComponent/uploadOrigin'

export const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const DeviceListWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  background-color: var(--white-color);
  box-shadow: 0rem 0.25rem 1rem rgba(51, 51, 51, 0.16);
  border-radius: 1.75rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 6.25rem;
  gap: 0.5rem;
`

export const HeaderComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 0.5rem 1rem;
  gap: 1rem;
  width: 47.25rem;
`

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  justify-content: space-between;
`
export const MainTitle = styled.div`
  font-family: inter;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--text-primary);
`
export const SubTitle = styled.div`
  font-family: inter;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: var(--text-lighter);
  display: flex;
  justify-content: center;
  align-items: center;
`
const SelectDiv = styled.div`
  cursor: pointer;
`
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`
export const CancelButton = styled.button`
  padding: 0.75rem 1rem 0.75rem 0.875rem;
  border-radius: 0.5rem;
  background-color: var(--element-bg);
  font-family: inter;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--text-primary);
  border: none;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    background-color: var(--element-bg-dark);
  }
`
export const ApplyButton = styled.button`
  padding: 0.75rem 1rem 0.75rem 0.875rem;
  border-radius: 0.5rem;
  background-color: var(--brand-primary-color);
  font-family: inter;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--white-color);
  border: none;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    background-color: var(--brand-darker-color);
  }
  &:disabled {
    background-color: var(--brand-primary-12-color);
    cursor: auto;
  }
`
const DeviceListComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`

export type devicesObject = {
  device: string
  notification: boolean
  title: string
  serial: string | null
  time: string
  statusBar: boolean
  active: boolean
  suspended: boolean
  checkBox: boolean
  patientDeviceIds: Array<string>
  uploadOrigin: string
  deviceDisplayName: string
}
type DeviceListProps = {
  checkBoxStates: null | Array<boolean>
  setCheckBoxStates: null | any
  devices: null | Array<devicesObject>
  handleShowDevices: () => void
  handleCheckBox: () => void
}

export const DeviceList = ({
  checkBoxStates,
  setCheckBoxStates,
  devices,
  handleShowDevices,
  handleCheckBox
}: DeviceListProps) => {
  const { t } = useTranslation()
  const [temporaryCheckboxStates, setTemporaryCheckboxStates] = useState(
    devices ? devices.map((item) => item.checkBox) : null
  )
  const handleSingleCheckBox = (index: number) => {
    const array = temporaryCheckboxStates
      ? temporaryCheckboxStates.slice(0)
      : null
    if (array) array[index] = !array[index]
    setTemporaryCheckboxStates(array)
    setCheckBoxStates(array)
  }

  const DevicesToRender =
    devices && temporaryCheckboxStates ? (
      devices.map((item, index) => (
        <IntegrationComponent
          key={item.serial}
          index={index}
          notification={item.notification}
          title={item.deviceDisplayName}
          serial={item.serial}
          time={item.time}
          uploadOrigin={item.uploadOrigin as UploadOrigin}
          statusBar={item.statusBar}
          active={item.active}
          suspended={item.suspended}
          device={item.device}
          checkBox={temporaryCheckboxStates[index]}
          handleSingleCheckBox={handleSingleCheckBox}
        ></IntegrationComponent>
      ))
    ) : (
      <div>{t('No devices')}</div>
    )
  const DisabledCheck = (
    temporaryCheckboxStates: null | Array<boolean>,
    devices: null | Array<devicesObject>
  ) => {
    if (temporaryCheckboxStates && devices) {
      if (!temporaryCheckboxStates.includes(true)) return true
      return !devices
        .map((item, index) => item.checkBox === temporaryCheckboxStates[index])
        .includes(false)
    } else return true
  }
  function SelectAll() {
    setCheckBoxStates(devices ? Array(devices.length).fill(true) : null)
    setTemporaryCheckboxStates(
      devices ? Array(devices.length).fill(true) : null
    )
  }
  function DeSelectAll() {
    setCheckBoxStates(devices ? Array(devices.length).fill(false) : null)
    setTemporaryCheckboxStates(
      devices ? Array(devices.length).fill(false) : null
    )
  }
  useEffect(() => {
    const close = (e: any) => {
      if (e.key === 'Escape') {
        handleShowDevices()
      }
    }
    window.addEventListener('keyup', close)
    return () => window.removeEventListener('keyup', close)
  }, [handleShowDevices])

  return (
    <RelativeWrapper
      onClick={function (event) {
        event.stopPropagation()
      }}
    >
      <DeviceListWrapper data-testid="devicelist">
        <HeaderComponent>
          <HeaderTitle>
            <MainTitle>{t('Select data sources to be shown')}</MainTitle>
            <SubTitle>
              <SelectDiv onClick={SelectAll} data-testid="selectall">
                {t('Select all')}
              </SelectDiv>
              &nbsp; &nbsp;
              <SelectDiv onClick={DeSelectAll} data-testid="deselectall">
                {t('De-select all')}
              </SelectDiv>
            </SubTitle>
          </HeaderTitle>
          <ButtonWrapper>
            <CancelButton onClick={handleShowDevices}>
              {t('Cancel')}
            </CancelButton>
            <ApplyButton
              data-testid="applyButton"
              onClick={handleCheckBox}
              disabled={DisabledCheck(temporaryCheckboxStates, devices)}
            >
              {t('Apply Changes')}
            </ApplyButton>
          </ButtonWrapper>
        </HeaderComponent>

        <DeviceListComponent>{DevicesToRender}</DeviceListComponent>
      </DeviceListWrapper>
    </RelativeWrapper>
  )
}

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import dayjs from '../../../../core/dayjs/dayjs'
import { DeviceEventIcon } from '../../DeviceEventIcon/DeviceEventIcon'
import { AbstractDeviceEvent, DeviceEventSubType } from '../../Interfaces'
import { getDeviceEventConfig } from '../../getDeviceEventConfig'

const BodyWrapper = styled.div<{ $last: boolean }>`
  width: 100%;
  padding: 1rem 0rem;
  border-bottom: ${(props) =>
    !props.$last ? '0.0625rem solid var(--text-lightest)' : 'none'};
`

const Body = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const IconWrapper = styled.div`
  width: 2rem;
  display: flex;
  justify-content: center;
  padding-top: 1.625rem;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`
const Title = styled.span`
  font-family: inter;
  color: var(--text-lighter);
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
`
const EventText = styled.span`
  color: var(--text-primary)
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 150%;
`
export const DeviceEventListEntry = ({
  event,
  last
}: {
  event: AbstractDeviceEvent
  last: boolean
}) => {
  const { t } = useTranslation()
  const config = getDeviceEventConfig(event, t)
  return (
    <BodyWrapper data-testid="DeviceEventListEntry" $last={last}>
      <Body>
        <IconWrapper>
          <DeviceEventIcon config={config} isHovered={false} />
        </IconWrapper>
        <Column>
          <TitleRow>
            <Title>{event.deviceContext}</Title>
            <Title>{dayjs(event.localTime).format('HH:mm')}</Title>
          </TitleRow>
          <EventText
            style={
              event.deviceEventSubType === DeviceEventSubType.Note
                ? { whiteSpace: 'pre' }
                : {}
            }
          >
            {config?.text}
          </EventText>
        </Column>
      </Body>
    </BodyWrapper>
  )
}

import dayjs from 'dayjs'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Bar,
  BarChart,
  Label,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts'
import { referenceLines } from '../BasalGraph/BasalGraph'
import { calculateInsightXValue } from '../GlucoseGraph/GlucoseGraph'
import {
  MergedNovoPenEventValue,
  NovoPenEventValue,
  NovoPenMedicine
} from '../Insights/interfaces'
import { CustomNovoPenBar } from './CustomNovoPenBar/CustomNovoPenBar'
import { InsightTooltipProps } from './PenInjectionTooltip/PenInjectionTooltip'

interface PenInjectionsGraphProps {
  data: (NovoPenEventValue | MergedNovoPenEventValue)[] | null
  maxPenInjection: number | null
  setShowPenInjectionTooltip: Dispatch<
    SetStateAction<InsightTooltipProps | null>
  >
  insights: null | {
    timestamp: string
    penEvents: (NovoPenEventValue | MergedNovoPenEventValue)[]
    setPenTooltip: Dispatch<SetStateAction<InsightTooltipProps | null>>
  }
}
interface GraphDataElement {
  time: string
  timeAsNumber: number
  value: number
  medicine: NovoPenMedicine
  id: number
  element: NovoPenEventValue | MergedNovoPenEventValue
}

const CustomizedLabel = (props: any) => {
  const { t } = useTranslation()
  return (
    <>
      <text
        x={20}
        y={16}
        fontFamily="inter"
        fontWeight="600"
        fontSize="0.75rem"
      >
        <tspan fill="var(--text-primary)">{t('Pen Injections')}</tspan>
        <tspan dx={4} fill="var(--text-lighter)">
          U
        </tspan>
      </text>
    </>
  )
}

const CustomReferenceArea = (props: any) => {
  const isHovered = props.hoverState === props.item.id
  const hoverColor =
    props.item.medicine === NovoPenMedicine.Basal
      ? 'var(--basal-darker-color)'
      : 'var(--bolus-darker-color)'
  return (
    <g
      onMouseEnter={async () => {
        await props.redraw(props.data, props.item.id)
        props.setHoverState(props.item.id)
        props.setShowPenInjectionTooltip({
          data: {
            value: props.item.element,
            pos: props.item.timeAsNumber,
            time: props.item.time,
            yMax: props.yDomainMax
          }
        })
      }}
      onMouseLeave={() => {
        props.setHoverState(null)
        props.setShowPenInjectionTooltip(null)
      }}
    >
      <rect
        fill={isHovered ? hoverColor : props.fill}
        fillOpacity={1}
        x={props.x}
        y={props.y}
        x1={props.x1}
        x2={props.x2}
        y1={props.y1}
        y2={props.y2}
        width={8}
        height={props.height}
        stroke={'none'}
        strokeOpacity={0}
      />
    </g>
  )
}

export const PenInjectionsGraph = ({
  data,
  maxPenInjection,
  setShowPenInjectionTooltip,
  insights
}: PenInjectionsGraphProps) => {
  const yDomainMax = 2 * Math.round(((maxPenInjection ?? 65) + 1) / 2)
  const xDomainMax = 24
  const [tickArray, setTickArray] = useState<number[]>([])
  const [graphData, setGraphData] = useState<Array<GraphDataElement>>([])
  const [hoverState, setHoverState] = useState<number | null>(null)
  useEffect(() => {
    const array = []
    const fillTickArray = () => {
      const tempTickArray = []
      tempTickArray.push(0)
      tempTickArray.push(yDomainMax / 2)
      tempTickArray.push(yDomainMax)
      setTickArray(tempTickArray)
    }
    fillTickArray()
    if (data && data.length > 0) {
      for (const [idx, element] of data.entries()) {
        array.push({
          time: element.localTime,
          timeAsNumber:
            dayjs(element.localTime).hour() +
            dayjs(element.localTime).minute() / 60 +
            dayjs(element.localTime).second() / 3600,
          value: 'units' in element ? element.units : element.totalUnits,
          medicine: element.drug,
          id: idx,
          element
        })
      }
    }
    setGraphData(array)
  }, [data, yDomainMax])

  function redraw(arr: Array<GraphDataElement>, id: number) {
    const copy = [...arr]
    const dot = copy.find((element) => element.id === id)
    copy.splice(
      copy.findIndex((element) => element.id === id),
      1
    )
    if (dot) copy.push(dot)
    setGraphData(copy)
  }

  return (
    <ResponsiveContainer>
      <BarChart
        margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
        data={graphData}
      >
        <ReferenceArea
          x1={0}
          x2={xDomainMax}
          y1={0}
          y2={yDomainMax}
          fill={'var(--element-bg)'}
          stroke={'var(--text-lightest)'}
          radius={[0, 4, 4, 0]}
          fillOpacity={1}
        />
        {insights === null && referenceLines('bolus')}
        {insights !== null && (
          <ReferenceLine
            x={14.4}
            stroke="var(--veryLow-color)"
            key={`hypoLine`}
          />
        )}
        <XAxis
          type="number"
          ticks={[
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
            19, 20, 21, 22, 23, 24
          ]}
          domain={[0, xDomainMax]}
          tick={false}
          dataKey="timeAsNumber"
          name="timeAsNumber"
          tickLine={false}
          orientation={'bottom'}
          axisLine={false}
          height={1}
        >
          <Label content={<CustomizedLabel />} />
        </XAxis>
        <YAxis
          stroke="#979797"
          fontSize="0.875rem"
          dataKey="value"
          name="value"
          interval={0}
          ticks={tickArray}
          tickFormatter={(value) => `${value},0`}
          tick={{
            fill: 'var(--text-lighter)',
            fontSize: '0.75rem',
            fontWeight: '600',
            fontFamily: 'inter'
          }}
          tickLine={{ opacity: 1, stroke: 'var(--text-lightest)' }}
          domain={[0, yDomainMax]}
          axisLine={false}
          width={15}
        />

        <Bar dataKey={'value'} fillOpacity={0} />

        {graphData.length > 0 &&
          graphData.map((item, index) => (
            <ReferenceArea
              key={'bar' + index}
              x1={item.timeAsNumber - 0.1 >= 0 ? item.timeAsNumber - 0.1 : 0}
              x2={item.timeAsNumber + 0.1 <= 24 ? item.timeAsNumber + 0.1 : 24}
              y1={0.1}
              y2={item.value ? item.value + 0.1 : 0.1}
              fill={
                item.medicine === NovoPenMedicine.Bolus
                  ? 'var(--bolus-color)'
                  : 'var(--basal-color)'
              }
              shape={
                <CustomReferenceArea
                  data={graphData}
                  hoverState={hoverState}
                  setHoverState={setHoverState}
                  redraw={redraw}
                  setShowPenInjectionTooltip={setShowPenInjectionTooltip}
                  item={item}
                  yDomainMax={yDomainMax}
                />
              }
            />
          ))}

        {insights !== null &&
          insights.penEvents.length > 0 &&
          insights.penEvents.map((penEvent) => {
            const t = dayjs(penEvent.localTime)
            const tNumber = t.hour() + t.minute() / 60 + t.second() / 3600
            const timestamp = dayjs(insights.timestamp)
            const timestampNumber =
              timestamp.hour() +
              timestamp.minute() / 60 +
              timestamp.second() / 3600
            const difference = tNumber - timestampNumber
            const x = calculateInsightXValue(
              timestamp,
              t,
              difference,
              timestampNumber,
              tNumber
            )
            return (
              <ReferenceArea
                key={'penInjection' + x}
                y1={0.1}
                y2={'units' in penEvent ? penEvent.units : penEvent.totalUnits}
                x1={x - 0.1 >= 0 ? x - 0.1 : 0}
                x2={x + 0.1 <= 24 ? x + 0.1 : 24}
                fill={
                  penEvent.drug === NovoPenMedicine.Basal
                    ? 'var(--basal-color)'
                    : 'var(--bolus-color)'
                }
                fillOpacity={1}
                shape={
                  <CustomNovoPenBar
                    handleOnMouseEnter={() =>
                      insights.setPenTooltip({
                        data: {
                          value: penEvent,
                          time: penEvent.localTime,
                          pos: x,
                          yMax: yDomainMax
                        }
                      })
                    }
                    handleOnMouseLeave={() => insights.setPenTooltip(null)}
                    medicine={penEvent.drug}
                  />
                }
              />
            )
          })}
      </BarChart>
    </ResponsiveContainer>
  )
}

import { ReactNode, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useBlurContext } from '../../core/contexts/blur-context'
import { handleTableScroll } from './handleTableScroll'

const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Table = styled.table`
  width: 100%;
  font-family: inter;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--text-primary);
  position: relative;
  text-align: left;
  border-collapse: seperate;
  height: 1px;
  border-spacing: 0;
  border-radius: 0.5rem;
  & tr:last-child {
    & td:last-child {
      border-bottom-right-radius: 0.5rem;
    }
    & td:first-child {
      border-bottom-left-radius: 0.5rem;
    }
  }
  & tr:first-child {
    & th:last-child {
      border-top-right-radius: 0.5rem;
    }
    & th:first-child {
      border-top-left-radius: 0.5rem;
      border-left: 0.0625rem solid var(--text-lightest);
    }
  }
`
export const Tr = styled.tr`
  height: 3rem;
  box-sizing: border-box;
`
export const THead = styled.tr`
  position: sticky;
  z-index: 3;
  top: 8.25rem;
  border-top: 0.0625rem solid var(--text-lightest);
  backdrop-filter: blur(0.25rem);
`
export const Td = styled.td`
  padding: 0.375rem 0.5rem;
  height: 3rem;
  box-sizing: border-box;
  border-bottom: 0.0625rem solid var(--text-lightest);
  border-right: 0.0625rem solid var(--text-lightest);
  &:first-child {
    border-left: 0.0625rem solid var(--text-lightest);
  }
  background-color: var(--white-color);
`
export const Th = styled.th`
  font-weight: 600;
  padding: 0.375rem 0.5rem;
  height: 3rem;
  box-sizing: border-box;
  background-color: var(--element-bg-90);
  border-top: 0.0625rem solid var(--text-lightest);
  border-bottom: 0.0625rem solid var(--text-lightest);
  border-right: 0.0625rem solid var(--text-lightest);
`
export const TText = styled.span`
  white-space: nowrap;
  font-weight: 600;
`
interface TableComponentProps {
  children?: ReactNode
  group?: ReactNode
}
export const ScrollTableComponent = ({
  children,
  group
}: TableComponentProps) => {
  const tableRef = useRef<HTMLTableElement | null>(null)
  const { setBlur } = useBlurContext()
  useEffect(() => {
    return () => {
      setBlur(false)
    }
  }, [setBlur])
  return (
    <TableWrapper
      onScroll={() => handleTableScroll(tableRef, setBlur)}
      onWheel={() => handleTableScroll(tableRef, setBlur)}
      ref={tableRef}
      data-testid="ScrollTableComponent"
    >
      <Table>
        {group}
        <tbody>{children}</tbody>
      </Table>
    </TableWrapper>
  )
}

import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export enum CheckBoxState {
  check,
  unchecked,
  inactive
}

export enum CheckBoxSize {
  small,
  medium,
  large
}

interface WrapperProps {
  $active: boolean | null
  $size: CheckBoxSize
}

const getStyle = (size: CheckBoxSize) => {
  switch (size) {
    case CheckBoxSize.small:
      return { size: '1rem', borderRadius: '0.25rem', fontSize: '0.75rem' }
    case CheckBoxSize.medium:
      return { size: '1.5rem', borderRadius: '0.375rem', fontSize: '1.125rem' }
    case CheckBoxSize.large:
      return { size: '2rem', borderRadius: '0.25rem', fontSize: '1.5rem' }
  }
}
export const Wrapper = styled.div<WrapperProps>`
  width: ${(props) => getStyle(props.$size).size};
  height: ${(props) => getStyle(props.$size).size};
  background-color: ${(props) =>
    props.$active ? 'var(--brand-primary-color)' : 'var(--white-color)'};
  border: ${(props) =>
    props.$active
      ? '0.0625rem solid var(--brand-darker-color)'
      : '0.0625rem solid var(--element-stroke)'};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => getStyle(props.$size).borderRadius};
  cursor: pointer;
  z-index: 1;
`
export const Icon = styled(FontAwesomeIcon)<WrapperProps>`
  font-size: ${(props) => getStyle(props.$size).fontSize};
  color: ${(props) =>
    props.$active ? 'var(--white-color)' : 'var(--text-lighter)'};
`

interface CheckBoxProps {
  state: CheckBoxState
  size: CheckBoxSize
}
export const CheckBox = ({ state, size }: CheckBoxProps) => {
  const active = state === CheckBoxState.check
  return (
    <Wrapper $active={active} $size={size}>
      {state !== CheckBoxState.unchecked && (
        <Icon $active={active} $size={size} icon={faCheck} />
      )}
    </Wrapper>
  )
}

import {
  faArrowDownFromLine,
  faArrowUpFromLine,
  faCircleQuestion
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DoseRecommendationState } from '../../../../core/api/getDoseRecommendations'
import { Modal, ModalComponent } from '../../../Modal/Modal'
import { DoseEditorState } from '../DoseEditor/DoseEditorBadge/DoseEditorBadge'
import { CgmDataModal } from './CgmDataModal/CgmDataModal'

const Wrapper = styled.div<{ $color: string }>`
  color: ${(props) => props.$color};
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`

const ArrowIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: inherit;
`

const QuestionIcon = styled(FontAwesomeIcon)`
  color: var(--brand-primary-color);
  font-size: 1rem;
  line-height: 135%;
  cursor: pointer;
`
const Text = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: inherit;
`
interface DoseChangeMessageProps {
  state: DoseEditorState
  previousDose: number
  nextDose: number
  doseState: DoseRecommendationState
}

export const DoseChangeMessage = ({
  state,
  previousDose,
  nextDose,
  doseState
}: DoseChangeMessageProps) => {
  const { t } = useTranslation()
  const [showCgmDataModal, setShowCgmDataModal] = useState(false)
  const changeValue = nextDose - previousDose
  const getConfig = () => {
    if (
      state === DoseEditorState.auto &&
      doseState === DoseRecommendationState.LowSensorCoverage
    ) {
      return {
        icon: (
          <QuestionIcon
            data-testid="showCGMDataTrigger"
            icon={faCircleQuestion}
            onClick={() => setShowCgmDataModal(true)}
          />
        ),
        text: t('Less than 80% CGM data for the last 3 days'),
        color: 'var(--text-medium)'
      }
    } else if (nextDose === previousDose)
      return { icon: null, text: t('No change'), color: 'var(--green)' }
    else
      return {
        icon:
          changeValue < 0 ? (
            <ArrowIcon icon={faArrowDownFromLine} />
          ) : (
            <ArrowIcon icon={faArrowUpFromLine} />
          ),
        text: t(changeValue < 0 ? 'doseDecreased' : 'doseIncreased', {
          value: Math.abs(changeValue)
        }),
        color: state === DoseEditorState.auto ? 'var(--pink)' : 'var(--blue)'
      }
  }
  const config = getConfig()
  return (
    <>
      <Modal isOpen={showCgmDataModal}>
        <ModalComponent
          closeFun={() => setShowCgmDataModal(false)}
          closetestid="closeCgmDataModal"
        >
          <CgmDataModal></CgmDataModal>
        </ModalComponent>
      </Modal>
      <Wrapper $color={config.color}>
        {config.icon}
        <Text data-testid="doseChangeMessageText">{config.text}</Text>
      </Wrapper>
    </>
  )
}

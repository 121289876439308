import styled from 'styled-components'
import dayjs from '../../../../core/dayjs/dayjs'
import { Header, MainText } from '../../../BasalTooltip/BasalTooltip'
import { DeviceEventIcon } from '../../../DeviceEvent/DeviceEventIcon/DeviceEventIcon'
import {
  AbstractDeviceEvent,
  DeviceEventConfig,
  DeviceEventSubType
} from '../../../DeviceEvent/Interfaces'

const Wrapper = styled.div<{ leftPos: number }>`
  width: 22.5rem;
  border-radius: 0.5rem;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.0625rem solid var(--text-lightest);
  position: absolute;
  top: 4rem;
  left: ${(props) => `calc(${props.leftPos}% + 10px)`};
  transform: translateX(-50%);
  box-shadow: 0rem 0.25rem 0.5rem 0rem var(--tooltip-shadow);
  z-index: 3;
  overflow: hidden;
`

const BodyWrapper = styled.div`
  width: 100%;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
`

const Body = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const IconWrapper = styled.div`
  width: 2rem;
  display: flex;
  justify-content: center;
  padding-top: 1.625rem;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: calc(100% - 2.75rem);
`
const Title = styled.span`
  font-family: inter;
  color: var(--text-lighter);
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
`
const EventText = styled.span`
  color: var(--text-primary)
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 150%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  width: 100%;
  `
export const EventTooltip = ({
  currentTooltip
}: {
  currentTooltip: {
    event: AbstractDeviceEvent
    config: DeviceEventConfig | null
    timestampAsNumber: number
  }
}) => {
  const getPosition = () => {
    let pos = (currentTooltip.timestampAsNumber / 24) * 100
    if (pos < 15.5) {
      pos = 15.5
    } else if (pos > 82.5) {
      pos = 82.5
    }
    return pos
  }
  return (
    <Wrapper leftPos={getPosition()}>
      <Header>
        <MainText>
          {dayjs(currentTooltip.event.localTime).format('HH:mm')}
        </MainText>
      </Header>
      <BodyWrapper>
        <Body>
          <IconWrapper>
            <DeviceEventIcon config={currentTooltip.config} isHovered={false} />
          </IconWrapper>
          <Column>
            <Title data-testid="deviceEventContext">
              {currentTooltip.event.deviceContext}
            </Title>
            <EventText
              data-testid="deviceEventText"
              style={
                currentTooltip.event.deviceEventSubType ===
                DeviceEventSubType.Note
                  ? { whiteSpace: 'pre' }
                  : {}
              }
            >
              {currentTooltip.config?.text}
            </EventText>
          </Column>
        </Body>
      </BodyWrapper>
    </Wrapper>
  )
}

import { useCallback, useEffect, useReducer, useState } from 'react'

const DEFAULT_PAGE_SIZE = 20

interface PaginationState {
  pageIndex: number
  pageSize: number
}

export enum FetchStatus {
  Idle,
  Loading,
  Error,
  Success
}

enum FetchActionType {
  Fetch,
  Error,
  Success
}

export type FetchAction<T> =
  | {
      type: FetchActionType.Fetch
    }
  | {
      type: FetchActionType.Error
      payload: Error
    }
  | {
      type: FetchActionType.Success
      payload: T
    }

interface FetchState<T> {
  response: T | null
  error: Error | null
  status: FetchStatus
}

export function useFetch<DataType, ParamsType>(
  fetchFunction: any,
  params: ParamsType
) {
  const fetchMemoized = useCallback(fetchFunction, [fetchFunction])
  const [canFetch, setCanFetch] = useState(false)
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE
  })
  const [state, dispatch] = useReducer<
    React.Reducer<FetchState<DataType>, FetchAction<DataType>>
  >(fetchReducer, {
    response: null,
    error: null,
    status: FetchStatus.Idle
  })

  useEffect(() => {
    setPagination((state) => ({ ...state, pageIndex: 0 }))
  }, [params])

  useEffect(() => {
    setCanFetch(true)
  }, [pagination])

  useEffect(() => {
    if (!canFetch) return

    const controller = new AbortController()

    const fetch = async () => {
      dispatch({ type: FetchActionType.Fetch })
      try {
        if (!controller.signal.aborted) {
          const response = await fetchMemoized(
            {
              page: pagination.pageIndex + 1,
              resultsPerPage: pagination.pageSize,
              ...params
            },
            { signal: controller.signal }
          )

          dispatch({
            type: FetchActionType.Success,
            payload: response as DataType
          })
        }
      } catch (error) {
        if (!controller.signal.aborted) {
          dispatch({ type: FetchActionType.Error, payload: error as Error })
        }
      } finally {
        setCanFetch(false)
      }
    }

    fetch()

    return () => controller.abort()
  }, [fetchMemoized, params, pagination, canFetch])

  function refresh() {
    setCanFetch(true)
  }

  return {
    ...state,
    refresh,
    pagination,
    setPagination
  }
}

function fetchReducer<T>(
  state: FetchState<T>,
  action: FetchAction<T>
): FetchState<T> {
  switch (action.type) {
    case FetchActionType.Fetch:
      return {
        ...state,
        status: FetchStatus.Loading,
        error: null,
        response: null
      }
    case FetchActionType.Error:
      return {
        ...state,
        status: FetchStatus.Error,
        error: action.payload,
        response: null
      }
    case FetchActionType.Success:
      return {
        ...state,
        status: FetchStatus.Success,
        response: action.payload,
        error: null
      }
    default:
      return state
  }
}

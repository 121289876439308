import { ProviderPatient } from '../../containers/Integrations/Integrations'
import config from '../config'
import { AxiosRequest } from './axios-request'

export async function getPatientProviders(
  patientId: string
): Promise<Array<ProviderPatient>> {
  const data = await AxiosRequest.create().get(
    `${config.API_URL}/cmi/patients/${patientId}`
  )
  return data.data as Array<ProviderPatient>
}

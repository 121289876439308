import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { usePatientContext } from '../../../../core/contexts/patient-context'
import { usePeriodContext } from '../../../../core/contexts/period-context'
import { Patient } from '../../../../core/entities/patient.entity'
import { WardComponent } from './WardComponent/WardComponent'

const Wrapper = styled.div`
  display: flex;
  padding: 1rem;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 46.625rem;
  border-radius: 28px;
  opacity: 0.97;
  background-color: var(--white-color);
  box-shadow: 0rem 0.25rem 1rem 0rem var(--modalBackground);
  gap: 0.5rem;
`

export const Header = styled.div`
  display: flex;
  padding: 0rem 0.5rem 1rem 0.5rem;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`

export const HeaderTitle = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  color: var(--text-primary);
  font-weight: 600;
  line-height: 2.5rem;
`
export const HeaderButtons = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
`

export const HeaderButton = styled.button`
  display: flex;
  padding: 0.75rem 1rem;
  box-sizing: border-box;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  font-family: inter;
  font-size: 0.875rem;
  color: var(--text-primary);
  font-weight: 600;
  line-height: 1rem;
  border-radius: 0.5rem;
  background-color: var(--element-bg);
  border: none;
  cursor: pointer;
  &:hover {
    background-color: var(--element-bg-dark);
  }
´
`
export const PrimaryHeaderButton = styled(HeaderButton)`
  background-color: var(--brand-primary-color);
  color: var(--white-color);
  &:hover {
    background-color: var(--brand-darker-color);
  }
  &:disabled {
    background-color: var(--brand-primary-12-color);
    cursor: auto;
  }
`
export const InfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.5rem;
  background-color: var(--element-bg);
`
export const InfoWrapper = styled.div`
  display: flex;
  padding: 1.5rem;
  align-items: flex-start;
  gap: 0.75rem;
`

export const InfoIcon = styled(FontAwesomeIcon)`
  color: var(--blue);
  font-size: 1.125rem;
  line-height: 1.25rem;
`
export const MainBodyText = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: var(--text-primary);
`
interface ChoosePatientModalProps {
  originalPatient: Patient | null
  wards: Array<Patient | undefined>
  currentlyViewedPatient: Patient
  closeFun: () => void
}
export const ChoosePatientModal = ({
  wards,
  originalPatient,
  currentlyViewedPatient,
  closeFun
}: ChoosePatientModalProps) => {
  const { t } = useTranslation()
  const { setPatient } = usePatientContext()
  const { setPeriod } = usePeriodContext()
  const [selectedPatient, setSelectedPatient] = useState<Patient>(
    currentlyViewedPatient
  )
  const setNewPatient = () => {
    setPeriod(null)
    setPatient(null)
    setPatient(selectedPatient)
    closeFun()
  }
  return (
    <Wrapper>
      <Header>
        <HeaderTitle>{t('Select which person you want to view')}</HeaderTitle>
        <HeaderButtons>
          <HeaderButton
            data-testid="cancelChoosePatientModal"
            onClick={closeFun}
          >
            {t('Cancel')}
          </HeaderButton>
          <PrimaryHeaderButton
            disabled={currentlyViewedPatient.mrn === selectedPatient.mrn}
            onClick={setNewPatient}
          >
            {t('Apply Changes')}
          </PrimaryHeaderButton>
        </HeaderButtons>
      </Header>
      <InfoContainer>
        <InfoWrapper>
          <InfoIcon icon={faInfoCircle}></InfoIcon>
          <MainBodyText>
            {t(
              'Guardian and ward relationships are fetched from the national CPR register.'
            )}
          </MainBodyText>
        </InfoWrapper>
      </InfoContainer>
      <WardComponent
        patient={originalPatient}
        wards={wards}
        selectedPatient={selectedPatient}
        setSelectedPatient={setSelectedPatient}
      />
    </Wrapper>
  )
}

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DailyActivity } from '../../containers/Overview/Overview'
import { AverageGlucoseObject } from '../../containers/StackedDaily/Interfaces/Interfaces'
import { useSiteSettingsContext } from '../../core/contexts/site-settings-context'
import { Capabilities } from '../../core/entities/siteSettings.entity'
import { CgmEnum } from '../CGMWidget/CGMWidget'
import { WidgetState } from '../DataWidget/Shared/Interfaces/Interfaces'
import { TooltipType } from '../InformationTooltip/InformationTooltipParagraphs'
import { Stat } from './Stat/Stat'
interface StatsProps {
  averageGlucose: AverageGlucoseObject | null
  totalCarbs: number | null
  dailyActivity: DailyActivity | null
  state: WidgetState
}

const Wrapper = styled.div`
  display: Flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`

export const DataWidgetStats = ({
  averageGlucose,
  totalCarbs,
  state,
  dailyActivity
}: StatsProps) => {
  const { siteSettings } = useSiteSettingsContext()
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Stat
        title={t('Avg. Glucose')}
        value={averageGlucose?.average ?? null}
        unit={'mmol/L'}
        state={state}
        type={CgmEnum.avg}
      />
      {!siteSettings?.capabilities.includes(Capabilities.adaptt2d) && (
        <Stat
          title={t('Total Carbohydrates')}
          value={totalCarbs}
          unit={'g'}
          state={state}
          type={TooltipType.totalCarbs}
        />
      )}
    </Wrapper>
  )
}

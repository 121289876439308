import { Dispatch, SetStateAction, useState } from 'react'
import styled from 'styled-components'

import {
  ActivityGraph,
  ActivityGraphSize
} from '../../../../components/ActivityComponents/ActivityGraph/ActivityGraph'
import {
  BasalTooltip,
  BasalTooltipState
} from '../../../../components/BasalTooltip/BasalTooltip'
import {
  BolusTooltip,
  BolusTooltipProps
} from '../../../../components/BolusTooltip/BolusTooltip'
import { BasalGraph } from '../../../../components/Graphs/BasalGraph/BasalGraph'
import { BolusGraph } from '../../../../components/Graphs/BolusGraph/BolusGraph'
import { EventGraph } from '../../../../components/Graphs/EventGraph/EventGraph'
import { GlucoseGraph } from '../../../../components/Graphs/GlucoseGraph/GlucoseGraph'
import { PenInjectionsGraph } from '../../../../components/Graphs/PenInjections/PenInjections'
import {
  InsightTooltipProps,
  PenInjectionTooltip
} from '../../../../components/Graphs/PenInjections/PenInjectionTooltip/PenInjectionTooltip'
import {
  Absolute,
  NoDataComponent,
  Relative
} from '../../../../components/NoData/NoData'
import { Spinner, spinnerSize } from '../../../../components/Spinner/Spinner'
import {
  PatientViewDisplays,
  usePatientViewsContext
} from '../../../../core/contexts/patient-view-context'
import { useSiteSettingsContext } from '../../../../core/contexts/site-settings-context'
import { Capabilities } from '../../../../core/entities/siteSettings.entity'
import { DaysObject, FitbitActivityMaxes } from '../../Interfaces/Interfaces'
import { SensorCoverageDisplay } from '../SensorCoverageDisplay/SensorCoverageDisplay'

interface StackedDailyGraphsProps {
  item: DaysObject | null
  setShowAlarmList: Dispatch<SetStateAction<boolean>>
  loading: boolean
  maxBolus: number | null
  maxBasal: number | null
  maxPenInjection: number | null
  fitbitActivityMaxes: FitbitActivityMaxes
}
const GlucoseWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 11.875rem;
  z-index: 2;
`

const BolusWrapper = styled(GlucoseWrapper)`
  height: 8.25rem;
`

const PenInjectionsWrapper = styled(BolusWrapper)`
  position: relative;
`
const BasalWrapper = styled(BolusWrapper)``

const GraphsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  z-index: 2;
  width: 1134px;
  min-width: 700px;
`
export const StackedDayGraphs = ({
  item,
  setShowAlarmList,
  loading,
  maxBolus,
  maxPenInjection,
  maxBasal,
  fitbitActivityMaxes
}: StackedDailyGraphsProps) => {
  const { patientViews } = usePatientViewsContext()
  const [bolusHover, setBolusHover] = useState<boolean>(false)
  const [showBasalTooltip, setShowBasalTooltip] =
    useState<BasalTooltipState | null>(null)
  const [showBolusTooltip, setShowBolusTooltip] =
    useState<BolusTooltipProps | null>(null)
  const [showPenInjectionTooltip, setShowPenInjectionTooltip] =
    useState<InsightTooltipProps | null>(null)
  const { siteSettings } = useSiteSettingsContext()
  return (
    <GraphsContainer>
      <EventGraph
        loading={loading}
        events={item?.deviceEvents ?? null}
        setShowAlarmList={setShowAlarmList}
      />
      <GlucoseWrapper>
        {!item?.glucoseEvents && !loading && (
          <NoDataComponent
            style={{
              transform: 'translateX(-40%)',
              zIndex: '2'
            }}
          />
        )}
        {loading && (
          <Absolute style={{ zIndex: '2' }}>
            <Relative>
              <Spinner spinnersize={spinnerSize.large}></Spinner>
            </Relative>
          </Absolute>
        )}
        {!loading && (
          <SensorCoverageDisplay sensorCoverage={item?.sensorUsage} />
        )}
        <GlucoseGraph
          data={item?.glucoseEvents ?? null}
          insights={null}
        ></GlucoseGraph>
      </GlucoseWrapper>
      {patientViews?.Daily.subViews.includes(
        PatientViewDisplays.FitbitWidget
      ) && (
        <ActivityGraph
          allData={item?.fitbitWidget ?? null}
          data={item?.fitbitWidget?.azm ?? null}
          size={ActivityGraphSize.small}
          idx={1}
          day={item?.day ?? ''}
          fitbitActivityMaxes={fitbitActivityMaxes}
          graphIsClickable={true}
        />
      )}
      {siteSettings?.capabilities.includes(Capabilities.adaptt2d) ? (
        <PenInjectionsWrapper data-testid="penInjections">
          {showPenInjectionTooltip !== null && (
            <PenInjectionTooltip data={showPenInjectionTooltip.data} />
          )}
          {item?.penInjections?.length === 0 && !loading && (
            <NoDataComponent
              style={{
                transform: 'translateX(-40%)',
                zIndex: '2'
              }}
            />
          )}
          {loading && (
            <Absolute style={{ zIndex: '2' }}>
              <Relative>
                <Spinner spinnersize={spinnerSize.large}></Spinner>
              </Relative>
            </Absolute>
          )}
          <PenInjectionsGraph
            data={item?.penInjections ?? null}
            maxPenInjection={maxPenInjection}
            setShowPenInjectionTooltip={setShowPenInjectionTooltip}
            insights={null}
          ></PenInjectionsGraph>
        </PenInjectionsWrapper>
      ) : (
        <>
          <BolusWrapper
            onMouseEnter={() => setBolusHover(true)}
            onMouseLeave={() => setBolusHover(false)}
            style={bolusHover ? { zIndex: 3 } : { zIndex: 2 }}
            data-testid="bolusGraphWrapper"
          >
            {showBolusTooltip !== null && (
              <BolusTooltip
                bolusValues={showBolusTooltip.bolusValues}
                maxBolus={maxBolus ?? 0}
              />
            )}
            {!item?.bolusEvents && !loading && (
              <NoDataComponent
                style={{
                  transform: 'translateY(-30px) translateX(-40%)',
                  zIndex: '2'
                }}
              />
            )}
            {loading && (
              <Absolute style={{ zIndex: '2' }}>
                <Relative>
                  <Spinner spinnersize={spinnerSize.large}></Spinner>
                </Relative>
              </Absolute>
            )}
            <BolusGraph
              data={item?.bolusEvents ?? null}
              maxBolus={maxBolus}
              autoBolusEvents={item?.autoBolusEvents ?? null}
              setShowBolusTooltip={setShowBolusTooltip}
            ></BolusGraph>
          </BolusWrapper>
          <BasalWrapper data-testid="basalGraphWrapper">
            {showBasalTooltip !== null && (
              <BasalTooltip
                start={showBasalTooltip.start}
                end={showBasalTooltip.end}
                value={showBasalTooltip.value}
                maxBasal={maxBasal ?? 0}
              />
            )}
            {loading && (
              <Absolute style={{ zIndex: '2' }}>
                <Relative>
                  <Spinner spinnersize={spinnerSize.large}></Spinner>
                </Relative>
              </Absolute>
            )}
            {!item?.basalEvents && !loading && (
              <NoDataComponent
                style={{
                  transform: 'translateY(-30px) translateX(-40%)',
                  zIndex: '2'
                }}
              />
            )}
            <BasalGraph
              data={item?.basalEvents ?? null}
              setShowBasalTooltip={setShowBasalTooltip}
              maxBasal={maxBasal}
              activityData={item?.activitySlices ?? null}
            ></BasalGraph>
          </BasalWrapper>
        </>
      )}
    </GraphsContainer>
  )
}

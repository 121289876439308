import { faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SortDirection } from '@tanstack/react-table'
import styled from 'styled-components'

const SortIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const SortIcon = styled(FontAwesomeIcon)<{ $active: boolean }>`
  font-size: 1rem;
  font-weight: 500;
  color: ${(props) =>
    props.$active ? 'var(--brand-primary-color)' : 'var(--text-lightest)'};
  margin-right: 0.25rem;
`
export const SortIconUp = styled(SortIcon)`
  transform: translateY(50%);
`
export const SortIconDown = styled(SortIcon)`
  transform: translateY(-50%);
`

export const SortIndicator = ({
  sorting
}: {
  sorting: false | SortDirection
}) => {
  if (sorting === false) {
    return null
  }

  return (
    <SortIconWrapper data-testid="sortbutton">
      <SortIconUp icon={faSortUp} $active={sorting === 'asc'} />
      <SortIconDown icon={faSortDown} $active={sorting === 'desc'} />
    </SortIconWrapper>
  )
}

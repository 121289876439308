import { flexRender, Table } from '@tanstack/react-table'
import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { FetchStatus } from '../../hooks/useFetch'
import { DataTableEmpty } from './DataTableEmpty'
import { DataTableError } from './DataTableError'
import { DataTableLoading } from './DataTableLoading'
import { DataTableRow } from './DataTableRow'
import { SortIndicator } from './DataTableSorting'
import { Pagination } from './Pagination'
import { RowPlaceholder } from './RowPlaceholder'

const StickyTable = styled.table`
  border-collapse: seperate;
  border-spacing: 0;
  position: relative;
  width: 100%;
  table-layout: fixed;
  background: var(--element-bg-transparent-80);

  & thead {
    background: #fff;
  }

  & tbody {
    tr {
      &:hover {
        background: var(--element-bg-dark);
      }
    }
  }

  tr {
    th:first-child,
    td:first-child {
      width: 6.5625rem;
    }
    th:nth-child(2),
    td:nth-child(2) {
      width: 4.9375rem;
    }
    th:nth-child(3),
    td:nth-child(3) {
      width: 8rem;
    }
  }

  & th:last-child {
    border-right: 0 !important;
  }

  & tr:first-child {
    td {
      border-top: 0.0625rem solid var(--text-lightest);
    }
    & td:last-child {
      border-top-right-radius: 0.5rem;
    }
    & td:first-child {
      border-top-left-radius: 0.5rem;
    }
  }
  & tr:last-child {
    & td:last-child {
      border-bottom-right-radius: 0.5rem;
    }
    & td:first-child {
      border-bottom-left-radius: 0.5rem;
    }
  }
`

const FooterStack = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Th = styled.th<{ $options?: boolean }>`
  border-left: 0.0625rem solid var(--text-lightest);
  padding: 0.375rem 0.75rem 0.375rem 0.5rem;
  box-sizing: border-box;
  text-align: left;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-lighter);
  line-height: 1rem;
  height: 3rem;
  ${(props) =>
    props.$options === true
      ? css`
          border-left: 0 !important;
        `
      : css``}
  &:first-child {
    border-left: 0 !important;
  }
`

const ThWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  align-items: center;
  & > span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

export function DataTable<T>({
  table,
  context = null,
  fetchStatus,
  fetchError
}: Readonly<{
  table: Table<T>
  context: ReactNode
  fetchStatus: FetchStatus
  fetchError?: Error | null
}>) {
  return (
    <>
      <StickyTable>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  key={header.id}
                  $options={header.column.id === 'options'}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <ThWrapper>
                    <span>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </span>
                    <SortIndicator sorting={header.column.getIsSorted()} />
                  </ThWrapper>
                </Th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {fetchStatus === FetchStatus.Success && table.getRowCount() > 0 && (
            <>
              {table.getRowModel().rows.map((row) => (
                <DataTableRow row={row} key={row.id} />
              ))}
              <RowPlaceholder
                colSpan={table.getFlatHeaders().length}
                currentPageRowCount={table.getRowModel().rows.length}
                rowsPerPage={table.getState().pagination.pageSize}
              ></RowPlaceholder>
            </>
          )}

          {fetchStatus === FetchStatus.Error && fetchError && (
            <RowPlaceholder
              colSpan={table.getFlatHeaders().length}
              currentPageRowCount={table.getRowModel().rows.length}
              rowsPerPage={table.getState().pagination.pageSize}
            >
              <DataTableError error={fetchError} />
            </RowPlaceholder>
          )}
          {fetchStatus === FetchStatus.Loading && (
            <RowPlaceholder
              colSpan={table.getFlatHeaders().length}
              currentPageRowCount={table.getRowModel().rows.length}
              rowsPerPage={table.getState().pagination.pageSize}
            >
              <DataTableLoading />
            </RowPlaceholder>
          )}
          {fetchStatus === FetchStatus.Success && table.getRowCount() === 0 && (
            <RowPlaceholder
              colSpan={table.getFlatHeaders().length}
              currentPageRowCount={table.getRowModel().rows.length}
              rowsPerPage={table.getState().pagination.pageSize}
            >
              <DataTableEmpty />
            </RowPlaceholder>
          )}
        </tbody>
      </StickyTable>
      <FooterStack>
        <div>{context}</div>
        <Pagination table={table} />
      </FooterStack>
    </>
  )
}

import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 4rem;
`

const ErrorBox = styled.div`
  background: var(--element-bg);
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  padding: 1.5rem;
`

const Icon = styled(FontAwesomeIcon)`
  color: var(--yellow);
  line-height: 1.25rem;
  font-size: 1.125rem;
`

export function DataTableError({ error }: { error: Error }) {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <ErrorBox>
        <Icon icon={faTriangleExclamation} />
        <div>
          <div>{t('Error getting data for table')}</div>
          <div>{error.message}</div>
        </div>
      </ErrorBox>
    </Wrapper>
  )
}

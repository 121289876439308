import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faInbox } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react'
import styled from 'styled-components'

const NoDataIconAndText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0.4;
  gap: 1rem;
`
const NoDataIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  color: var(--text-lighter);
`
const NoDataText = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
  color: var(--text-lighter);
  font-family: inter;
`
interface ComponentProps {
  icon?: IconProp
  children: ReactNode
}
export const NoDataEmptyState = ({ icon, children }: ComponentProps) => {
  return (
    <NoDataIconAndText>
      <NoDataIcon icon={icon || faInbox} />
      <NoDataText data-testid="no-data-empty-state">{children}</NoDataText>
    </NoDataIconAndText>
  )
}

import {
  faArrowRotateLeft,
  faTriangleExclamation
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, ReactNode, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DoseRecommendationState } from '../../../../../core/api/getDoseRecommendations'

export enum DoseEditorState {
  auto,
  edited,
  revert
}

interface DoseEditorBadgeConfig {
  badgeColor: string
  backgroundColor: string
  textColor: string
  text: string
  icon: ReactNode | null
}
const getDoseEditorBadgeConfig = (
  state: DoseEditorState,
  doseState: DoseRecommendationState
) => {
  switch (state) {
    case DoseEditorState.auto:
      if (doseState === DoseRecommendationState.LowSensorCoverage) {
        return {
          badgeColor: 'var(--yellow)',
          backgroundColor: 'var(--yellow)',
          textColor: 'var(--white-color)',
          text: 'auto',
          icon: (
            <FontAwesomeIcon
              data-testid="lowSensorCoverageWarning"
              style={{ color: 'var(--white-color)' }}
              icon={faTriangleExclamation}
            ></FontAwesomeIcon>
          )
        }
      } else {
        return {
          badgeColor: 'var(--pink)',
          backgroundColor: 'none',
          textColor: 'var(--pink)',
          text: 'auto',
          icon: null
        }
      }

    case DoseEditorState.edited:
      return {
        badgeColor: 'var(--blue)',
        backgroundColor: 'none',
        textColor: 'var(--blue)',
        text: 'edited',
        icon: null
      }
    case DoseEditorState.revert:
      return {
        badgeColor: 'var(--blue)',
        backgroundColor: 'var(--blue)',
        textColor: 'var(--white-color)',
        text: 'revert',
        icon: <RevertIcon icon={faArrowRotateLeft} />
      }
  }
}

const Wrapper = styled.div<{
  $config: DoseEditorBadgeConfig
  $state: DoseEditorState
}>`
  display: inline-flex;
  padding: 0.125rem 0.5rem;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.375rem;
  border: ${(props) => `0.125rem solid ${props.$config.badgeColor}}`};
  background-color: ${(props) => props.$config.backgroundColor};
  cursor: ${(props) =>
    props.$state === DoseEditorState.revert ? 'pointer' : 'default'};
`
const RevertIcon = styled(FontAwesomeIcon)`
  color: var(--white-color);
  font-size: 0.875rem;
  line-height: 1rem;
`
const Text = styled.span<{ $config: DoseEditorBadgeConfig }>`
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: ${(props) => props.$config.textColor};
`
export const DoseEditorBadge = ({
  state,
  setState,
  setNextDose,
  originalNextDose,
  doseState
}: {
  state: DoseEditorState
  setState: Dispatch<SetStateAction<DoseEditorState>>
  setNextDose: Dispatch<SetStateAction<number>>
  originalNextDose: number
  doseState: DoseRecommendationState
}) => {
  const { t } = useTranslation()
  const config = getDoseEditorBadgeConfig(state, doseState)
  return (
    <Wrapper
      data-testid="doseEditorBadgeWrapper"
      $state={state}
      $config={config}
      onMouseEnter={() => {
        if (state === DoseEditorState.edited) {
          setState(DoseEditorState.revert)
        }
      }}
      onMouseLeave={() => {
        if (state === DoseEditorState.revert) {
          setState(DoseEditorState.edited)
        } else {
          setState(DoseEditorState.auto)
        }
      }}
      onClick={() => {
        if (state === DoseEditorState.revert) {
          setNextDose(originalNextDose)
        }
      }}
    >
      {config.icon}
      <Text data-testid="doseEditorBadgeText" $config={config}>
        {t(config.text)}
      </Text>
    </Wrapper>
  )
}

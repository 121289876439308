import { findDiff } from './helperfunctions/findDiff'
import { isNumber } from './helperfunctions/isNumber'
import { ValdiationFunctionProps } from './interfaces'

export const foedselsnummerValidation = ({
  setPatientError,
  setValue,
  getValues,
  setCurrentMRN,
  currentMRN
}: ValdiationFunctionProps) => {
  setPatientError(undefined)
  const mrn = getValues('MRN')
  const diff = findDiff(currentMRN, mrn)
  if (mrn.match(/-.*-/)) {
    setValue('MRN', currentMRN)
  }
  if (mrn.length === 0) {
    setValue('MRN', mrn)
    setCurrentMRN(mrn)
  }
  if (!isNumber(diff.difference) && diff.difference !== '') {
    setValue('MRN', currentMRN)
    return
  }
  if (mrn.length > 11) {
    setValue('MRN', currentMRN)
  }
  setCurrentMRN(getValues('MRN'))
}
export const foedselsnummerRegex = /^\d{11}$/

import { faInbox } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'i18next'
import styled from 'styled-components'

const Wrapper = styled.div`
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  background-color: var(--white-color);
  display: flex;
  width: 100%;
  padding: 0.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  box-sizing: border-box;
`
const MainContent = styled.div`
  display: flex;
  padding: 2rem 0rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
`
const NoDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  opacity: 0.4;
`
const NoDataIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  color: var(--text-lighter);
`
const NoDataText = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
  color: var(--text-lighter);
  font-family: inter;
`
export const NoSleepData = () => {
  return (
    <Wrapper data-testid="NoSleepData">
      <MainContent>
        <NoDataWrapper>
          <NoDataIcon icon={faInbox} />
          <NoDataText>{t('NO DATA')}</NoDataText>
        </NoDataWrapper>
      </MainContent>
    </Wrapper>
  )
}

export enum infusionSiteChangeEventType {
  cannula = 'cannula',
  tubing = 'tubing',
  pod = 'pod'
}
export interface Patient {
  patientId: string
  name: string
  mrn: string
  userId: string | null
  infusionSiteChangeEvent: infusionSiteChangeEventType
  setPatientName: boolean
}

import { faMobileNotch } from '@fortawesome/pro-regular-svg-icons'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { ApplicationUploadActivityType, UploadContext } from '../../constants'
import { Icon, Text } from '../UploadContext'
import { UploadContextGroup } from '../UploadContextGroup/UploadContextGroup'

export const AppUploadContext = ({
  context
}: {
  context: Extract<
    UploadContext,
    {
      type: ApplicationUploadActivityType.App
    }
  >
}) => {
  const { t } = useTranslation()
  const group: ReactNode[] = [
    <Icon icon={faMobileNotch}></Icon>,
    <Text>{t('Patient App')}</Text>
  ]
  if (context.applicationUploadVersion !== null) {
    group.push(<Text>{context.applicationUploadVersion}</Text>)
  }
  if (context.applicationPlatform !== null) {
    group.push(<Text>{context.applicationPlatform}</Text>)
  }
  return <UploadContextGroup type={context.type} group={group} />
}

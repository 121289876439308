import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { ApplicationUploadActivityType, UploadContext } from '../constants'
import { AppUploadContext } from './AppUploadContext/AppUploadContext'
import { ClinicUploadContext } from './ClinicUploaderContext/ClinicUploaderContext'
import { CloudUploadContext } from './CloudUploadContext/CloudUploadContext'
import { FileUploadContext } from './FileUploadContext/FileUploadContext'
import { HomeUploadContext } from './HomeUploaderContext/HomeUploaderContext'
import { LegacyUploadContext } from './LegacyUploadContext/LegacyUploadContext'

export const Icon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  color: var(--text-primary);
  font-weight: 400;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
`
export const Text = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-primary);
  white-space: nowrap;
`
export const LastText = styled(Text)`
  padding-right: 0.75rem;
`

export const Seperator = styled(FontAwesomeIcon)`
  font-size: 0.5rem;
  font-weight: 900;
  color: var(--text-lighter);
`

export function UploadPlatformContext({ context }: { context: UploadContext }) {
  const type = context.type
  switch (type) {
    case ApplicationUploadActivityType.App:
      return <AppUploadContext context={context} />
    case ApplicationUploadActivityType.ClinicUploader:
      return <ClinicUploadContext context={context} />
    case ApplicationUploadActivityType.Cloud:
      return <CloudUploadContext context={context} />
    case ApplicationUploadActivityType.File:
      return <FileUploadContext context={context} />
    case ApplicationUploadActivityType.HomeUploader:
      return <HomeUploadContext context={context} />
    case ApplicationUploadActivityType.Legacy:
      return <LegacyUploadContext context={context} />
  }
}

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useSessionContext } from '../../core/contexts/session-context'
import { UploadUser } from './constants'

export const YouBadge = styled.span`
  background-color: var(--brand-primary-12-color);
  color: var(--brand-darker-color);
  padding: 0.125rem 0.25rem;
  border-radius: 0.25rem;
  font-family: inter;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  display: inline-block;
  margin-left: 0.25rem;
`

export function UploadedBy({ uploadedByUser }: { uploadedByUser: UploadUser }) {
  const { t } = useTranslation()
  const { user } = useSessionContext()
  const isSameUser = user?.userId === uploadedByUser.id

  return (
    <>
      {uploadedByUser.name}
      {isSameUser ? <YouBadge>{t('you')}</YouBadge> : null}
    </>
  )
}

import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ModalComponent } from '../../../../components/Modal/Modal'
import { putPatient } from '../../../../core/api/put-patient'
import { usePatientContext } from '../../../../core/contexts/patient-context'
import { usePeriodContext } from '../../../../core/contexts/period-context'
import { useSiteSettingsContext } from '../../../../core/contexts/site-settings-context'
import { Patient } from '../../../../core/entities/patient.entity'
import { SnackBar, SnackBarType } from '../../../../libraries/Toast/SnackBar'
import { FormFieldComponent } from '../../FormFieldComponent/FormFieldComponent'
import { InfoBox } from '../../InfoBox/InfoBox'
import { InfoFieldComponent } from '../../infoFieldComponent/infoFieldComponent'
const Wrapper = styled.div`
  background-color: var(--white-color);
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;
  width: 31.25rem;
  gap: 2rem;
  box-sizing: border-box;
  box-shadow: 0rem 0.25rem 1rem rgba(51, 51, 51, 0.16);
`
const HeaderTitle = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  color: var(--text-primary);
  font-weight: 600;
  line-height: 2.5rem;
`

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
const FormButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
const SubmitButton = styled.button`
  width: 100%;
  border: none;
  padding: 0;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:var(--brand-primary-color);
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  font-family: inter;
  color: var(--white-color);
  line-height: 1.25rem;
  cursor: pointer;
  &:disabled {
    opacity: 0.16;
    cursor: default;
    border: 0.0625rem solid rgb(0,0,0,0);
`

const CancelButton = styled.div`
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  background-color: var(--element-bg);
  display: flex;
  height: 3rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  font-size: 0.875rem;
  font-weight: 600;
  font-family: inter;
  color: var(--text-primary);
  line-height: 1.25rem;
  cursor: pointer;
  &:hover {
    background-color: var(--element-bg-dark);
  }
`
export const NameChangeModal = ({
  patient,
  closeModal
}: {
  patient: Patient
  closeModal: () => void
}) => {
  const { setPatient } = usePatientContext()
  const { setPeriod } = usePeriodContext()
  const { siteSettings } = useSiteSettingsContext()
  const {
    register,
    handleSubmit,
    formState: { isValid }
  } = useForm<{ name: string }>({
    mode: 'onChange',
    defaultValues: {
      name: ''
    }
  })
  const onSubmit: SubmitHandler<{ name: string }> = async (data) => {
    try {
      await putPatient(patient.patientId, data.name)
      setPeriod(null)
      setPatient(null)
      closeModal()
    } catch (err) {
      SnackBar({
        type: SnackBarType.Error,
        message: t('Error changing patient name')
      })
    }
  }
  const { t } = useTranslation()
  return (
    <ModalComponent closeFun={closeModal} closetestid="closeNameChangeModal">
      <Wrapper>
        <HeaderTitle>{t('Change patient name')}</HeaderTitle>
        <InfoBox
          testid="nameChangeModalInfoBox"
          paragraph={t('nameChangeModalInfo', {
            regionalLoginSolution: 'MitID'
          })}
        />
        <InfoFieldComponent
          label={t(`${siteSettings?.mrnType}.mrn-title`)}
          value={
            patient
              ? patient.mrn.substring(0, 6) + '-' + patient.mrn.substring(6, 11)
              : ''
          }
          disabled={true}
          edit={false}
        />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormFieldComponent
            label={t('Full name')}
            value={patient?.name}
            register={{
              ...register('name', {
                required: true
              })
            }}
          />
          <FormButtons>
            <SubmitButton
              disabled={!isValid}
              type={'submit'}
              data-testid="changePatientNameButton"
            >
              {t('Change patient name')}
            </SubmitButton>
            <CancelButton onClick={closeModal}>{t('Cancel')}</CancelButton>
          </FormButtons>
        </Form>
      </Wrapper>
    </ModalComponent>
  )
}

import { AxiosRequest } from './axios-request'
import config from '../config'
import { User } from '../entities/user.entity'

export async function getCurrentUser(options: object = {}): Promise<User> {
  const response = await AxiosRequest.create().get(
    config.API_URL + '/users/me',
    options
  )
  return response.data as User
}

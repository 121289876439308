import {
  faCaretDown,
  faTriangleExclamation
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, SetStateAction, useState } from 'react'
import {
  SubmitHandler,
  UseFormRegister,
  UseFormSetValue,
  useForm
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  PopulationOverview,
  SubPopulation
} from '../../containers/ClinicPatients/tables/interfaces'
import { assignPatientToPopulation } from '../../core/api/assign-patient-to-population'
import { getPatient } from '../../core/api/get-patient-api'
import { useSiteSettingsContext } from '../../core/contexts/site-settings-context'
import { Patient } from '../../core/entities/patient.entity'
import { getValidation } from '../../helpers/MRNValidation/getValidation'
import { MRNTypes } from '../../helpers/MRNValidation/interfaces'
import { SnackBar, SnackBarType } from '../../libraries/Toast/SnackBar'
import {
  InputField,
  InputFieldLabel,
  InputFieldWrapper,
  InputWrapper
} from '../FormStyles/FormStyles'
import { ModalComponent } from '../Modal/Modal'

interface AddPatientToPopulationProps {
  closeModal: () => void
  populations: PopulationOverview[]
  populationid: string
  refresh: () => void
}

const Wrapper = styled.div`
  background-color: var(--white-color);
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;
  width: 31.25rem;
  gap: 2rem;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  justify-content: flex-start;
  align-items: flex-start;
`
const Title = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--text-primary);
  line-height: 2.25rem;
`
const Subtitle = styled.span`
  color: var(--text-lighter);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
`
const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
const SubmitButton = styled.button`
  border: 0.0625rem solid var(--brand-primary-color);
  width: 100%;
  padding: 1rem;
  background-color:var(--brand-primary-color);
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  font-family: inter;
  color: var(--white-color);
  cursor: pointer;
  &:disabled {
    opacity: 0.16;
    cursor: default;
    border: 0.0625rem solid rgb(0,0,0,0);
`

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1.5rem;
  gap: 0.75rem;
  border-radius: 0.5rem;
  background-color: var(--element-bg);
  width: 100%;
  box-sizing: border-box;
`
const InfoBoxIcon = styled(FontAwesomeIcon)`
  font-size: 1.125rem;
  font-weight: 900;
  color: var(--yellow);
`
const InfoBoxText = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--yellow-darker);
`

const SelectPopulation = styled.select<{ $selected: boolean }>`
  width: 100%;
  padding: 1rem;
  border: 0.0625rem solid var(--text-lightest);
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  font-family: inter;
  color: ${(props) =>
    props.$selected ? 'var(--text-primary)' : 'var(--text-lighter)'};
  box-sizing: border-box;
  -webkit-appearance: none;
`
const SelectArrow = styled(FontAwesomeIcon)`
  color: var(--text-medium);
  font-size: 0.875rem;
  line-height: 1rem;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
`
const PatientInformationBoxWrapper = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  gap: 0.25rem;
  align-self: stretch;
  border-radius: 0.5rem;
  background-color: var(--element-bg);
  flex-direction: column;
`

const PatientInformationBoxText1 = styled.span`
  color: var(--brand-primary-color);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
`
const PatientInformationBoxText2 = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2.25rem;
`
const PatientInformationBox = ({ patient }: { patient: Patient }) => {
  const { t } = useTranslation()
  return (
    <PatientInformationBoxWrapper data-testid="patientFoundBox">
      <PatientInformationBoxText1>
        {t('Patient found')}
      </PatientInformationBoxText1>
      <PatientInformationBoxText2>
        {patient.mrn.substring(0, 6) + '-' + patient.mrn.substring(6, 11)}
      </PatientInformationBoxText2>
      <PatientInformationBoxText2>{patient.name}</PatientInformationBoxText2>
    </PatientInformationBoxWrapper>
  )
}
interface FormValues {
  MRN: string
  SubPopulation: any
}
interface InputComponentProps {
  label: 'MRN' | 'SubPopulation'
  register: UseFormRegister<FormValues>
  resetError: Dispatch<SetStateAction<Error | undefined>>
  errorState?: Error | undefined
  setValue: UseFormSetValue<any>
  getValues: (field?: string | string[] | undefined) => any
  population?: PopulationOverview
}
const InfoBoxComponent = () => {
  const { t } = useTranslation()
  return (
    <InfoBox data-testid="addPatientToPopulationInfoBox">
      <InfoBoxIcon icon={faTriangleExclamation}></InfoBoxIcon>
      <InfoBoxText>{t('Patient does not exist')}</InfoBoxText>
    </InfoBox>
  )
}

const InputComponent = ({
  label,
  register,
  resetError,
  errorState,
  setValue,
  getValues,
  population
}: InputComponentProps) => {
  const { t } = useTranslation()
  const [currentMRN, setCurrentMRN] = useState('')
  const { siteSettings } = useSiteSettingsContext()
  const validation = getValidation({
    mrntype: siteSettings?.mrnType ?? MRNTypes.dkcpr,
    setPatientError: () => resetError(undefined),
    setValue,
    getValues,
    setCurrentMRN,
    currentMRN
  })
  const getLabel = (label: 'MRN' | 'SubPopulation') => {
    if (label === 'MRN') return t(`${siteSettings?.mrnType}.mrn-title`)
    else return t('Sub-population')
  }
  const getPlaceHolder = (label: 'MRN' | 'SubPopulation') => {
    if (label === 'MRN') return t(`${siteSettings?.mrnType}.mrn-enter`)
    else return t('Select sub-population')
  }

  return (
    <InputWrapper>
      <InputFieldLabel
        style={
          errorState !== undefined ? { color: 'var(--yellow-darker)' } : {}
        }
      >
        {getLabel(label)}
      </InputFieldLabel>
      <InputFieldWrapper>
        {label === 'MRN' ? (
          <InputField
            data-testid={`searchForPatientInput${label}`}
            style={
              errorState !== undefined ? { borderColor: 'var(--yellow)' } : {}
            }
            {...register(label, {
              onChange: () => validation.changeFunction(),
              required: true,
              pattern: {
                value: validation.pattern,
                message: t('wrong format')
              }
            })}
            placeholder={getPlaceHolder(label)}
            autoComplete="off"
          ></InputField>
        ) : (
          <>
            <SelectPopulation
              {...register(label, {
                required: true
              })}
              $selected={getValues(label) !== ''}
              data-testid="selectSubPopulation"
            >
              <option
                value=""
                disabled
                selected
                style={{ color: 'var(--text-lighter)' }}
              >
                {getPlaceHolder(label)}
              </option>
              {population !== undefined &&
                Object.values(population?.distribution).map(
                  (group: { name: string; size: number } | null, index) => (
                    <option
                      data-testid="optionSubPopulation"
                      key={group?.name}
                      value={Object.values(SubPopulation)[index]}
                    >
                      {group?.name}
                    </option>
                  )
                )}
            </SelectPopulation>
            <SelectArrow icon={faCaretDown}></SelectArrow>
          </>
        )}
      </InputFieldWrapper>
    </InputWrapper>
  )
}
export const AddPatientToPopulationModal = ({
  closeModal,
  populations,
  populationid,
  refresh
}: AddPatientToPopulationProps) => {
  const population = populations.filter(
    (population) => population.population.id === populationid
  )[0]
  const [errorState, setErrorState] = useState<Error | undefined>()
  const [patient, setPatient] = useState<Patient | null>(null)
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      MRN: '',
      SubPopulation: null
    }
  })

  const renderForm = () => {
    if (!patient) {
      return (
        <>
          <InputComponent
            label={'MRN'}
            register={register}
            resetError={setErrorState}
            errorState={errorState}
            setValue={setValue}
            getValues={getValues}
          />
          <SubmitButton
            disabled={!isValid}
            type={'submit'}
            data-testid="searchForPatientButton"
          >
            {t('Search for patient')}
          </SubmitButton>
        </>
      )
    } else {
      return (
        <>
          <PatientInformationBox patient={patient} />
          <InputComponent
            label={'SubPopulation'}
            register={register}
            resetError={setErrorState}
            errorState={errorState}
            setValue={setValue}
            getValues={getValues}
            population={population}
          />
          <SubmitButton
            disabled={!isValid}
            type={'submit'}
            data-testid="addPatientToPopulationButton"
          >
            {t('Add patient to population')}
          </SubmitButton>
        </>
      )
    }
  }
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (!patient)
      try {
        const foundPatient = (await getPatient(data.MRN.replace(/-/g, ''))).data
        if (foundPatient.length === 0) {
          throw new Error('patient not found')
        }
        setPatient(foundPatient[0])
      } catch (err) {
        setErrorState(new Error())
      }
    else {
      try {
        await assignPatientToPopulation(
          populationid,
          patient.patientId,
          data.SubPopulation
        )
        closeModal()
        setPatient(null)
        refresh()
      } catch (err) {
        SnackBar({
          type: SnackBarType.Error,
          message: t(
            'Something went wrong, maybe the patient is already in a population?'
          )
        })
      }
    }
  }
  return (
    <ModalComponent
      closeFun={closeModal}
      closetestid="closeAddPatientToPopulationModal"
    >
      <Wrapper
        onClick={function (event) {
          event.stopPropagation()
        }}
        data-testid="addPatientToPopulationModal"
      >
        <TitleWrapper>
          <Subtitle>{t('Add patient to population')}</Subtitle>
          <Title>{population.population.name}</Title>
        </TitleWrapper>
        {errorState !== undefined && <InfoBoxComponent />}
        <Form onSubmit={handleSubmit(onSubmit)}>{renderForm()}</Form>
      </Wrapper>
    </ModalComponent>
  )
}

import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ReferenceArea,
  ResponsiveContainer,
  ScatterChart,
  XAxis,
  YAxis
} from 'recharts'
import styled from 'styled-components'
import dayjs from '../../../core/dayjs/dayjs'
import { DeviceEventIcon } from '../../DeviceEvent/DeviceEventIcon/DeviceEventIcon'
import { getDeviceEventConfig } from '../../DeviceEvent/getDeviceEventConfig'
import {
  AbstractDeviceEvent,
  DeviceEventConfig
} from '../../DeviceEvent/Interfaces'
import { referenceLines } from '../BasalGraph/BasalGraph'
import { EventTooltip } from './EventTooltip/EventTooltip'

const Wrapper = styled.div`
  position: relative;
  height: 3.3125rem;
  width: calc(100% - 15px);
  margin-left: 15px;
`

const EventLabel = styled.span`
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 0.75rem;
  padding: 0.25rem; 0.125rem;
  position: absolute;
  left: 0.25rem;
  top: calc(0.25rem + 25px);
  z-index: 1;
  border-radius: 0.125rem;
  background-color: var(--element-bg-80);
  pointer-events: none;
`

export const CustomizedAxisTick = (props: any) => {
  return (
    <g
      transform={`translate(${
        props.payload.value === 24
          ? props.x + 8
          : props.payload.value === 1
          ? props.x - 45
          : props.x
      },${props.y})`}
    >
      <text
        x={0}
        y={0}
        textAnchor={props.payload.value === 24 ? 'end' : 'start'}
        fill="var(--text-lighter)"
        fontSize="0.75rem"
        fontWeight="600"
        fontFamily="inter"
      >
        {props.payload.value === 1 ? 0 : props.payload.value}:00
      </text>
    </g>
  )
}

export const EventEntry = (
  data: AbstractDeviceEvent[],
  event: AbstractDeviceEvent,
  currentTooltip: {
    event: AbstractDeviceEvent
    config: DeviceEventConfig | null
    timestampAsNumber: number
  } | null,
  setCurrentTooltip: Dispatch<
    SetStateAction<{
      event: AbstractDeviceEvent
      config: DeviceEventConfig | null
      timestampAsNumber: number
    } | null>
  >,
  redraw: () => void
) => {
  const { t } = useTranslation()
  const time = dayjs(event.localTime)
  const timestampAsNumber =
    time.hour() + time.minute() / 60 + time.second() / 3600

  const config = getDeviceEventConfig(event, t)
  const x1 = timestampAsNumber > 23.5 ? 23.5 : timestampAsNumber
  const x2 = timestampAsNumber + 1 > 24 ? 24 : timestampAsNumber + 1

  const getEventBackgroundFill = () => {
    if (currentTooltip?.event.id === event.id) {
      if (
        config?.colors !== undefined &&
        config.colors !== 'var(--text-primary)'
      ) {
        return config.colors
      } else {
        return 'var(--blue)'
      }
    } else return 'var(--white-color)'
  }

  return (
    <ReferenceArea
      key={event.id}
      fillOpacity={1}
      x1={x1}
      x2={x2}
      y1={0}
      y2={1.7}
      onMouseEnter={async () => {
        redraw()
        setCurrentTooltip({ event, config, timestampAsNumber: x1 })
      }}
      onMouseLeave={() => {
        setCurrentTooltip(null)
      }}
      shape={
        <svg style={{ cursor: 'pointer' }}>
          <rect
            width="1.25rem"
            height="1.25rem"
            rx="0.25rem"
            fill={getEventBackgroundFill()}
          ></rect>
          <DeviceEventIcon
            config={config}
            isHovered={currentTooltip?.event.id === event.id}
          />
        </svg>
      }
    />
  )
}
const xDomainMax = 24

export const EventGraph = ({
  loading,
  events,
  setShowAlarmList
}: {
  loading: boolean
  events: AbstractDeviceEvent[] | null
  setShowAlarmList: Dispatch<SetStateAction<boolean>>
}) => {
  const [currentTooltip, setCurrentTooltip] = useState<{
    event: AbstractDeviceEvent
    config: DeviceEventConfig | null
    timestampAsNumber: number
  } | null>(null)
  const [data, setData] = useState<AbstractDeviceEvent[] | null>(events)
  const [hovered, setHovered] = useState<boolean>(false)
  const { t } = useTranslation()
  function redraw(arr: AbstractDeviceEvent[], id: string) {
    const copy = [...arr]
    const dot = copy.find((element) => element.id === id)
    copy.splice(
      copy.findIndex((element) => element.id === id),
      1
    )
    if (dot) copy.push(dot)
    setData(copy)
  }

  const CustomReferenceArea = (hovered: boolean) => {
    return (
      <ReferenceArea
        x1={0}
        x2={xDomainMax}
        y1={0}
        y2={2}
        fill={hovered ? 'var(--element-bg-dark)' : 'var(--element-bg)'}
        stroke={'var(--text-lightest)'}
        radius={4}
        fillOpacity={1}
      />
    )
  }
  return (
    <Wrapper>
      {currentTooltip && <EventTooltip currentTooltip={currentTooltip} />}
      <EventLabel>{t('Events')}</EventLabel>
      <ResponsiveContainer>
        <ScatterChart
          margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={() => setShowAlarmList(true)}
          style={{ cursor: 'pointer' }}
        >
          {CustomReferenceArea(hovered)}
          {referenceLines('stackedDaily')}
          <XAxis
            type="number"
            name="Time"
            ticks={[0, 1, 3, 6, 9, 12, 15, 18, 21, 24]}
            tick={<CustomizedAxisTick />}
            domain={[0, xDomainMax]}
            dataKey="x"
            tickLine={false}
            orientation={'top'}
            axisLine={false}
            tickMargin={5}
            height={25}
          ></XAxis>
          <YAxis
            stroke="#979797"
            fontSize="0.875rem"
            dataKey="y"
            name="Glucose"
            interval={0}
            tick={false}
            domain={[0, 2]}
            axisLine={false}
            width={0}
          />

          {loading ? null : data && data.length > 0 ? (
            data.map((deviceEvent) => {
              return EventEntry(
                data,
                deviceEvent,
                currentTooltip,
                setCurrentTooltip,
                () => redraw(data, deviceEvent.id)
              )
            })
          ) : (
            <ReferenceArea
              x1={12}
              fill="var(--text-lighter)"
              fillOpacity={0.5}
              fontFamily="inter"
              fontSize={12}
              fontWeight={700}
              shape={
                <text
                  style={{
                    transform: 'translateY(1.125rem) translateX(-2.25rem)'
                  }}
                >
                  {t('NO DATA')}
                </text>
              }
            ></ReferenceArea>
          )}
        </ScatterChart>
      </ResponsiveContainer>
    </Wrapper>
  )
}

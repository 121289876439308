import {
  IconDefinition,
  faCloud,
  faFileArrowUp,
  faHouseMedical,
  faLaptop,
  faMobileNotch
} from '@fortawesome/pro-regular-svg-icons'
import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { Td } from '../../Table/ScrollTableComponent'
import {
  ApplicationUploadPlatform,
  ApplicationUploadType,
  FileUploadType,
  Upload,
  UploadType
} from '../Uploads'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  min-height: 2.25rem;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  color: var(--text-primary);
  font-weight: 400;
`

const Seperator = styled(FontAwesomeIcon)`
  font-size: 0.5rem;
  font-weight: 900;
  color: var(--text-lighter);
`

const Text = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-primary);
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`
interface UploadContextProps {
  upload: Upload
}

interface UploadTypeObject {
  icon: IconDefinition
  name?: string | null
  subType?: FileUploadType | ApplicationUploadType | null
  context?: string | null
  version?: string | null
  os?: ApplicationUploadPlatform | null
}

const getUploadTypeObject = (upload: Upload): UploadTypeObject => {
  const type = upload.uploadType as UploadType
  switch (type) {
    case UploadType.cloud:
      return {
        icon: faCloud,
        name: upload.providerName
      }
    case UploadType.file:
      return {
        icon: faFileArrowUp,
        subType: upload.fileUploadType
      }
    case UploadType.application:
      return {
        icon: getAppIcon(upload.applicationPlatform),
        subType: upload.applicationUploadType,
        version: upload.applicationUploadVersion,
        context: upload.applicationPlatform
      }
  }
}

const getAppIcon = (type: ApplicationUploadPlatform | null) => {
  switch (type) {
    case ApplicationUploadPlatform.ios:
      return faMobileNotch
    case ApplicationUploadPlatform.mac:
      return faLaptop
    case ApplicationUploadPlatform.windows:
      return faLaptop
    case ApplicationUploadPlatform.android:
      return faMobileNotch
    case ApplicationUploadPlatform.legacy:
      return faHouseMedical
    default:
      return faHouseMedical
  }
}

export const UploadContext = ({ upload }: UploadContextProps) => {
  const object = getUploadTypeObject(upload)
  return (
    <Td style={{ maxWidth: '15rem', overflow: 'auto' }}>
      <Wrapper>
        <Icon icon={object.icon}></Icon>
        {Object.entries(object).map(
          (entry, index) =>
            entry[0] !== 'icon' && (
              <Row key={entry[0]}>
                <Text>{entry[1]}</Text>
                {index !== Object.entries(object).length - 1 && (
                  <Seperator icon={faCircleSmall} />
                )}
              </Row>
            )
        )}
      </Wrapper>
    </Td>
  )
}

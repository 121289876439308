import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import bluePen from '../../../../assets/Pens/NovoPenBlue.png'
import greyPen from '../../../../assets/Pens/NovoPenGrey.png'
import dayjs from '../../../../core/dayjs/dayjs'
import { formatNumber } from '../../../../helpers/helpers'
import { Header, Wrapper } from '../../../BasalTooltip/BasalTooltip'
import { ToolTipEntry } from '../../../BolusTooltip/BolusTooltip'
import {
  MergedNovoPenEventValue,
  NovoPenEventValue,
  NovoPenMedicine,
  NovoPenMedicineString
} from '../../Insights/interfaces'

export interface InsightTooltipProps {
  data: {
    value: NovoPenEventValue | MergedNovoPenEventValue
    time: string
    pos: number
    yMax: number
  }
}
const UnitText = styled.span`
  fony-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--text-lighter);
`
const HeaderText = styled(UnitText)`
  color: var(--text-primary);
`
const Body = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
`

const Divider = styled.div`
  width: 100%;
  height: 0.0625rem;
  background-color: var(--text-lightest);
`

const PenImgWrapper = styled.div`
  width: 100%;
  height: 3.25rem;
  position: relative;
`
const PenImg = styled.img`
  transform: rotate(-90deg);
  position: absolute;
  height: 11.125rem;
  width: 6.5rem;
  top: -3.875rem;
  left: 2.375rem;
`

const Seperator = styled(FontAwesomeIcon)`
  font-size: 0.5rem;
  color: var(--text-lightest);
  line-height: 0.75rem;
`

const getPenImg = (medicine: NovoPenMedicine) => {
  switch (medicine) {
    case NovoPenMedicine.Basal:
      return bluePen
    case NovoPenMedicine.Bolus:
      return greyPen
  }
}

export const PenInjectionTooltip = ({ data }: InsightTooltipProps) => {
  const { t } = useTranslation()
  const totalValue =
    'units' in data.value ? data.value.units : data.value.totalUnits

  const AllInjections = ({
    event
  }: {
    event: NovoPenEventValue | MergedNovoPenEventValue
  }) => {
    if ('totalUnits' in event) {
      return (
        <>
          {event.penEvents.map((e) => (
            <ToolTipEntry
              name={{
                value: dayjs(e.localTime).format('HH:mm'),
                color: 'var(--text-primary)'
              }}
              value={{
                value: formatNumber({
                  value: e.units,
                  decimals: 1
                }),
                color: 'var(--text-primary)'
              }}
              unit={'U'}
            />
          ))}
          <Divider />
          <ToolTipEntry
            name={{
              value: t('Total'),
              color: 'var(--text-primary)'
            }}
            value={{
              value: formatNumber({
                value: totalValue,
                decimals: 1
              }),
              color: 'var(--text-primary)'
            }}
            unit={'U'}
          />
        </>
      )
    } else {
      return (
        <ToolTipEntry
          name={{ value: t('Injected'), color: 'var(--text-primary)' }}
          value={{
            value: formatNumber({
              value: totalValue,
              decimals: 1
            }),
            color: 'var(--text-primary)'
          }}
          unit={'U'}
        />
      )
    }
  }
  return (
    <Wrapper
      width={((data.pos ?? 1) / 24) * 100}
      height={(totalValue / data.yMax) * 100}
      data-testid="InsightTooltip"
      style={{ gap: '0rem' }}
    >
      <Header style={{ gap: '0.25rem' }}>
        <HeaderText>{dayjs(data.time).format('HH:mm')}</HeaderText>
        {!('penName' in data.value) && (
          <>
            <Seperator icon={faCircleSmall} />
            <HeaderText data-testid="mergedNovoPenEventAmount">
              {data.value.penEvents.length} {t('Injections')}
            </HeaderText>
          </>
        )}
      </Header>
      <Body>
        <AllInjections event={data.value} />
        <ToolTipEntry
          name={{ value: t('Medicine'), color: 'var(--text-primary)' }}
          value={{
            value: NovoPenMedicineString(data.value.drug),
            color: 'var(--text-primary)'
          }}
        />
        <Divider />
        <PenImgWrapper>
          <PenImg src={getPenImg(data.value.drug)} />
        </PenImgWrapper>
        <Divider />
        <ToolTipEntry
          name={{ value: t('Pen Name'), color: 'var(--text-lighter)' }}
          value={{
            value:
              'penName' in data.value
                ? data.value.penName
                : data.value.penEvents[0].penName,
            color: 'var(--text-primary)'
          }}
        />
        <ToolTipEntry
          name={{ value: t('Pen ID'), color: 'var(--text-lighter)' }}
          value={{
            value:
              'penId' in data.value
                ? data.value.penId
                : data.value.penEvents[0].penId,
            color: 'var(--text-primary)'
          }}
        />
      </Body>
    </Wrapper>
  )
}

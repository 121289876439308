import { useState } from 'react'
import { ActivityGraphSize } from '../ActivityGraph'

export const CustomActivityBar = (props: any) => {
  const {
    x,
    y,
    y1,
    y2,
    fill,
    height,
    barIndex,
    onClick,
    graphIndex,
    size,
    onHover,
    index,
    data,
    event,
    graphHovered,
    graphIsClickable
  } = props

  const [isHovered, setIsHovered] = useState<boolean>(false)

  const getBackgroundBarColor = () => {
    if (isHovered) {
      if (graphHovered && graphIsClickable) {
        return 'var(--element-bg-darkest)'
      } else return 'var(--element-bg-dark)'
    } else {
      if (graphHovered && graphIsClickable) {
        return 'var(--element-bg-dark)'
      } else return 'var(--element-bg)'
    }
  }
  const actualX1 = (x ?? 0) - 4
  const actualX2 = (x ?? 0) + 4
  const actualWidth = actualX2 - actualX1

  const actualX2ForPrevious = (x / barIndex) * (barIndex - 1) + 4
  const actualX1ForNext = (x / barIndex) * (barIndex + 1) - 4
  const spaceHalfwayToPrevious = (actualX1 - actualX2ForPrevious) / 2
  const spaceHalfwayToNext = (actualX1ForNext - actualX2) / 2

  const handleMouseEnter = () => {
    setIsHovered(true)
    const width = ((index + 0.5 + (0.5 - 0.5 * (index / 24))) / 24) * 100
    const height = graphIndex === 0 ? 85 : 100
    onHover({
      width,
      height,
      data: {
        ...event,
        unit: data.rightLabel,
        type: data.type
      }
    })
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
    onHover(null)
  }
  return (
    <g
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-testid="customActivityBar"
    >
      <rect
        x={actualX1 - spaceHalfwayToPrevious}
        x1={actualX1 - spaceHalfwayToPrevious}
        x2={actualX2 + spaceHalfwayToNext}
        fillOpacity={0}
        y={graphIndex === 0 ? 26 : size === ActivityGraphSize.small ? 0.5 : 1}
        width={spaceHalfwayToPrevious + actualWidth + spaceHalfwayToNext}
        height={
          graphIndex === 0
            ? '85.5%'
            : size === ActivityGraphSize.small
            ? '96%'
            : '98.5%'
        }
      ></rect>
      <rect
        data-testid="backgroundBar"
        x={actualX1}
        x1={actualX1}
        x2={actualX2}
        y={graphIndex === 0 ? 26 : size === ActivityGraphSize.small ? 0.5 : 1}
        fill={getBackgroundBarColor()}
        width={actualX2 - actualX1}
        height={
          graphIndex === 0
            ? '85.5%'
            : size === ActivityGraphSize.small
            ? '96%'
            : '98.5%'
        }
        fillOpacity={1}
        strokeOpacity={0}
      />
      <rect
        x={actualX1}
        x1={actualX1}
        x2={actualX2}
        y={y}
        y1={y1}
        y2={y2}
        fill={fill}
        width={actualX2 - actualX1}
        height={height}
        fillOpacity={1}
        strokeOpacity={0}
      />
    </g>
  )
}

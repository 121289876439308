import { ReactNode, useMemo } from 'react'
import { useSessionContext } from '../../core/contexts/session-context'
import { UserType } from '../../core/entities/user.entity'
import { ContextMenu } from '../ContextMenu/ContextMenu'
import { Upload } from './constants'
import { DeleteUploadMenuItem } from './DeleteUploadMenuItem'
import { DownloadUploadEventsMenuItem } from './DownloadUploadEventsMenuItem'
import { GoToPatientMenuItem } from './GoToPatientMenuItem'

export function UploadActivityOptions({
  upload,
  onDelete
}: {
  upload: Upload
  onDelete: (id: string) => void
}) {
  const { user } = useSessionContext()

  const items: ReactNode[] = useMemo(() => {
    const list = [
      <GoToPatientMenuItem patientId={upload.patientId} />,
      <DownloadUploadEventsMenuItem upload={upload} />
    ]

    if (user && user.type === UserType.HCPAdmin) {
      list.push(<DeleteUploadMenuItem upload={upload} onDelete={onDelete} />)
    }
    return list
  }, [onDelete, upload, user])

  return <ContextMenu items={items} />
}

import styled from 'styled-components'

export const ListWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  flex-direction: column;
`

export const NotificationDot = styled.div`
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background-color: var(--blue);
  position: absolute;
  left: -1rem;
`
export const ListHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  position: relative;
`

export const ListHeaderText = styled.span`
  font-family: inter;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-primary);
`

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.5rem;
  height: 20rem;
  align-items: flex-start;
`
export const ListItem = styled.li`
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`

export const ListItemText = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  color: inherit;
`

export const SelectAllListItemText = styled(ListItemText)`
  color: var(--text-lighter);
`

import { Dispatch, SetStateAction } from 'react'
import { UseFormSetValue } from 'react-hook-form'
import { cprFunction, sixdigitdashfourdigitregex } from './CprValidation'
import {
  foedselsnummerRegex,
  foedselsnummerValidation
} from './FoedselsnummerValidation'
import { MRNTypes } from './interfaces'
import { ptalRegex, ptalValidation } from './PtalValidation'

interface getValidationProps {
  mrntype: MRNTypes
  setPatientError: (value: string | undefined) => void
  getValues: (field?: string | string[] | undefined) => any
  setCurrentMRN: Dispatch<SetStateAction<string>>
  currentMRN: string
  setValue: UseFormSetValue<{ MRN: string; [any: string]: string }>
}
export const getValidation = ({
  mrntype,
  setPatientError,
  getValues,
  setCurrentMRN,
  currentMRN,
  setValue
}: getValidationProps) => {
  switch (mrntype) {
    case MRNTypes.dkcpr:
      return {
        changeFunction: () =>
          cprFunction({
            setPatientError,
            setValue,
            getValues,
            setCurrentMRN,
            currentMRN
          }),
        pattern: sixdigitdashfourdigitregex
      }
    case MRNTypes.svpersonnummer:
      return {
        changeFunction: () =>
          cprFunction({
            setPatientError,
            setValue,
            getValues,
            setCurrentMRN,
            currentMRN
          }),
        pattern: sixdigitdashfourdigitregex
      }
    case MRNTypes.nofoedselsnummer:
      return {
        changeFunction: () =>
          foedselsnummerValidation({
            setPatientError,
            setValue,
            getValues,
            setCurrentMRN,
            currentMRN
          }),
        pattern: foedselsnummerRegex
      }
    case MRNTypes.foptal:
      return {
        changeFunction: () =>
          ptalValidation({
            setPatientError,
            setValue,
            getValues,
            setCurrentMRN,
            currentMRN
          }),
        pattern: ptalRegex
      }
    case MRNTypes.dev:
      return {
        changeFunction: () =>
          cprFunction({
            setPatientError,
            setValue,
            getValues,
            setCurrentMRN,
            currentMRN
          }),
        pattern: sixdigitdashfourdigitregex
      }
  }
}

import {
  FitbitActivityMaxes,
  GlucoseEventsObject
} from '../../../containers/StackedDaily/Interfaces/Interfaces'
import { FitbitObjectItem } from '../../ActivityComponents/interfaces'

export enum InsightsReason {
  NoHypos = 'NoHypos',
  NoPen = 'NoPen',
  NoPenEvents = 'NoPenEvents'
}

export enum HypoCause {
  Unknown = 'Unknown',
  Bolus = 'Bolus',
  Basal = 'Basal',
  Activity = 'Activity'
}
export interface HypoTypeAndCause {
  causes: HypoCause[]
  time: string
  localTime: string
}

export enum NovoPenMedicine {
  Basal = 'Basal',
  Bolus = 'Bolus'
}

export enum NovoPenBatteryStatus {
  Ok = 'Ok'
}
export const NovoPenMedicineString = (medicineType: NovoPenMedicine) => {
  switch (medicineType) {
    case NovoPenMedicine.Basal:
      return 'Tresiba®'
    case NovoPenMedicine.Bolus:
      return 'Novorapid®'
  }
}
export interface NovoPenEventValue {
  id: string
  units: number
  priming: string
  drug: NovoPenMedicine
  time: string
  localTime: string
  device: string | null
  penId: string
  penName: string
  type: 'novoPen'
}
export interface MergedNovoPenEventValue {
  drug: NovoPenMedicine
  localTime: string
  time: string
  totalUnits: number
  type: 'novoPenMergedEvent'
  penEvents: Array<NovoPenEventValue>
}

export interface DetectedHypo {
  azmEvents: FitbitObjectItem
  hypoTimeAndCauses: HypoTypeAndCause
  cgmEvents: GlucoseEventsObject[]
  insulinEvents: NovoPenEventValue[]
}
export interface InsightsDay {
  date: string
  hypos: DetectedHypo[]
}
export interface InsightViewObject {
  days: Array<InsightsDay>
  daysWithData: number
  selectedDays: number
  reason: InsightsReason | null
  fitbitActivityMaxes: FitbitActivityMaxes
}

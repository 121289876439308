import { faGlobe } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Modal } from '../../../components/Modal/Modal'
import { useLanguage } from '../../../hooks/use-language'
import { LanguageSelectorModal } from '../../../LanguageSelector/LanguageSelectorModal/LanguageSelectorModal'

const ButtonWrapper = styled.button`
  background-color: var(--white-color);
  border: 0.0625rem solid var(--text-lightest);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1rem 0.75rem 0.875rem;
  gap: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  color: var(--text-primary);
  &:hover {
    color: var(--brand-primary-color);
  }
  position: relative;
`

const GlobeIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  color: inherit;
`

const ChangeLanguageTooltip = styled.div`
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  background: var(--white-color);
  padding: 0.75rem 1rem;
  box-shadow: 0rem 0.25rem 0.5rem 0rem var(--tooltip-shadow);
  position: absolute;
  top: 3rem;
  right: 0;
  white-space: nowrap;
  z-index: 100;
`

const Text = styled.span`
  font-family: inter;
  color: var(--text-primary);
  font-size: 0.875rem;
  font-weight: 600;
`
export const ChangeLanguage = () => {
  const { i18n, localization, changeLanguage } = useLanguage()
  const [showModal, setShowModal] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const { t } = useTranslation()
  const handleChangeLanguage = (value: string) => {
    changeLanguage(value)
    setShowModal(false)
  }
  return (
    <>
      {showModal && (
        <Modal isOpen={showModal}>
          <LanguageSelectorModal
            closeFun={() => setShowModal(false)}
            changeLanguage={handleChangeLanguage}
            localization={localization}
            currentLanguage={i18n.language}
          />
        </Modal>
      )}
      <ButtonWrapper
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onClick={() => setShowModal(true)}
        data-testid="changeLanguageButton"
      >
        <GlobeIcon icon={faGlobe}></GlobeIcon>
        {showTooltip && (
          <ChangeLanguageTooltip data-testid="changeLanguageTooltip">
            <Text>{t('Language')}</Text>
          </ChangeLanguageTooltip>
        )}
      </ButtonWrapper>
    </>
  )
}

import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Select } from '../Select/Select'

const RESULTS_PER_PAGE = [10, 20, 50, 100]

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
`

const Button = styled.button`
  background: #fafafa;
  border-radius: 8px;
  border: 0;
  padding: 0.75rem;
  cursor: pointer;
  &:hover {
    color: var(--brand-primary-color);
  }
  &:disabled {
    color: var(--text-lightest);
    cursor: auto;
  }
`

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
`

const SummaryText = styled.span`
  font-weight: 600;
  color: var(--text-primary);
  font-size: 0.875rem;
  line-height: 1rem;
  font-family: inter;
`

export function Pagination<T>({ table }: { readonly table: Table<T> }) {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Select
        value={table.getState().pagination.pageSize}
        onChange={(e) => {
          table.setPageSize(Number(e.target.value))
        }}
      >
        {RESULTS_PER_PAGE.map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {t('pagination.per-page', { pageSize })}
          </option>
        ))}
      </Select>
      <SummaryText>
        {getFirstItemInThePage(
          table.getState().pagination.pageIndex,
          table.getState().pagination.pageSize
        )}
        {' - '}
        {getLastItemInThePage(
          table.getState().pagination.pageIndex,
          table.getState().pagination.pageSize,
          table.getRowCount()
        )}
        {` ${t('of')} `}
        {table.getRowCount()}
      </SummaryText>
      <ButtonRow>
        <Button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <Button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </Button>
      </ButtonRow>
    </Wrapper>
  )
}

function getFirstItemInThePage(index: number, resultsPerPage: number) {
  return index * resultsPerPage + 1
}

function getLastItemInThePage(
  index: number,
  resultsPerPage: number,
  totalResults: number
) {
  return Math.min((index + 1) * resultsPerPage, totalResults)
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { DeviceEventConfig } from '../Interfaces'

const CustomIcon = styled(FontAwesomeIcon)<{ $color: string }>`
  color: ${(props) => props.$color};
`

export const DeviceEventIcon = ({
  config,
  isHovered
}: {
  config: DeviceEventConfig | null
  isHovered: boolean
}) => {
  return (
    config && (
      <CustomIcon
        icon={config.icon}
        height={16}
        width={16}
        x={2}
        y={2}
        $color={isHovered ? 'var(--white-color)' : config.colors}
      />
    )
  )
}

import { ProviderPatient } from '../../containers/Integrations/Integrations'

export enum FetchStateEnum {
  Ready = 'Ready',
  NotReady = 'NotReady',
  UpToDate = 'UpToDate'
}
export interface FetchState {
  rateLimitReset: { hours: number; minutes: number } | null
  fetchState: FetchStateEnum
}
export enum ProviderPatientProviderType {
  MedtronicCarelinkWebCMIProvider = 'MedtronicCarelinkWebCMIProvider',
  AbbottLibreViewWebCMIProvider = 'AbbottLibreViewWebCMIProvider',
  DexcomClarityWebCMIProvider = 'DexcomClarityWebCMIProvider',
  DexcomAPICMIProvider = 'DexcomAPICMIProvider',
  FitbitAPICMIProvider = 'FitbitAPICMIProvider'
}
export const dexcomClarityProviderPatientTestData: ProviderPatient = {
  assignedPatientId: '8a81984d8775342e018775359a91277d',
  birthday: '1953-06-16',
  deleted: false,
  id: '1',
  ignored: false,
  name: 'Elmer Madsen',
  mrn: '1234567890',
  localPatientMrn: '1234567890',
  localPatientName: 'Elmer Madsen',
  providerType: ProviderPatientProviderType.DexcomClarityWebCMIProvider,
  providerName: 'Dexcom Clarity Web Provider',
  email: 'test@email.com',
  newestLocalData: '2023-04-12T10:15:36',
  newestProviderData: '2023-04-12T10:15:36',
  providerId: '8a81984d8775342e01877536098e277e',
  providerPatientId: '1762627633994022912',
  connected: false,
  suspended: false,
  latestFetchTime: '2023-04-12T10:15:36',
  fetchState: null
}
export const medtronicCarelinkProviderPatientTestData: ProviderPatient = {
  ...dexcomClarityProviderPatientTestData,
  providerType: ProviderPatientProviderType.MedtronicCarelinkWebCMIProvider,
  providerName: 'Medtronic Carelink Web Provider',
  id: '2'
}

export const abbottLibreViewProviderPatientTestData: ProviderPatient = {
  ...dexcomClarityProviderPatientTestData,
  providerType: ProviderPatientProviderType.AbbottLibreViewWebCMIProvider,
  providerName: 'Abbott LibreView Web Provider',
  id: '3'
}

export const dexcomApiProviderPatientsTestData: ProviderPatient = {
  ...dexcomClarityProviderPatientTestData,
  providerType: ProviderPatientProviderType.DexcomAPICMIProvider,
  id: '4'
}

export const fitbitApiProviderPatientsTestData: ProviderPatient = {
  ...dexcomClarityProviderPatientTestData,
  providerType: ProviderPatientProviderType.FitbitAPICMIProvider,
  fetchState: {
    fetchState: FetchStateEnum.Ready,
    rateLimitReset: { hours: 0, minutes: 59 }
  },
  id: '5'
}

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'
import { FormValues, SearchBar } from '../../components/SearchBar/SearchBar'
import { usePatientContext } from '../../core/contexts/patient-context'
import { useSessionContext } from '../../core/contexts/session-context'
import { UserType } from '../../core/entities/user.entity'

export function PatientSearch() {
  const { t } = useTranslation()
  const [patientError, setPatientError] = useState<string | undefined>()
  const [searchingState, setSearchingState] = useState(false)
  const navigate = useNavigate()
  const { searchPatient } = usePatientContext()
  const session = useSessionContext()

  const handleSuccess = (formdata: FormValues) => {
    setSearchingState(true)
    if (searchPatient) {
      searchPatient(formdata.MRN)
        .then((patient) => {
          if (patient === null) {
            throw new Error(t('Patient not found'))
          }
          navigate(`/portal/patients/${patient?.patientId}/insights`)
        })
        .catch(function (error: Error) {
          console.log(error)
          setPatientError(error.message)
          setSearchingState(false)
        })
    }
  }

  if (session.user?.type === UserType.Patient) {
    return <Navigate to={'/'} />
  }

  return (
    <SearchBar
      onSuccess={handleSuccess}
      patientError={patientError}
      setPatientError={setPatientError}
      searching={searchingState}
    >
      {searchingState ? `${t('Searching')}` : `${t('Search')}`}
    </SearchBar>
  )
}

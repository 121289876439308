import { findDiff } from './helperfunctions/findDiff'
import { isNumber } from './helperfunctions/isNumber'
import { ValdiationFunctionProps } from './interfaces'

export const ptalValidation = ({
  setPatientError,
  setValue,
  getValues,
  setCurrentMRN,
  currentMRN
}: ValdiationFunctionProps) => {
  setPatientError(undefined)
  const mrn = getValues('MRN')
  const diff = findDiff(currentMRN, mrn)
  if (mrn.match(/-.*-/)) {
    setValue('MRN', currentMRN)
  }
  if (mrn.length === 0) {
    setValue('MRN', mrn)
    setCurrentMRN(mrn)
    return
  }
  if (mrn.length === 1 && mrn.includes('-')) {
    setValue('MRN', '')
    setCurrentMRN('')
    return
  }
  if (mrn.includes('-')) {
    const index = mrn.indexOf('-')
    const firstSix = mrn.substring(0, index)
    const lastThree = mrn.substring(index + 1, mrn.length)
    if (firstSix.replace('-', '').length < 6) {
      if (lastThree.length > 3) {
        setValue('MRN', currentMRN)
        return
      } else {
        setValue('MRN', mrn)
        setCurrentMRN(mrn)
        return
      }
    }
    if (lastThree.length > 3 || firstSix.length > 6) {
      setValue('MRN', currentMRN)
      return
    }
  }
  if (
    !isNumber(diff.difference) &&
    diff.difference !== '' &&
    diff.difference.length < 2
  ) {
    setValue('MRN', currentMRN)
    return
  }
  if (mrn.length > 10) {
    setValue('MRN', currentMRN)
    return
  }
  if (mrn.length > 6 && !mrn.includes('-')) {
    setValue('MRN', mrn.substring(0, 6) + '-' + mrn.substring(6, 10))
    setCurrentMRN(mrn.substring(0, 6) + '-' + mrn.substring(6, 10))
    return
  }
  if (currentMRN.includes('-') && !mrn.includes('-')) {
    const index = currentMRN.indexOf('-')
    const lastFour = mrn.substring(index + 1, mrn.length)
    if (lastFour.length === 0) {
      setValue('MRN', mrn)
    } else {
      setValue('MRN', currentMRN)
    }
    return
  }
  setCurrentMRN(getValues('MRN'))
}

export const ptalRegex = /^\d{6}-\d{3}$/

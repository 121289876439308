import { faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as _ from 'lodash'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { loadIncrement } from '../../constants/loadIncrement'
import {
  DeviceEnum,
  FilterEnum,
  IntegrationFilters
} from '../../containers/ClinicIntegrations/IntegrationFilters/IntegrationFilters'
import { ProviderPatient } from '../../containers/Integrations/Integrations'
import {
  ProviderPatientsFilters,
  getProviderPatients
} from '../../core/api/get-provider-patients'
import { useSiteSettingsContext } from '../../core/contexts/site-settings-context'
import dayjs from '../../core/dayjs/dayjs'
import { ProviderPatientProviderType } from '../../core/testdata/ProviderPatientTestData'
import { Modal } from '../Modal/Modal'
import { GetProviders } from '../ProviderIntegrationModel/getProviders'
import { NoDataRow } from '../Table/NoDataRow'
import {
  ScrollTableComponent,
  THead,
  TText,
  Td,
  Th,
  Tr
} from '../Table/ScrollTableComponent'
import { ScrollTableLoading } from '../Table/ScrollTableLoading.tsx'
import { LinkPatientButton } from './LinkPatientButton/LinkPatientButton'
import { LinkPatientModal } from './LinkPatientModal/LinkPatientModal'
import { LoadMoreButton } from './LoadMoreButton/LoadMoreButton'
import { PatientApiModal } from './PatientApiModal/PatientApiModal'
export enum ProviderPatientsType {
  todo = 'to do',
  all = 'all',
  dexcomapiprovider = 'dexcomapiprovider',
  fitbitapiprovider = 'fitbitapiprovider',
  medtroniccarelinkwebprovider = 'medtroniccarelinkwebprovider',
  abbottlibreviewwebprovider = 'abbottlibreviewwebprovider',
  dexcomclaritywebprovider = 'dexcomclaritywebprovider'
}

export enum PMIStateValues {
  CONNECTING = 'CONNECTING',
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  NOT_SETUP = 'NOT_SETUP'
}
export enum SortEnum {
  Ascending = 'Ascending',
  Descending = 'Descending'
}
export enum OrderEnum {
  BirthDay = 'BirthDay',
  PatientName = 'PatientName',
  MRN = 'MRN'
}

export interface FilterProps {
  filterState:
    | {
        type: FilterEnum
        state: boolean
      }[]
    | null
  sortState:
    | {
        type: OrderEnum
        state: SortEnum | null
      }[]
    | null
  deviceState:
    | {
        type: DeviceEnum
        state: boolean
      }[]
    | null
}

export interface ProviderPatientsObject {
  providerPatients: Array<ProviderPatient>
}
interface PatientRowProps {
  patient: ProviderPatient
  refresh: () => void
  type: ProviderPatientsType
}

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-bottom: 2rem;
`

export const SortIconWrapper = styled.div`
  display: flex;
  flex-direction: column;

  cursor: pointer;
`
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  min-height: 2.125rem;
`
const SortIcon = styled(FontAwesomeIcon)<{ $active: boolean }>`
  font-size: 1rem;
  font-weight: 500;
  color: ${(props) =>
    props.$active ? 'var(--brand-primary-color)' : 'var(--text-lightest)'};
  margin-right: 0.25rem;
`
const SortIconUp = styled(SortIcon)`
  transform: translateY(50%);
`
const SortIconDown = styled(SortIcon)`
  transform: translateY(-50%);
`
const ProviderPatientsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
`

export const RowBoxWrapper = styled.div`
  min-height: 2.125rem;
  display: flex;
  align-items: center;
`

const UnspecificProviderGroups = () => {
  return (
    <colgroup>
      <col style={{ width: '10%' }}></col>
      <col style={{ width: '20%' }}></col>
      <col style={{ width: '10%' }}></col>
      <col style={{ width: '20%' }}></col>
      <col style={{ width: '10%' }}></col>
      <col style={{ width: '10%' }}></col>
      <col style={{ width: '10%' }}></col>
      <col style={{ width: '5%' }}></col>
      <col style={{ width: '5%' }}></col>
    </colgroup>
  )
}
const SpecificProviderGroups = () => {
  return (
    <colgroup>
      <col style={{ width: '10%' }}></col>
      <col style={{ width: '22.5%' }}></col>
      <col style={{ width: '12.5%' }}></col>
      <col style={{ width: '22.5%' }}></col>
      <col style={{ width: '10%' }}></col>
      <col style={{ width: '12.5%' }}></col>
      <col style={{ width: '5%' }}></col>
      <col style={{ width: '5%' }}></col>
    </colgroup>
  )
}
const getIsDefault = (type: ProviderPatientsType, filters: FilterProps) => {
  const defaultSortState = Object.keys(OrderEnum).map((key) =>
    (key as OrderEnum) === OrderEnum.PatientName
      ? {
          type: key as OrderEnum,
          state: SortEnum.Ascending
        }
      : {
          type: key as OrderEnum,
          state: null
        }
  )

  const isDefaultSortState = _.isEqual(filters.sortState, defaultSortState)

  const isDefaultDeviceState =
    filters.deviceState?.find((e) => e.type === DeviceEnum.allDevices)
      ?.state === true
  const isDefaultFilterState =
    filters.filterState?.every((e) => e.state === false) === true

  return () => {
    switch (type) {
      case ProviderPatientsType.todo:
        return isDefaultSortState && isDefaultDeviceState
      case ProviderPatientsType.all:
        return (
          isDefaultSortState && isDefaultDeviceState && isDefaultFilterState
        )
      default:
        return isDefaultFilterState && isDefaultSortState
    }
  }
}

export const getProviderPlatformTitle = (type: ProviderPatientProviderType) => {
  switch (type) {
    case ProviderPatientProviderType.AbbottLibreViewWebCMIProvider:
      return 'Abbott Libreview'
    case ProviderPatientProviderType.DexcomClarityWebCMIProvider:
      return 'Dexcom Clarity'
    case ProviderPatientProviderType.MedtronicCarelinkWebCMIProvider:
      return 'Medtronic Carelink'
    case ProviderPatientProviderType.DexcomAPICMIProvider:
      return 'Dexcom API'
    case ProviderPatientProviderType.FitbitAPICMIProvider:
      return 'Fitbit API'
  }
}

const deviceEnumToCMIProvider = (
  device:
    | DeviceEnum.abbott
    | DeviceEnum.dexcom
    | DeviceEnum.dexcomApi
    | DeviceEnum.fitbit
    | DeviceEnum.medtronic
) => {
  switch (device) {
    case DeviceEnum.dexcom:
      return ProviderPatientProviderType.DexcomClarityWebCMIProvider
    case DeviceEnum.dexcomApi:
      return ProviderPatientProviderType.DexcomAPICMIProvider
    case DeviceEnum.abbott:
      return ProviderPatientProviderType.AbbottLibreViewWebCMIProvider
    case DeviceEnum.medtronic:
      return ProviderPatientProviderType.MedtronicCarelinkWebCMIProvider
    case DeviceEnum.fitbit:
      return ProviderPatientProviderType.FitbitAPICMIProvider
  }
}
interface SortIconComponentProps {
  sortState: Array<{ type: OrderEnum; state: null | SortEnum }>
  setSortState: Dispatch<
    SetStateAction<{ type: OrderEnum; state: SortEnum | null }[] | null>
  >
  type: OrderEnum
}
export const SortIconComponent = ({
  sortState,
  setSortState,
  type
}: SortIconComponentProps) => {
  const index = sortState.findIndex(
    (e: { type: OrderEnum; state: SortEnum | null }) => e.type === type
  )
  const parse: {
    type: OrderEnum
    state: null | SortEnum
  }[] = JSON.parse(JSON.stringify(sortState))

  return (
    <SortIconWrapper
      data-testid={'sort' + type}
      onClick={() => {
        if (parse[index].state === null) {
          const filter: Array<{ type: OrderEnum; state: null | SortEnum }> =
            Object.keys(OrderEnum).map((key) => ({
              type: key as OrderEnum,
              state: null
            }))
          filter[index].state = SortEnum.Ascending
          setSortState(filter)
        } else {
          parse[index].state =
            parse[index].state === SortEnum.Ascending
              ? SortEnum.Descending
              : SortEnum.Ascending
          setSortState(parse)
        }
      }}
    >
      <SortIconUp
        icon={faSortUp}
        $active={parse[index].state === SortEnum.Ascending}
      />
      <SortIconDown
        icon={faSortDown}
        $active={parse[index].state === SortEnum.Descending}
      />
    </SortIconWrapper>
  )
}
const PatientRow = ({ patient, refresh, type }: PatientRowProps) => {
  const [showLinkPatientModal, setShowLinkPatientModal] = useState(false)
  const newestLocalData = patient.newestLocalData
    ? dayjs(patient.newestLocalData)
    : '-'
  const newestProviderData = patient.newestProviderData
    ? dayjs(patient.newestProviderData)
    : '-'
  return (
    <>
      <Modal isOpen={showLinkPatientModal}>
        {patient.providerType ===
        ProviderPatientProviderType.DexcomAPICMIProvider ? (
          <PatientApiModal
            providerPatient={patient}
            setShowLinkPatientModal={() => setShowLinkPatientModal(false)}
            refresh={refresh}
          ></PatientApiModal>
        ) : (
          <LinkPatientModal
            providerPatient={patient}
            setShowLinkPatientModal={() => setShowLinkPatientModal(false)}
            refresh={refresh}
          ></LinkPatientModal>
        )}
      </Modal>
      <Tr key={patient.id}>
        <LinkPatientButton
          setShowLinkPatientModal={() => setShowLinkPatientModal(true)}
          patient={patient}
        />

        <Td>
          <RowBoxWrapper>
            <TText>{patient.localPatientName ?? '-'}</TText>
          </RowBoxWrapper>
        </Td>
        <Td>
          <RowBoxWrapper>
            <TText>{patient.localPatientMrn ?? '-'}</TText>
          </RowBoxWrapper>
        </Td>
        <Td>
          <RowBoxWrapper>
            <TText>{patient.name ?? '-'}</TText>
          </RowBoxWrapper>
        </Td>
        <Td>
          <RowBoxWrapper>
            <TText>
              {patient.birthday
                ? dayjs(patient.birthday).format('DD/MM/YYYY')
                : '-'}
            </TText>
          </RowBoxWrapper>
        </Td>
        <Td>
          <RowBoxWrapper>
            <TText>{patient.email ?? '-'}</TText>
          </RowBoxWrapper>
        </Td>
        {(type === ProviderPatientsType.all ||
          type === ProviderPatientsType.todo) && (
          <Td>
            <RowBoxWrapper>
              <TText>{getProviderPlatformTitle(patient.providerType)}</TText>
            </RowBoxWrapper>
          </Td>
        )}
        <Td>
          <RowBoxWrapper>
            {newestLocalData !== '-' && newestLocalData instanceof dayjs ? (
              <>
                <TText>{newestLocalData.format('DD/MM/YYYY')}</TText>
                <TText style={{ color: 'var(--text-lighter)' }}>
                  &nbsp;@&nbsp;
                </TText>
                <TText>{newestLocalData.format('hh:mm')}</TText>
              </>
            ) : (
              <TText>{newestLocalData}</TText>
            )}
          </RowBoxWrapper>
        </Td>
        <Td>
          <RowBoxWrapper>
            {newestProviderData !== '-' &&
            newestProviderData instanceof dayjs ? (
              <>
                <TText>{newestProviderData.format('DD/MM/YYYY')}</TText>
                <TText style={{ color: 'var(--text-lighter)' }}>
                  &nbsp;@&nbsp;
                </TText>
                <TText>{newestProviderData.format('hh:mm')}</TText>
              </>
            ) : (
              <TText>{newestProviderData}</TText>
            )}
          </RowBoxWrapper>
        </Td>
      </Tr>
    </>
  )
}
interface ProviderPatientsProps {
  type: ProviderPatientsType
}
export const ProviderPatients = ({ type }: ProviderPatientsProps) => {
  const { siteSettings } = useSiteSettingsContext()
  const { providers, providersError } = GetProviders()
  const { providerId } = useParams()
  const providerType =
    providers?.find((provider) => provider.id === providerId)?.type ?? type
  const { t } = useTranslation()
  const [patients, setPatients] = useState<null | ProviderPatient[]>(null)
  const [isCurrentlyDefault, setIsCurrentlyDefault] = useState<boolean>(false)
  const [refresh, setRefresh] = useState(false)
  const [showFilters, setShowFilters] = useState<boolean>(false)
  const [maxSize, setMaxSize] = useState<number>(0)
  const [filterState, setFilterState] = useState<
    | null
    | {
        type: FilterEnum
        state: boolean
      }[]
  >(null)
  const [sortState, setSortState] = useState<
    | null
    | {
        type: OrderEnum
        state: SortEnum | null
      }[]
  >(null)
  const [deviceState, setDeviceState] = useState<
    | null
    | {
        type: DeviceEnum
        state: boolean
      }[]
  >(null)
  const [loadAmount, setLoadAmount] = useState<number>(loadIncrement)
  const [patientsError, setPatientsError] = useState<boolean>(false)
  const [searchString, setSearchString] = useState<string>('')
  const [allFiltersState, setAllFiltersState] =
    useState<ProviderPatientsFilters | null>(null)

  const clearFilterState = () => {
    const filter: Array<{ type: FilterEnum; state: boolean }> = Object.keys(
      FilterEnum
    ).map((key) => ({
      type: key as FilterEnum,
      state: false
    }))
    setFilterState(filter)
  }
  const clearDeviceState = () => {
    const filter: Array<{ type: DeviceEnum; state: boolean }> = Object.keys(
      DeviceEnum
    ).map((key) =>
      key === DeviceEnum.allDevices
        ? {
            type: key as DeviceEnum,
            state: true
          }
        : {
            type: key as DeviceEnum,
            state: false
          }
    )
    setDeviceState(filter)
  }

  const clearSortState = () => {
    const filter = Object.keys(OrderEnum).map((key) =>
      (key as OrderEnum) === OrderEnum.PatientName
        ? {
            type: key as OrderEnum,
            state: SortEnum.Ascending
          }
        : {
            type: key as OrderEnum,
            state: null
          }
    )
    setSortState(filter)
  }

  const clearSearchstring = () => {
    setSearchString('')
  }
  const clearFunction = useCallback((first: boolean) => {
    clearFilterState()
    clearSortState()
    clearDeviceState()
    first && clearSearchstring()
  }, [])

  useEffect(() => {
    clearFunction(true)
  }, [clearFunction])

  useEffect(() => {
    if (filterState === null) return
    if (providerId !== undefined && providerType === ProviderPatientsType.all)
      return

    setIsCurrentlyDefault(
      getIsDefault(providerType, {
        filterState,
        deviceState,
        sortState
      })
    )
    const allDevices =
      deviceState?.find((e) => e.type === DeviceEnum.allDevices)?.state === true
    const allFilters =
      filterState?.find((e) => e.type === FilterEnum.allFilters)?.state === true

    const getProviderTypes = () => {
      if (allDevices) return Object.values(ProviderPatientProviderType)
      const types: ProviderPatientProviderType[] = []
      deviceState?.forEach((state) => {
        if (state.state === true && state.type !== DeviceEnum.allDevices) {
          types.push(deviceEnumToCMIProvider(state.type))
        }
      })
      return types
    }
    const filters: ProviderPatientsFilters = {
      selection: {
        sortBy:
          sortState?.find((e) => e.state !== null)?.type ??
          OrderEnum.PatientName,
        order:
          sortState?.find((e) => e.state !== null)?.state ?? SortEnum.Ascending,
        searchBy: {
          todo: providerType === ProviderPatientsType.todo,
          linked: allFilters
            ? true
            : filterState?.find((e) => e.type === FilterEnum.linked)?.state ??
              false,
          ignored: allFilters
            ? true
            : filterState?.find((e) => e.type === FilterEnum.ignored)?.state ??
              false,
          searchString: searchString !== '' ? searchString : null,
          providerId: providerId !== undefined ? providerId : null,
          providerTypes: getProviderTypes()
        }
      },
      size: loadAmount
    }
    setAllFiltersState(filters)
  }, [
    deviceState,
    filterState,
    loadAmount,
    providerId,
    providerType,
    searchString,
    sortState
  ])

  useEffect(() => {
    const getAllProviderPatients = async () => {
      if (allFiltersState === null) return null
      setPatientsError(false)
      setPatients(null)
      try {
        setPatients(null)
        const data = await getProviderPatients(allFiltersState)
        setPatients(data.providerPatients)
        setMaxSize(data.size)
      } catch (error) {
        setPatientsError(true)
        console.log(error)
      }
    }

    getAllProviderPatients()
  }, [allFiltersState, refresh])

  const passFilters = (searchstring: string) => {
    setSearchString(searchstring)
  }
  if (patientsError) {
    return (
      <div data-testid="providerPatientsError">Provider Patients Error</div>
    )
  }

  return (
    <TableWrapper>
      <IntegrationFilters
        passFilters={passFilters}
        clearFunction={() => clearFunction(false)}
        filterState={filterState}
        setFilterState={setFilterState}
        deviceState={deviceState}
        setDeviceState={setDeviceState}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        providerId={providerId}
        searchString={searchString}
        isCurrentlyDefault={isCurrentlyDefault}
        type={providerType}
      />
      {sortState && (
        <ProviderPatientsWrapper data-testid="ProviderPatients">
          <ScrollTableComponent
            group={
              providerType === ProviderPatientsType.todo ||
              providerType === ProviderPatientsType.all ? (
                <UnspecificProviderGroups />
              ) : (
                <SpecificProviderGroups />
              )
            }
          >
            <THead>
              <Th>
                <HeaderWrapper>
                  <TText>{t('Status')}</TText>
                </HeaderWrapper>
              </Th>
              <Th>
                <HeaderWrapper>
                  <TText>{t('Name (local)')}</TText>
                </HeaderWrapper>
              </Th>
              <Th>
                <HeaderWrapper>
                  <TText>
                    {t(`${siteSettings?.mrnType}.mrn-title`) +
                      ' ' +
                      t('(local)')}
                  </TText>
                </HeaderWrapper>
              </Th>
              <Th>
                <HeaderWrapper>
                  <TText>{t('Name (provider)')}</TText>
                  <SortIconComponent
                    sortState={sortState}
                    setSortState={setSortState}
                    type={OrderEnum.PatientName}
                  />
                </HeaderWrapper>
              </Th>
              <Th>
                <HeaderWrapper>
                  <TText>{t('Birthday (provider)')}</TText>
                  <SortIconComponent
                    sortState={sortState}
                    setSortState={setSortState}
                    type={OrderEnum.BirthDay}
                  />
                </HeaderWrapper>
              </Th>
              <Th>
                <HeaderWrapper>
                  <TText>{t('Email (provider)')}</TText>
                </HeaderWrapper>
              </Th>
              {(providerType === ProviderPatientsType.all ||
                providerType === ProviderPatientsType.todo) && (
                <Th>
                  <HeaderWrapper>
                    <TText>{t('Provider platform')}</TText>
                  </HeaderWrapper>
                </Th>
              )}
              <Th>
                <HeaderWrapper>
                  <TText>{t('Latest local data')}</TText>
                </HeaderWrapper>
              </Th>
              <Th>
                <HeaderWrapper>
                  <TText>{t('Latest provider data')}</TText>
                </HeaderWrapper>
              </Th>
            </THead>
            {patients ? (
              patients.length > 0 ? (
                patients
                  .slice(0, loadAmount)
                  .map((patient: ProviderPatient) => (
                    <PatientRow
                      patient={patient}
                      key={patient.id}
                      refresh={() => setRefresh(!refresh)}
                      type={providerType}
                    ></PatientRow>
                  ))
              ) : (
                <NoDataRow />
              )
            ) : (
              <ScrollTableLoading size={loadAmount} />
            )}
          </ScrollTableComponent>
          {loadAmount < maxSize && (
            <LoadMoreButton
              setLoadAmount={setLoadAmount}
              loadAmount={loadAmount}
              maxSize={maxSize}
            />
          )}
        </ProviderPatientsWrapper>
      )}
    </TableWrapper>
  )
}

import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'i18next'
import { Dispatch, SetStateAction, useState } from 'react'
import styled from 'styled-components'
import {
  DoseRecommendationValue,
  getDoseRecommendationsResponse,
  NovoPenObject
} from '../../core/api/getDoseRecommendations'
import { putNewDoseRecommendation } from '../../core/api/putNewDoseRecommendation'
import { usePatientContext } from '../../core/contexts/patient-context'
import { useSiteSettingsContext } from '../../core/contexts/site-settings-context'
import { SnackBar, SnackBarType } from '../../libraries/Toast/SnackBar'
import { CurrentDose } from './CurrentDose/CurrentDose'
import { DoseRecommender } from './DoseRecommender/DoseRecommender'
import { PenSection } from './PenSection/PenSection'

const Wrapper = styled.div`
  display: flex;
  padding: 0.75rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  border: 0.0625rem solid var(--text-lightest);
  border-radius: 1.5rem;
  width: 100%;
  box-sizing: border-box;
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
  height: 14.75rem;
`
const Footer = styled.div`
  display: flex;
  width: 100%;
`

const WarningBox = styled.div`
  box-sizing: border-box;
  height: 3rem;
  padding: 0 1rem;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  border-radius: 0.75rem;
  background-color: var(--element-bg);
  border: 0.125rem solid var(--yellow);
  width: 100%;
`
const WarningIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--yellow);
`
const WarningText = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: var(--text-primary);
`
const Button = styled.button`
  min-width: 26.25rem;
  max-width: 30rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background-color: var(--blue);
  color: var(--white-color);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
  cursor: pointer;
  border: none;
  &:hover {
    background-color: var(--blue-darker);
  }
  &:disabled {
    opacity: 0.3;
    cursor: default;
    &:hover {
      background-color: var(--blue);
    }
  }
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
`
export const PenDosing = ({
  smartPen,
  doseRecommendation,
  isPatient,
  updateHistory,
  setDoseRecommendation
}: {
  smartPen: NovoPenObject | null
  doseRecommendation: DoseRecommendationValue
  isPatient: boolean
  updateHistory: () => void
  setDoseRecommendation: Dispatch<
    SetStateAction<getDoseRecommendationsResponse | null>
  >
}) => {
  const { siteSettings } = useSiteSettingsContext()
  const { patient } = usePatientContext()
  const [nextDose, setNextDose] = useState<number>(
    doseRecommendation.recommendedDose
  )
  const largeChange =
    Math.abs(nextDose - doseRecommendation.currentDose) >
    doseRecommendation.currentDose * 0.2

  const handleSetNewDoseRecommendation = async () => {
    try {
      await putNewDoseRecommendation(
        patient?.patientId ?? '',
        nextDose,
        siteSettings?.releaseVersion
      )
      setDoseRecommendation(null)
      updateHistory()
    } catch (e) {
      SnackBar({
        type: SnackBarType.Error,
        message: t(
          'Something went wrong when setting the new dose recommendation, please try again'
        )
      })
    }
  }

  return (
    <Wrapper data-testid="penDosingComponent">
      <>
        <Row>
          <PenSection smartPen={smartPen} isPatient={isPatient} />
          <CurrentDose
            isPatient={isPatient}
            hcpName={doseRecommendation.setBy}
            time={doseRecommendation.currentDoseTimeZone}
            currentDose={doseRecommendation.currentDose}
          />
          {!isPatient && (
            <Column>
              <DoseRecommender
                originalNextDose={doseRecommendation.recommendedDose}
                previousDose={doseRecommendation.currentDose}
                nextDose={nextDose}
                setNextDose={setNextDose}
                largeChange={largeChange}
                doseState={doseRecommendation.state}
              />
              <Button
                data-testid="setNewDose"
                onClick={handleSetNewDoseRecommendation}
                disabled={!!(nextDose < 0 || nextDose > 1000)}
              >
                {t('Set new dose recommendation')}
              </Button>
            </Column>
          )}
        </Row>
        {!isPatient && largeChange && (
          <Footer>
            <WarningBox>
              <WarningIcon icon={faTriangleExclamation} />
              <WarningText>
                {t(
                  'This dose is more than 20% different than the currenty set dose. Are you sure you want to change it this much?'
                )}
              </WarningText>
            </WarningBox>
          </Footer>
        )}
      </>
    </Wrapper>
  )
}

import { faInbox } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ActivityGraphSize } from '../ActivityGraph'

const NoDataWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-41%) translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0.4;
  gap: 0.5rem;
`
const NoDataText = styled.span`
  color: var(--text-lighter);
  text-align: center;
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 0.875rem;
`
const NoDataIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  color: var(--text-lighter);
`

interface ActivityGraphNoDataProps {
  size: ActivityGraphSize
}
export const ActivityGraphNoData = ({ size }: ActivityGraphNoDataProps) => {
  const { t } = useTranslation()
  return (
    <NoDataWrapper>
      {size === ActivityGraphSize.big && <NoDataIcon icon={faInbox} />}
      <NoDataText>{t('NO DATA')}</NoDataText>
    </NoDataWrapper>
  )
}

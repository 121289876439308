import { faLock, faPen } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const InfoFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  align-self: stretch;
  position: relative;
`

const InfoFieldLabel = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-medium);
  margin-left: 0.5rem;
`
const InfoField = styled.input`
  border: 0.0625rem solid var(--text-lightest);
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-primary);
  &:disabled{
    background-color(--element-bg-dark);
  }
    &:focus,
  active {
    border-color: var(--brand-primary-color);
     outline: none;
  }
`

const IconWrapper = styled.div`
  position: absolute;
  top: 2.5625rem;
  right: 1rem;
`
const LockIcon = styled(FontAwesomeIcon)`
  color: var(--text-medium);
  font-size: 0.875rem;
`

const PenIcon = styled(FontAwesomeIcon)`
  color: var(--brand-primary-color);
  font-size: 0.875rem;
  cursor: pointer;
  &:hover {
    color: var(--brand-darker-color);
  }
`

interface InfoFieldComponentProps {
  label: string
  value: string | undefined
  disabled: boolean
  edit: boolean
  handleEdit?: () => void
}
export const InfoFieldComponent = ({
  label,
  value,
  disabled,
  edit,
  handleEdit
}: InfoFieldComponentProps) => {
  return (
    <InfoFieldWrapper>
      <InfoFieldLabel>{label}</InfoFieldLabel>
      <InfoField disabled={disabled} value={value}></InfoField>
      {disabled && (
        <IconWrapper>
          {edit ? (
            <PenIcon icon={faPen} onClick={handleEdit} data-testid="editPen" />
          ) : (
            <LockIcon icon={faLock} />
          )}
        </IconWrapper>
      )}
    </InfoFieldWrapper>
  )
}

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { formatNumber } from '../../../../helpers/helpers'
import {
  SubTitle,
  Title,
  TitleWrapper,
  Value,
  Wrapper
} from '../AvgInjections/AvgInjections'
import { BolusInjectionWidgetData } from '../BolusInjection'

const ValueAndUnit = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  gap: 0.125rem;
`
const UnitText = styled(SubTitle)`
  line-height: 0.625rem;
  font-weight: 700;
  font-size: 0.625rem;
`
const BolusValue = styled(Value)`
  color: var(--bolus-color);
`
export const AvgAmount = ({ data }: { data: BolusInjectionWidgetData }) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <TitleWrapper>
        <Title>{t('Avg. daily total bolus')}</Title>
        <SubTitle>{t('including flow check')}</SubTitle>
      </TitleWrapper>
      <ValueAndUnit>
        <BolusValue data-testid="bolusAvgDailyAmountValue">
          {formatNumber({ value: data.avgDailyAmount, decimals: 1 })}
        </BolusValue>
        <UnitText>U total</UnitText>
      </ValueAndUnit>
    </Wrapper>
  )
}

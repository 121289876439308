import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { NovoPenObject } from '../../../core/api/getDoseRecommendations'
import { NovoPenMedicine } from '../../Graphs/Insights/interfaces'
import { ModalComponent } from '../../Modal/Modal'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 0.0625rem solid var(--element-bg);
  background-color: var(--white-color);
  border-radius: 1.5rem;
  width: 31.25rem;
  padding: 2rem;
  box-sizing: border-box;
  gap: 2rem;
`
const Title = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  color: var(--text-primary);
  font-weight: 700;
  line-height: 2.25rem;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
`
const KeyValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 50%;
`

const Key = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: var(--text-lighter);
`
const Value = styled(Key)`
  color: var(--text-primary);
`

const ValueRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
`

const InfoSection = styled.span`
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 150%;
  color: var(--text-lighter);
`
export const MorePenInfo = ({
  closeFun,
  smartPen
}: {
  closeFun: () => void
  smartPen: NovoPenObject
}) => {
  const { t } = useTranslation()

  const getDeviceType = () => {
    switch (smartPen.type) {
      case NovoPenMedicine.Basal:
        return t('NovoPen® 6 Blue')
      case NovoPenMedicine.Bolus:
        return t('NovoPen® 6 Silver')
    }
  }

  return (
    <ModalComponent closeFun={closeFun} closetestid="closeMorePenInfo">
      <Wrapper data-testid="morePenInfo">
        <Title>{t('NovoPen details')}</Title>
        <Row>
          <KeyValue>
            <Key>{t('Device type')}</Key>
            <Value>{getDeviceType()}</Value>
          </KeyValue>
          <KeyValue>
            <Key>{t('Device name')}</Key>
            <Value>{smartPen.name}</Value>
          </KeyValue>
        </Row>
        <Row>
          <KeyValue>
            <Key>{t('Firmware')}</Key>
            <Value>{smartPen.firmwareRevision}</Value>
          </KeyValue>
          <KeyValue>
            <Key>{t('Dose memory')}</Key>
            <ValueRow>
              <Value>{smartPen.memory}</Value>
              <Key>/</Key>
              <Value>800</Value>
              <Key>{t('doses used')}</Key>
            </ValueRow>
          </KeyValue>
        </Row>
        <InfoSection>
          {t(
            'Novo Nordisk A/S provides the necessary technical documentation and assets to Line Systems in order to make safe integrations with NovoPen® 6 and NovoPen Echo® Plus (“NN Smart Pens”). However, neither ADAPT or its integration with NN Smart Pens have been developed, assessed or in any way endorsed by Novo Nordisk A/S.'
          )}
        </InfoSection>
      </Wrapper>
    </ModalComponent>
  )
}

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { HeaderWrapper } from '../../components/ProviderPatients/ProviderPatients'
import { NoDataRow } from '../../components/Table/NoDataRow'
import {
  ScrollTableComponent,
  Th,
  THead,
  TText
} from '../../components/Table/ScrollTableComponent'
import { ScrollTableLoading } from '../../components/Table/ScrollTableLoading.tsx'
import { getPatient } from '../../core/api/get-patient-api'
import { getTitrationLog } from '../../core/api/getTitrationLog'
import { useSiteSettingsContext } from '../../core/contexts/site-settings-context'
import { SnackBar, SnackBarType } from '../../libraries/Toast/SnackBar'
import { TimeInRangeObject } from '../StackedDaily/Interfaces/Interfaces'
import { PenTitrationRow } from './PenTitrationsRow/PenTitrationsRow'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 85.5rem;
  min-width: 61.5rem;
  gap: 1rem;
  padding-right: 2rem;
`

const TableWrapper = styled.div`
  width: 100%;
`

const Title = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2.25rem;
  color: var(--text-primary);
`
const TitrationHistoryGroup = () => {
  return (
    <colgroup>
      <col style={{ width: '13.5%' }} />
      <col style={{ width: '8%' }} />
      <col style={{ width: '15.5%' }} />
      <col style={{ width: '7%' }} />
      <col style={{ width: '8%' }} />
      <col style={{ width: '8%' }} />
      <col style={{ width: '12%' }} />
      <col style={{ width: '28%' }} />
    </colgroup>
  )
}

export interface PatientPenTitrationEvent {
  patientName: string
  mrn: string
  timeInRange: TimeInRangeObject | null
  sensorCoverage: number
  currentDose: number | null
  suggestedDose: number | null
  change: number | null
  timeSinceLastTitration: number | null
}

export const PenTitrations = () => {
  const navigate = useNavigate()
  const [log, setLog] = useState<null | PatientPenTitrationEvent[]>(null)
  const [logError, setLogError] = useState<boolean>(false)
  const { siteSettings } = useSiteSettingsContext()
  const { t } = useTranslation()
  const columns = [
    t('Patient name'),
    t(`${siteSettings?.mrnType}.mrn-title`),
    t('Time in range (3 days)'),
    t('Sensor usage'),
    t('Current dose'),
    t('Suggest. dose'),
    t('Change'),
    t('Time since last titration')
  ]
  const linkToPatient = async (mrn: string, penDosing: boolean) => {
    try {
      const response = (await getPatient(mrn.replace(/-/g, ''))).data.pop()
      if (!response) {
        throw new Error(t('Patient not found'))
      }
      navigate(
        `/portal/patients/${response.patientId}/${
          penDosing ? 'pen_dosing' : 'insights'
        }`
      )
    } catch (error: any) {
      SnackBar({ type: SnackBarType.Error, message: error.message })
    }
  }

  useEffect(() => {
    const getLog = async () => {
      setLogError(false)
      try {
        const historyResponse = await getTitrationLog()
        setLog(historyResponse)
      } catch (e) {
        setLogError(true)
        SnackBar({
          type: SnackBarType.Error,
          message: t('Something went wrong when getting titration log')
        })
      }
    }
    getLog()
  }, [t])
  return (
    <Container>
      <Wrapper>
        <Title>{t('Smart pen – basal titration')}</Title>
        <TableWrapper>
          <ScrollTableComponent group={<TitrationHistoryGroup />}>
            <THead>
              {columns.map((key) => (
                <Th key={key}>
                  <HeaderWrapper>
                    <TText>{key}</TText>
                  </HeaderWrapper>
                </Th>
              ))}
            </THead>
            {log?.length === 0 || logError === true ? (
              <NoDataRow />
            ) : log && log.length > 0 ? (
              log.map((e) => (
                <PenTitrationRow
                  key={e.patientName + 'titration'}
                  event={e}
                  linkToPatient={linkToPatient}
                  t={t}
                />
              ))
            ) : (
              <ScrollTableLoading size={15} />
            )}
          </ScrollTableComponent>
        </TableWrapper>
      </Wrapper>
    </Container>
  )
}

import { faInbox } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const Absolute = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`
export const Relative = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const NoDataIconAndText = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  left: 50%;
  opacity: 0.4;
`
const NoDataIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  color: var(--text-lighter);
`
const NoDataText = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
  color: var(--text-lighter);
  font-family: inter;
`
interface CssStyleProps {
  style?: React.CSSProperties | null
}
export const NoDataComponent = ({ style }: CssStyleProps) => {
  const { t } = useTranslation()
  return (
    <Absolute>
      <Relative>
        <NoDataIconAndText style={style || {}}>
          <NoDataIcon icon={faInbox} />
          <NoDataText data-testid="noDataComponent">{t('NO DATA')}</NoDataText>
        </NoDataIconAndText>
      </Relative>
    </Absolute>
  )
}

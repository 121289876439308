import { t } from 'i18next'
import styled from 'styled-components'
import {
  convertProviderPatientProviderTypeToProviderPatientsType,
  getProviderInformationState,
  ProviderPatient
} from '../../../containers/Integrations/Integrations'
import { acknowledgePatientProvider } from '../../../core/api/acknowledge-patient-provider'
import { deletePatientProvider } from '../../../core/api/delete-patient-provider'
import { useSessionContext } from '../../../core/contexts/session-context'
import { SnackBar, SnackBarType } from '../../../libraries/Toast/SnackBar'
import { IntegrationTypeLabel } from '../../IntegrationTypeLabel/IntegrationTypeLabel'
import { ModalComponent } from '../../Modal/Modal'
import {
  getIntegrationType,
  getProviderTypeInfoText
} from '../../ProviderIntegrationModel/ProviderIntegrationModelFunctions/ProviderIntegrationModelFunctions'
import { IntegrationComponentStatus } from '../../ProviderIntegrationModel/StatusPage/IntegrationComponent/IntegrationComponent'
import {
  getBadgeStatus,
  getStatusText,
  StatusBadge
} from '../../StatusBadge/StatusBadge'
import { ProviderInformation } from '../ProviderInformation/ProviderInformation'
import { PMIStateValues } from '../ProviderPatients'

interface PatientApiModalProps {
  providerPatient: ProviderPatient
  setShowLinkPatientModal: () => void
  refresh: () => void
}
const Relative = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 4;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  padding: 2rem;
  width: 31.25rem;
  background-color: var(--white-color);
  border-radius: 2rem;
`
const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`
const Title = styled.span`
  font-size: 1.25rem;
  font-family: inter;
  font-weight: 700;
  color: var(--text-primary);
`

const Line = styled.div`
  width: 100%;
  height: 0.0625rem;
  background-color: var(--element-stroke);
`
const Footer = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  color: var(--text-medium);
  font-weight: 600;
`
const Button = styled.div`
  width: 100%;
  border-radius: 0.5rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--brand-primary-color);
  font-family: inter;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--white-color);
  cursor: pointer;
  &:hover {
    background-color: var(--brand-darker-color);
  }
`
const Disconnected = styled.span`
  color: var(--destructive);
  font-family: inter;
  font-size: 0.875ren;
  font-weight: 600;
  text-decoration-line: underline;
  cursor: pointer;
`
const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`
const BodyTitle = styled.span`
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 700;
  color: var(--text-medium);
  text-transform: uppercase;
`
const LocalInfo = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--text-primary);
`
export const PatientApiModal = ({
  providerPatient,
  setShowLinkPatientModal,
  refresh
}: PatientApiModalProps) => {
  const showProviderInformation = getProviderInformationState(providerPatient)
  const connected = providerPatient.pmiStateValue === PMIStateValues.CONNECTED
  const { clinic } = useSessionContext()
  const deleteDexcomIntegration = async () => {
    try {
      await deletePatientProvider({
        patientId: providerPatient.id
      })
      refresh()
    } catch (error) {
      SnackBar({
        type: SnackBarType.Error,
        message: t('Error occurred while deleting patient provider')
      })
      refresh()
    }
  }

  const acknowledgeDexcomIntegration = async () => {
    try {
      await acknowledgePatientProvider({
        patientId: providerPatient.id
      })
      refresh()
    } catch (error) {
      SnackBar({
        type: SnackBarType.Error,
        message: t('Error occurred while acknowledging patient provider')
      })
      refresh()
    }
  }
  return (
    <ModalComponent
      closeFun={setShowLinkPatientModal}
      closetestid="closePatientApiModal"
    >
      <Wrapper>
        <Header>
          <TitleWrapper>
            <Title data-testid="ProviderIntegrationModelTitle">
              {getProviderTypeInfoText(
                convertProviderPatientProviderTypeToProviderPatientsType(
                  providerPatient.providerType
                )
              )}
            </Title>
            <IntegrationTypeLabel
              type={getIntegrationType(
                convertProviderPatientProviderTypeToProviderPatientsType(
                  providerPatient.providerType
                )
              )}
            />
          </TitleWrapper>
          <StatusBadge
            text={getStatusText(
              connected
                ? IntegrationComponentStatus.running
                : IntegrationComponentStatus.disconnected,
              t
            )}
            state={getBadgeStatus(
              connected
                ? IntegrationComponentStatus.running
                : IntegrationComponentStatus.disconnected
            )}
          />
        </Header>
        <Body data-testid={'localInformation' + providerPatient.id}>
          <BodyTitle>{t('local information')}</BodyTitle>
          <LocalInfo>{providerPatient.localPatientName}</LocalInfo>
          <LocalInfo>{providerPatient.localPatientMrn}</LocalInfo>
        </Body>

        {showProviderInformation && (
          <ProviderInformation providerPatient={providerPatient} />
        )}
        <Line></Line>
        {connected ? (
          <Disconnected
            onClick={deleteDexcomIntegration}
            data-testid="deleteDexcomIntegration"
          >
            {t('Disconnect integration')}
          </Disconnected>
        ) : (
          <>
            <Footer>
              {t('patientRemovedIntegration', {
                integrationAlias: providerPatient.providerName,
                deploymentName: clinic?.name
              })}
            </Footer>
            <Button
              onClick={acknowledgeDexcomIntegration}
              data-testid="acknowledgeDexcomIntegration"
            >
              {t('acknowledge')}
            </Button>
          </>
        )}
      </Wrapper>
    </ModalComponent>
  )
}

import { faInbox } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { NoDataEmptyState } from '../NoDataEmptyState/NoDataEmptyState'

const Wrapper = styled.div`
  padding: 4rem;
`

export function DataTableEmpty() {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <NoDataEmptyState icon={faInbox}>{t('NO DATA')}</NoDataEmptyState>
    </Wrapper>
  )
}

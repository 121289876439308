import { faRotateRight } from '@fortawesome/pro-solid-svg-icons'
import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDataFilter } from '../../hooks/useDataFilter'
import { FilterFunctionButton } from '../TableComponents/FilterFunctionButton/FilterFunctionButton'
import {
  ApplicationUploadActivityType,
  Filter,
  Manufacturer
} from './constants'
import { DataFilter } from './DataFilter'

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--element-bg);
  border: 0.0625rem solid var(--text-lightest);
  padding: 2rem 4rem;
  border-radius: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  position: relative;
`

const FilterSection = styled.div`
  display: flex;
  gap: 4rem;
`

const TopRight = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
`

export function UploadActivityFilters({
  defaults,
  currentFilters,
  onChange
}: {
  defaults: { [key: string]: string[] }
  currentFilters: { [key: string]: string[] }
  onChange: (values: { [key: string]: string[] }) => void
}) {
  const { t } = useTranslation()
  const isFirstRender = useRef(true)

  const typesFilter = useDataFilter(
    Object.values(ApplicationUploadActivityType),
    defaults[Filter.types],
    currentFilters[Filter.types]
  )
  const manufacturersFilter = useDataFilter(
    Object.values(Manufacturer),
    defaults[Filter.manufacturers],
    currentFilters[Filter.manufacturers]
  )

  const filters = useMemo(() => {
    return {
      [Filter.types]: typesFilter.selected,
      [Filter.manufacturers]: manufacturersFilter.selected
    }
  }, [typesFilter.selected, manufacturersFilter.selected])

  const resetFilters = () => {
    typesFilter.reset()
    manufacturersFilter.reset()
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    onChange(filters)
  }, [filters, onChange])

  return (
    <FiltersWrapper>
      <FilterSection>
        <DataFilter title={t('Context')} filter={typesFilter} slug="types" />
        <DataFilter
          title={t('Manufacturers')}
          filter={manufacturersFilter}
          slug="manufacturers"
        />
      </FilterSection>
      <TopRight>
        <FilterFunctionButton
          handleClick={resetFilters}
          icon={faRotateRight}
          text={t('Reset to defaults')}
        />
      </TopRight>
    </FiltersWrapper>
  )
}

import { faCloud } from '@fortawesome/pro-regular-svg-icons'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { ApplicationUploadActivityType, UploadContext } from '../../constants'
import { Icon, Text } from '../UploadContext'
import { UploadContextGroup } from '../UploadContextGroup/UploadContextGroup'

export const CloudUploadContext = ({
  context
}: {
  context: Extract<
    UploadContext,
    {
      type: ApplicationUploadActivityType.Cloud
    }
  >
}) => {
  const { t } = useTranslation()
  const group: ReactNode[] = [
    <Icon icon={faCloud}></Icon>,
    <Text>{t('Cloud')}</Text>
  ]
  if (context.providerName !== null) {
    group.push(<Text>{context.providerName}</Text>)
  }

  return <UploadContextGroup type={context.type} group={group} />
}

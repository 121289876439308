import config from '../config'
import { AxiosRequest } from './axios-request'

export async function putPatient(
  patientId: string,
  name: string
): Promise<any> {
  const response = await AxiosRequest.create().put(
    config.API_URL + `/patients/${patientId}`,
    { name }
  )
  return response.data as any
}

import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const HyperLink = styled(Link)`
  color: var(--text-primary);
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: var(--brand-primary-color);
  }
`

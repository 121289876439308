import { FitbitObject } from '../../../components/ActivityComponents/interfaces'
import {
  AbstractDeviceEvent,
  DeviceEventType
} from '../../../components/DeviceEvent/Interfaces'
import { WrapsDay } from '../../../components/Graphs/BolusGraph/BolusGraph'
import { DotSizeEnum } from '../../../components/Graphs/GlucoseGraph/GlucoseGraph'
import {
  MergedNovoPenEventValue,
  NovoPenEventValue
} from '../../../components/Graphs/Insights/interfaces'
import { DailyActivity } from '../../Overview/Overview'

export interface BellProps {
  notificationAmount: number
}

export interface TotalInsulinObject {
  total: number
  bolusTotal: number
  basalTotal: number
  bolusRelative: number
  basalRelative: number
  basalAutomated: number
  bolusAutomated: number
  bolusAutoPercentage: number | null
  basalAutoPercentage: number | null
  totalAutoPercentage: number | null
  daysWithData: number
  selectedDays: number
}
export interface AverageGlucoseObject {
  average: number
  eventCount: number
  units: string
}
export enum Thresholds {
  low = 'low',
  high = 'high'
}
export interface OutOfRangeObject {
  value: Thresholds
  threshold: number
}
export interface TimeInAutomationObject {
  automation: number
  manual: number
  timeInAutomation: number
  timeInManual: number
  totalTime: number
}

export enum BasalChangeEvents {
  NoChange = 'NoChange',
  Temp = 'Temp',
  Suspended = 'Suspended',
  Scheduled = 'Scheduled',
  Automatic = 'Automatic'
}
export enum ActivityProfile {
  Sleeping = 'Sleeping',
  Exercising = 'Exercising',
  Normal = 'Normal'
}
export interface ActivityInfo {
  activityProfile: ActivityProfile
  start: string
  end: string
}
export interface BasalEventsObject {
  start: string
  end: string
  units: number
  deliveryMode: BasalChangeEvents
  reasonForSuspension: 'automatic' | 'manual' | null
}
export interface InterruptedBolusValues {
  difference: number
  expectedNormal: number
  normal: number
}
export interface BolusEventsObject {
  activeInsulin: number | null
  carbs: number | null
  glucose: number | null
  insulinCarbRatio: number | null
  insulinSensitivity: number | null
  recommended: number | null
  automatic: boolean
  time: string
  units: number | null
  userChange: number | null
  interruptedBolusValues: InterruptedBolusValues | null
  wrapsDay: WrapsDay | null
  bgTarget: { low: number | null; high: number | null; target: number | null }
  extendedValues: {
    duration: number
    extended: number
    extendedRatio: number
    initial: number
    initialRatio: number
  } | null
}
export interface GlucoseEventsObject {
  device: string | null
  localTime: string
  outOfRange: OutOfRangeObject | null
  time: string
  type: string
  subType: string | null
  units: string
  value: number
  dotSize: DotSizeEnum
}
interface RangeObject {
  percentage: number
  hours: number
  minutes: number
  count: number
}
export interface TimeInRangeObject {
  range1: RangeObject
  range2: RangeObject
  range3: RangeObject
  range4: RangeObject
  range5: RangeObject
  timeInTightRange: RangeObject
}

export enum AlarmTypes {
  unknown = 'unknown',
  high = 'high',
  low = 'low',
  rise = 'rise',
  fall = 'fall',
  outOfRange = 'outOfRange',
  urgentLow = 'urgentLow',
  urgentLowSoon = 'urgentLowSoon',
  noReadings = 'noReadings',
  fixedLow = 'fixedLow'
}
export enum StatusTypes {
  resumed = 'resumed',
  suspended = 'suspended'
}

export enum PrimeTypes {
  cannula = 'cannula',
  tubing = 'tubing'
}
export enum DeviceEventSubTypes {
  alarm = 'alarm',
  calibration = 'calibration',
  prime = 'prime',
  reservoirChange = 'reservoirChange',
  status = 'status',
  timeChange = 'timeChange'
}

export interface PumpActivity {
  timeInSleep: number
  timeInExercise: number
  sleep: number
  exercise: number
}
export interface AutoBolusEvent {
  device: null | string
  localTime: string
  rate: number
  time: string
  type: 'bolusauto'
}
export enum insulinDisplays {
  PenInjections = 'PenInjections',
  Empty = 'Empty',
  BasalAndBolus = 'BasalAndBolus',
  Failed = 'Failed'
}
export interface PenInjectionsWidgetObject {
  totalBasalInjections: number | null
  totalBasalVolume: number | null
  totalBolusInjections: number | null
  totalBolusVolume: number | null
}
export interface DaysObject {
  day: string
  autoBolusEvents: Array<AutoBolusEvent> | null
  averageGlucose: AverageGlucoseObject | null
  glucoseEvents: Array<GlucoseEventsObject> | null
  bolusEvents: Array<BolusEventsObject> | null
  basalEvents: Array<BasalEventsObject> | null
  deviceEvents: Array<AbstractDeviceEvent> | null
  totalInsulin: TotalInsulinObject | null
  timeInRange: TimeInRangeObject | null
  totalCarbs: number | null
  sensorUsage: number | null
  timeInAutomation: TimeInAutomationObject | null
  pumpActivity: PumpActivity | null
  timeInActivity: DailyActivity | null
  insulinDisplay: insulinDisplays
  penInjections: (NovoPenEventValue | MergedNovoPenEventValue)[] | null
  penInjectionsWidget: PenInjectionsWidgetObject | null
  activitySlices: Array<ActivityInfo>
  fitbitWidget: FitbitObject
}

export interface FitbitActivityMaxes {
  azm: number
  calories: number
  steps: number
  distance: number
  floors: number
}
export interface DataObject {
  name: string
  days: Array<DaysObject>
  maxTotalInsulin: number | null
  maxBolusInsulin: number | null
  maxBasal: number | null
  maxPenInjection: number | null
  deviceEventTypes: Array<DeviceEventType>
  fitbitActivityMaxes: FitbitActivityMaxes
}

export enum SleepValues {
  awake = 'awake',
  rem = 'rem',
  light = 'light',
  deep = 'deep',
  restless = 'restless',
  asleep = 'asleep'
}

export interface FitbitLevelCalculations {
  percentage: number
  hours: number
  minutes: number
}

export enum FitbitSleepType {
  classic = 'classic',
  stages = 'stages'
}

export interface FitbitClassicSleep {
  awake: FitbitLevelCalculations
  restless: FitbitLevelCalculations
  asleep: FitbitLevelCalculations
}

export interface FitbitStagesSleep {
  awake: FitbitLevelCalculations
  rem: FitbitLevelCalculations
  light: FitbitLevelCalculations
  deep: FitbitLevelCalculations
}
export type FitbitSleepSlice =
  | {
      start: string
      end: string
      hours: number
      minutes: number
      type: FitbitSleepType.classic
      classic: FitbitClassicSleep
      stages: null
    }
  | {
      start: string
      end: string
      hours: number
      minutes: number
      type: FitbitSleepType.stages
      classic: null
      stages: FitbitStagesSleep
    }

export interface FitbitSleepWidget {
  slices: FitbitSleepSlice[]
}

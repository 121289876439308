import config from '../config'
import { AxiosRequest } from './axios-request'

export async function getWards(options: object = {}): Promise<any> {
  const response = await AxiosRequest.create().get(
    config.API_URL + '/wards',
    options
  )
  return response.data
}

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { formatNumber } from '../../../../helpers/helpers'
import { BasalInjectionWidgetData } from '../BasalInjection'

const Wrapper = styled.div`
  width: 100%;
  padding: 0.75rem 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: var(--element-bg);
  gap: 0.5rem;
`

const Text = styled.span`
  font-family: inter;
  color: var(--text-primary);
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
`
const ValueAndUnit = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  gap: 0.125rem;
`
const ValueText = styled(Text)`
  font-size: 1rem;
  font-weight: 700;
  color: var(--basal-color);
  line-height: 0.75rem;
`
const UnitText = styled(Text)`
  font-size: 0.625rem;
  font-weight: 700;
  color: var(--text-lighter);
  line-height: 0.625rem;
`
export const AvgForDaysInjected = ({
  data
}: {
  data: BasalInjectionWidgetData
}) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Text>{t('Avg. daily basal dose')}</Text>
      <ValueAndUnit>
        <ValueText data-testid="basalInjectionDaysWithInjectionsAvgBasal">
          {formatNumber({
            value: data.daysWithInjectionsAvgBasal,
            decimals: 0
          })}
        </ValueText>
        <UnitText>U total</UnitText>
      </ValueAndUnit>
      <Text>{t('for days with registered doses')}</Text>
    </Wrapper>
  )
}

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FitbitActivityMaxes } from '../../../containers/StackedDaily/Interfaces/Interfaces'
import { formatNumber } from '../../../helpers/helpers'
import { getInfoLogo } from '../../DataWidget/Shared/Header/Header'
import { WidgetState } from '../../DataWidget/Shared/Interfaces/Interfaces'
import { getWidgetState } from '../../DataWidget/Shared/Wrapper/Wrapper'
import { Modal } from '../../Modal/Modal'
import { ActivityIcon, ItemIcon } from '../ActivityIcon/ActivityIcon'
import { ActivityModal } from '../ActivityModal/ActivityModal'
import {
  FitbitAverageItem,
  FitbitAverageWidget,
  FitbitFineGrainedType,
  FitbitObject
} from '../interfaces'

export enum ActivityWidgetType {
  daily,
  avg
}
const ItemWrapper = styled.div<{ $type: ActivityWidgetType }>`
  display: flex;
  min-width: ${(props) =>
    props.$type === ActivityWidgetType.avg ? '7rem' : '7.5rem'};
  padding: ${(props) =>
    props.$type === ActivityWidgetType.avg ? '0.75rem 1rem' : '0.5rem'};
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 0.25rem;
  background-color: var(--element-bg);
  flex-direction: ${(props) =>
    props.$type === ActivityWidgetType.avg ? 'column' : 'row'};
  gap: ${(props) =>
    props.$type === ActivityWidgetType.avg ? '0.75rem' : '0rem'};
`
const Title = styled.span`
  font-family: inter;
  color: var(--text-primary);
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1rem;
`

const Wrapper = styled.div<{ $type: ActivityWidgetType }>`
  display: flex;
  width: ${(props) =>
    props.$type === ActivityWidgetType.avg ? '18.25rem' : '17.625rem;'};
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  border-radius: 0.5rem;
  &:hover {
    &.daily {
      cursor: pointer;
      ${ItemWrapper} {
        background-color: var(--brand-primary-8-color);
      }
      ${Title} {
        color: var(--brand-primary-color);
      }
      ${ItemIcon} {
        color: var(--brand-primary-color);
      }
    }
  }
  border-radius: ${(props) =>
    props.$type === ActivityWidgetType.avg ? '0.5rem' : '0rem'};
  border: ${(props) =>
    props.$type === ActivityWidgetType.avg
      ? '0.0625rem solid var(--text-lightest)'
      : 'none'};
  background-color: ${(props) =>
    props.$type === ActivityWidgetType.avg ? 'var(--white-color)' : 'none'};
  padding: ${(props) =>
    props.$type === ActivityWidgetType.avg ? '0.75rem 1rem 1rem' : '0'};
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.375rem;
`

const Body = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.25rem;
  align-self: stretch;
  flex-wrap: wrap;
`

const ItemIconWrapper = styled.div`
  display: flex;
  width: 1rem;
  height: 1rem;
  justify-content: center;
  align-items: center;
`

const ItemValueWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.125rem;
`

const ItemValue = styled.span`
  color: var(--green);
  text-align: right;
  font-family: inter;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1rem;
`
const ItemUnit = styled.span`
  color: var(--text-lighter);
  text-align: right;
  font-family: inter;
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 0.625rem;
`

type ActivityWidgetProps =
  | {
      type: ActivityWidgetType.daily
      data: FitbitObject | null
      fitbitActivityMaxes: FitbitActivityMaxes
      day: string
      state: WidgetState
    }
  | {
      type: ActivityWidgetType.avg
      data: FitbitAverageWidget | null
      fitbitActivityMaxes: undefined
      day: undefined
      state: WidgetState
    }

interface ActivityItemProps {
  type: ActivityWidgetType
  item: FitbitAverageItem
}

export const ActivityWidget = ({
  data,
  fitbitActivityMaxes,
  day,
  type,
  state
}: ActivityWidgetProps) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const actualState: WidgetState = getWidgetState(state, data)
  const TitleText =
    type === ActivityWidgetType.avg ? t('Avg. Daily Activity') : t('Activity')
  const closeModal = () => {
    setShowModal(false)
  }
  const ActivityItem = ({ type, item }: ActivityItemProps) => {
    return (
      <ItemWrapper $type={type}>
        <ItemIconWrapper>
          <ActivityIcon type={item.type} />
        </ItemIconWrapper>
        <ItemValueWrapper>
          <ItemValue>
            {formatNumber({
              value: item.total,
              decimals: item.type === FitbitFineGrainedType.distance ? 2 : 0
            })}
          </ItemValue>
          <ItemUnit>{t(item.rightLabel ?? '')}</ItemUnit>
        </ItemValueWrapper>
      </ItemWrapper>
    )
  }
  return (
    <>
      {data && type === ActivityWidgetType.daily && showModal && (
        <Modal isOpen={showModal}>
          <ActivityModal
            data={data}
            fitbitActivityMaxes={fitbitActivityMaxes}
            closeFun={closeModal}
            day={day}
          />
        </Modal>
      )}
      <Wrapper
        $type={type}
        onClick={() => setShowModal(true)}
        className={type === ActivityWidgetType.daily ? 'daily' : ''}
      >
        <Header>
          <Title>{TitleText}</Title>
          {type === ActivityWidgetType.avg &&
            getInfoLogo(
              [
                {
                  title: TitleText,
                  body: t(
                    'The average daily activity for the selected period. Days where the Fitbit device has not been worn will count as 0 (zero) for each activity category.'
                  )
                }
              ],
              actualState
            )}
        </Header>
        <Body>
          {data ? (
            <>
              <ActivityItem type={type} item={data.azm} />
              <ActivityItem type={type} item={data.calories} />
              <ActivityItem type={type} item={data.steps} />
              <ActivityItem type={type} item={data.distance} />
            </>
          ) : null}
        </Body>
      </Wrapper>
    </>
  )
}

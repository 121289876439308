export const findDiff = (string1: string, string2: string) => {
  let diff = ''
  let pos = null
  string2.split('').forEach((val, i) => {
    if (val !== string1.charAt(i)) {
      diff += val
      pos = i
    }
  })
  return { difference: diff, position: pos }
}

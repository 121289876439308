import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const InfoWrapper = styled.div`
  display: flex;
  padding: 1.5rem;
  align-items: flex-start;
  gap: 0.75rem;
  flex: 1 0 0;
  border-radius: 0.5rem;
  background-color: var(--element-bg);
`
const InfoCircle = styled(FontAwesomeIcon)`
  font-size: 1.125rem;
  color: var(--blue);
  line-height: 1.25rem;
`
const InfoText = styled.span`
  white-space: pre-wrap;
  color: var(--text-primary);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
`

export const InfoBox = ({
  paragraph,
  testid
}: {
  paragraph: string
  testid: string
}) => {
  const { t } = useTranslation()
  return (
    <InfoWrapper data-testid={testid}>
      <InfoCircle icon={faInfoCircle} />
      <InfoText>{paragraph}</InfoText>
    </InfoWrapper>
  )
}

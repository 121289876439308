import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  ScatterChart,
  XAxis,
  YAxis
} from 'recharts'
import styled from 'styled-components'
import { FitbitActivityMaxes } from '../../../containers/StackedDaily/Interfaces/Interfaces'
import { referenceLines } from '../../Graphs/BasalGraph/BasalGraph'
import { Modal } from '../../Modal/Modal'
import { ActivityModal } from '../ActivityModal/ActivityModal'
import {
  FitbitFineGrainedType,
  FitbitObject,
  FitbitObjectItem
} from '../interfaces'
import { ActivityGraphNoData } from './ActivityGraphNoData/ActivityGraphNoData'
import { ActivityLabels } from './ActivityLabels/ActivityLabels'
import { CustomActivityBar } from './CustomActivityBar/CustomActivityBar'
import { GraphTooltip, GraphTooltipProps } from './GraphTooltip/GraphTooltip'

export enum ActivityGraphSize {
  small,
  big
}

const Wrapper = styled.div<{ $size: ActivityGraphSize; $first: boolean }>`
  position: relative;
  height: ${(props) =>
    props.$size === ActivityGraphSize.big
      ? props.$first
        ? 'calc(9.9375rem + 25px)'
        : '9.9375rem'
      : '1.75rem'};
  width: 100%;
`

export const CustomizedAxisTick = (props: any) => {
  return (
    <g
      transform={`translate(${
        props.payload.value === 24 ? props.x + 8 : props.x
      },${props.y})`}
    >
      <text
        x={0}
        y={0}
        textAnchor={props.payload.value === 24 ? 'end' : 'start'}
        fill="var(--text-lighter)"
        fontSize="0.75rem"
        fontWeight="600"
        fontFamily="inter"
      >
        {props.payload.value === 1 ? 0 : props.payload.value}:00
      </text>
    </g>
  )
}

const xDomainMax = 24
interface ActivityGraphProps {
  allData?: FitbitObject | null
  data: FitbitObjectItem | null
  fitbitActivityMaxes: FitbitActivityMaxes
  size: ActivityGraphSize
  day: string
  idx: number
  graphIsClickable: boolean
  hypo?: boolean
}

export const ActivityGraph = ({
  allData,
  data,
  fitbitActivityMaxes,
  size,
  day,
  idx,
  graphIsClickable,
  hypo
}: ActivityGraphProps) => {
  const [hovered, setHovered] = useState<boolean>(false)
  const [showModal, setShowModal] = useState(false)
  const [active, setActive] = useState<GraphTooltipProps | null>(null)

  const { t } = useTranslation()
  const graphHasData = data && data.total > 0
  const getMaxYValue = () => {
    if (data) {
      if (data.type === FitbitFineGrainedType.distance) {
        const num = (fitbitActivityMaxes[data.type] ?? 100).toFixed(2)

        const halfNum = ((fitbitActivityMaxes[data.type] ?? 100) / 2).toFixed(2)
        return { num: Number(num), halfNum: Number(halfNum) }
      } else {
        const num =
          2 *
          Math.round((data ? fitbitActivityMaxes[data.type] ?? 100 : 100) / 2)
        const halfNum = num / 2

        return { num, halfNum }
      }
    } else return { num: 100, halfNum: 50 }
  }

  const maxYValues = getMaxYValue()
  const first = idx === 0

  const closeModal = () => {
    setShowModal(false)
    setHovered(false)
  }
  const openModal = () => {
    if (!graphIsClickable) return
    setHovered(false)
    setActive(null)
    setShowModal(true)
  }

  return (
    <>
      {allData && graphIsClickable && showModal && (
        <Modal isOpen={showModal}>
          <ActivityModal
            data={allData}
            fitbitActivityMaxes={fitbitActivityMaxes}
            closeFun={() => closeModal()}
            day={day}
          />
        </Modal>
      )}

      <Wrapper
        $size={size}
        $first={first}
        style={
          size === ActivityGraphSize.small
            ? {
                marginLeft: '0px',
                cursor: graphIsClickable ? 'pointer' : 'default'
              }
            : { width: 'calc(100% + 15px)', marginLeft: '-15px' }
        }
        onMouseEnter={() => {
          setHovered(true)
          setActive(null)
        }}
        onMouseLeave={() => {
          setHovered(false)
          setActive(null)
        }}
        onClick={openModal}
      >
        {!graphHasData && <ActivityGraphNoData size={size} />}

        {active && (
          <GraphTooltip
            width={active.width}
            height={active.height}
            data={active.data}
          />
        )}

        {data && <ActivityLabels data={data} first={first} size={size} />}

        <ResponsiveContainer>
          <ScatterChart
            margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
            style={graphIsClickable ? { cursor: 'pointer' } : {}}
          >
            <ReferenceArea
              x1={0}
              x2={xDomainMax}
              y1={0}
              y2={maxYValues.num}
              fill={
                hovered && graphIsClickable
                  ? 'var(--element-bg-dark)'
                  : 'var(--element-bg)'
              }
              stroke={'var(--text-lightest)'}
              radius={[0, 4, 4, 0]}
              fillOpacity={1}
            />
            {hypo === true && (
              <ReferenceLine
                x={14.4}
                stroke="var(--veryLow-color)"
                key={`hypoLine`}
              />
            )}
            {referenceLines('stackedDaily')}
            <XAxis
              type="number"
              name="Time"
              ticks={[0, 3, 6, 9, 12, 15, 18, 21, 24]}
              tick={first ? <CustomizedAxisTick /> : false}
              domain={[0, xDomainMax]}
              dataKey="x"
              tickLine={false}
              orientation={'top'}
              axisLine={false}
              tickMargin={5}
              height={first ? 25 : 0}
            ></XAxis>
            <YAxis
              stroke="#979797"
              fontSize="0.875rem"
              dataKey="y"
              name="Glucose"
              interval={0}
              tick={{
                fill: 'var(--text-lighter)',
                fontSize: '0.75rem',
                fontWeight: '600',
                fontFamily: 'inter'
              }}
              tickLine={{ opacity: 1, stroke: 'var(--text-lightest)' }}
              ticks={
                size === ActivityGraphSize.small
                  ? [0, maxYValues.num]
                  : [0, maxYValues.halfNum, maxYValues.num]
              }
              domain={[0, maxYValues.num]}
              axisLine={false}
              width={15}
            />
            {graphHasData &&
              data.timeSlices.map((event, index) => {
                return (
                  <ReferenceArea
                    key={event.end + event.start + event.total + idx}
                    x1={index + 0.5}
                    y1={0}
                    y2={
                      event.total > maxYValues.num
                        ? maxYValues.num
                        : event.total
                    }
                    fill={
                      event.start === active?.data.start
                        ? 'var(--green-darker)'
                        : 'var(--green)'
                    }
                    shape={
                      <CustomActivityBar
                        graphHovered={hovered}
                        barIndex={index + 1}
                        index={index}
                        graphIndex={idx}
                        size={size}
                        graphIsClickable={graphIsClickable}
                        data={data}
                        event={event}
                        onHover={setActive}
                      />
                    }
                  />
                )
              })}
          </ScatterChart>
        </ResponsiveContainer>
      </Wrapper>
    </>
  )
}

import dayjs from 'dayjs'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { defaultPeriod } from '../../../constants/defaultPeriod'
import { getPatientSummary } from '../../../core/api/get-patient-summary'
import { usePatientLoadingContext } from '../../../core/contexts/patient-loading-context'
import { usePeriodContext } from '../../../core/contexts/period-context'
import { Patient } from '../../../core/entities/patient.entity'
import { devicesObject } from '../../DeviceList/DeviceList'
import { defaultDevicesObject } from '../../IntegrationComponent/IntegrationComponent'
import { timeDataObject } from '../../TimeSelector/TimeSelector'
import { CurrentDevices } from '../CurrentDevicesComponent/CurrentDevicesComponent'
import { TimePeriodComponent } from '../TimePeriodComponent/TimePeriodComponent'

export interface PeriodObject {
  lengthOfPeriod: number
  endDate: Date
  deviceID: string | null
}

interface DevicesAndPeriodProps {
  patient: Patient
  setViewedPatientIsBlinded: Dispatch<SetStateAction<boolean>>
}

export const getLatestData = (data: timeDataObject[]) => {
  const minIndex = data.reduce(
    (index, value, i, array) =>
      dayjs(new Date()).diff(dayjs(value.end)) <
      dayjs(new Date()).diff(dayjs(array[index].end))
        ? i
        : index,
    0
  )
  return minIndex
}
export const DevicesAndPeriod = ({
  patient,
  setViewedPatientIsBlinded
}: DevicesAndPeriodProps) => {
  const { setPeriod } = usePeriodContext()
  const [timeData, setTimeData] = useState<Array<timeDataObject> | null>(null)
  const [devices, setDevices] = useState<null | Array<devicesObject>>([
    defaultDevicesObject
  ])
  const [newestDeviceData, setNewestDeviceData] =
    useState<null | Array<string>>([])
  const [period, setPeriodState] = useState<null | PeriodObject>({
    lengthOfPeriod: defaultPeriod,
    endDate: new Date(),
    deviceID: null
  })
  const [isEmpty, setIsEmpty] = useState<boolean>(false)
  const [oldData, setOldData] = useState<boolean>(false)
  const { patientLoading, setPatientLoading } = usePatientLoadingContext()

  useEffect(() => {
    const controller = new AbortController()
    setViewedPatientIsBlinded(false)
    setOldData(false)
    const getSummary = async () => {
      try {
        const summary = await getPatientSummary(patient.patientId, {
          signal: controller.signal
        })
        setDevices(
          summary.devices.length === 0
            ? null
            : summary.devices.map((device: any) => {
                return {
                  ...defaultDevicesObject,
                  title: device.device,
                  serial: device.serialnumber,
                  time: dayjs(device.latestData).fromNow(),
                  patientDeviceIds: device.patientDeviceIds,
                  uploadOrigin: device.uploadOrigin,
                  deviceDisplayName: device.deviceDisplayName,
                  device: device.device
                }
              })
        )

        setNewestDeviceData(
          summary.newestDevices.length > 0
            ? summary.newestDevices.map((device) => device.deviceDisplayName)
            : null
        )

        setTimeData(summary.timeslices.length === 0 ? null : summary.timeslices)
        let PeriodObject =
          summary.timeslices.length === 0
            ? null
            : {
                lengthOfPeriod: defaultPeriod,
                endDate: dayjs(
                  summary.timeslices[getLatestData(summary.timeslices)].end
                ).toDate(),
                deviceID: null
              }

        if (summary.emptyPatient) {
          setIsEmpty(true)
          PeriodObject = {
            lengthOfPeriod: defaultPeriod,
            endDate: new Date(),
            deviceID: null
          }
        }

        setViewedPatientIsBlinded(summary.blinded)
        setOldData(summary.oldData)
        if (summary.timeslices.length > 0 && !summary.emptyPatient) {
          setIsEmpty(false)
        }
        setPeriodState(PeriodObject)
        setPeriod(PeriodObject)
      } catch (error) {
        console.log(error)
        setDevices(null)
      }
    }
    setPatientLoading(true)
    getSummary()
  }, [
    patient,
    patient.patientId,
    setPatientLoading,
    setPeriod,
    setViewedPatientIsBlinded
  ])

  return (
    <>
      {patientLoading ? null : (
        <>
          <TimePeriodComponent
            timeData={timeData}
            setPeriodState={setPeriodState}
            period={period}
            emptyPatient={isEmpty}
            oldData={oldData}
          />
          <CurrentDevices
            period={period}
            setPeriod={setPeriod}
            setPeriodState={setPeriodState}
            devices={devices}
            newestDeviceData={newestDeviceData}
          />
        </>
      )}
    </>
  )
}

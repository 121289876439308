import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SelectHTMLAttributes } from 'react'
import styled from 'styled-components'

const Wrapper = styled.span`
  position: relative;
`

const SelectElement = styled.select`
  width: 100%;
  padding: 1rem 1.5rem 1rem 1rem;
  border: 0.0625rem solid var(--text-lightest);
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  font-family: inter;
  box-sizing: border-box;
  -webkit-appearance: none;
  &:hover {
    color: var(--brand-primary-color);
  }
  cursor: pointer;
`

export const SelectArrow = styled(FontAwesomeIcon)`
  color: var(--text-lighter);
  font-size: 0.875rem;
  line-height: 1rem;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
`

export function Select(props: SelectHTMLAttributes<HTMLSelectElement>) {
  return (
    <Wrapper>
      <SelectElement {...props} />
      <SelectArrow icon={faCaretDown} />
    </Wrapper>
  )
}

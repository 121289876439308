import {
  faFire,
  faForward,
  faLocationDot,
  faShoePrints,
  faStairs
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { FitbitFineGrainedType } from '../interfaces'

export const ItemIcon = styled(FontAwesomeIcon)`
  color: var(--text-primary);
  font-size: 0.75rem;
  line-height: 1rem;
`

interface ActivityIconProps {
  type: FitbitFineGrainedType
}
export const ActivityIcon = ({ type }: ActivityIconProps) => {
  const getActivityItemIcon = (type: FitbitFineGrainedType) => {
    switch (type) {
      case FitbitFineGrainedType.azm:
        return faForward
      case FitbitFineGrainedType.steps:
        return faShoePrints
      case FitbitFineGrainedType.distance:
        return faLocationDot
      case FitbitFineGrainedType.calories:
        return faFire
      case FitbitFineGrainedType.floors:
        return faStairs
    }
  }
  return <ItemIcon icon={getActivityItemIcon(type)}></ItemIcon>
}

import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { Dispatch, MutableRefObject, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CheckBox, CheckMark } from '../../../containers/Trends/Trends'
import {
  PeriodPosition,
  ScreenShotIconSize,
  ScreenshotComponent
} from '../../Screenshot/ScreenshotComponent/ScreenshotComponent'
import { TrendsButtonComponent } from '../TrendsButtonComponent/TrendsButtonComponent'

interface AllButtonsProps {
  daysState: boolean[] | null
  setDaysState: Dispatch<SetStateAction<boolean[]>>
  graphState: boolean[] | null
  setGraphState: Dispatch<SetStateAction<boolean[]>>
  refs: {
    wrapper: MutableRefObject<HTMLDivElement | null> | null
  }
}

const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`
const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
`
export enum checkBoxType {
  weekday,
  weekend,
  single
}
export const checkDays = (
  state: Array<boolean>,
  type: checkBoxType,
  index?: number
) => {
  const newState = [...state]
  if (type === checkBoxType.single && index !== undefined) {
    newState[index] = !newState[index]
    return newState
  }
  const checkType = type === checkBoxType.weekday
  const startIndex = checkType ? 0 : 5
  const endIndex = checkType ? 5 : 7
  const check = state.slice(startIndex, endIndex).includes(false)
  for (let i = startIndex; i < endIndex; i++) {
    newState[i] = !!check
  }
  return newState
}

const days = [...Array(11).keys()]
export const TrendsButtons = ({
  daysState,
  setDaysState,
  graphState,
  setGraphState,
  refs
}: AllButtonsProps) => {
  const { t } = useTranslation()
  return (
    <Buttons>
      <ButtonSection>
        <CheckBox
          hover={daysState !== null}
          data-testid="checkBoxWeekdays"
          onClick={
            daysState
              ? () => setDaysState(checkDays(daysState, checkBoxType.weekday))
              : () => {
                  return null
                }
          }
          active={!daysState?.slice(0, 5).includes(false)}
          style={{ marginRight: '0.25rem' }}
        >
          <CheckMark icon={faCheck} />
        </CheckBox>
        {days.slice(0, 5).map((day) => (
          <TrendsButtonComponent
            key={day}
            index={day}
            state={daysState}
            setState={setDaysState}
          />
        ))}
        <CheckBox
          data-testid="checkBoxWeekend"
          hover={daysState !== null}
          onClick={
            daysState
              ? () => setDaysState(checkDays(daysState, checkBoxType.weekend))
              : () => {
                  return null
                }
          }
          active={!daysState?.slice(5, 7).includes(false)}
          style={{ marginLeft: '0.75rem', marginRight: '0.25rem' }}
        >
          <CheckMark icon={faCheck} />
        </CheckBox>
        {days.slice(5, 7).map((day) => (
          <TrendsButtonComponent
            key={day}
            index={day}
            state={daysState}
            setState={setDaysState}
          />
        ))}
      </ButtonSection>
      <ButtonSection>
        {days.slice(0, 4).map((day) => (
          <TrendsButtonComponent
            key={day}
            index={day}
            state={graphState}
            setState={setGraphState}
          />
        ))}
        <ScreenshotComponent
          size={ScreenShotIconSize.medium}
          showIcon={true}
          index={0}
          refs={refs}
          periodPlacement={{
            position: PeriodPosition.bottomLeft,
            margin: { bottom: 0, left: 8 }
          }}
          wrapperPadding={{ width: 48, height: 32 }}
          border={true}
        />
      </ButtonSection>
    </Buttons>
  )
}

import { ReactNode } from 'react'
import { HyperLink } from '../HyperLink/HyperLink'

export function PatientLink({
  patientId,
  children
}: {
  patientId: string
  children: ReactNode
}) {
  return (
    <HyperLink to={`/portal/patients/${patientId}/insights`}>
      {children}
    </HyperLink>
  )
}

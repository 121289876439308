import { Dispatch, SetStateAction } from 'react'
import { Location, matchPath } from 'react-router-dom'
import { Clinic } from '../../core/entities/clinic.entity'
import { Patient } from '../../core/entities/patient.entity'
import { ClinicNameComponent } from '../Header/ClinicNameComponent/ClinicNameComponent'
import { DevicesAndPeriod } from '../Header/DevicesAndPeriod/DevicesAndPeriod'
import { NameComponent } from '../Header/NameComponent/NameComponent'
import { PatientSearch } from '../PatientSearch/PatientSearch'

interface GetLeftContentProps {
  location: Location
  clinic: Clinic | null
  patient: Patient | null
  viewedPatientIsBlinded: boolean
  setViewedPatientIsBlinded: Dispatch<SetStateAction<boolean>>
}
export const getLeftContent = ({
  location,
  patient,
  clinic,
  viewedPatientIsBlinded,
  setViewedPatientIsBlinded
}: GetLeftContentProps) => {
  const clinicIsActive = matchPath('/portal/clinic/*', location.pathname)

  if (location.pathname === '/portal/patients/search') return <></>
  else
    return (
      <>
        <PatientSearch />
        {clinicIsActive !== null && <ClinicNameComponent name={clinic?.name} />}
        {patient !== null && (
          <>
            <NameComponent
              patient={patient}
              blinded={viewedPatientIsBlinded}
            ></NameComponent>
            <DevicesAndPeriod
              patient={patient}
              setViewedPatientIsBlinded={setViewedPatientIsBlinded}
            />
          </>
        )}
      </>
    )
}

import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons'
import { ReactNode } from 'react'
import styled from 'styled-components'
import { ApplicationUploadActivityType } from '../../constants'
import { Seperator, Wrapper } from '../UploadContext'

interface UploadContextGroupProps {
  type: ApplicationUploadActivityType
  group: ReactNode[]
}

export const InnerDiv = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  &:last-child {
    padding-right: 0.75rem;
  }
`

export const UploadContextGroup = ({
  type,
  group
}: UploadContextGroupProps) => {
  return (
    <Wrapper>
      {group.map((item, idx) => (
        <InnerDiv key={type + idx}>
          {idx > 1 && <Seperator icon={faCircleSmall} />}
          {item}
        </InnerDiv>
      ))}
    </Wrapper>
  )
}

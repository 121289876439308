import dayjs from 'dayjs'
import { t } from 'i18next'
import styled from 'styled-components'
import { formatNumber } from '../../../../helpers/helpers'
import { Header, Wrapper } from '../../../BasalTooltip/BasalTooltip'
import { FitbitFineGrainedSlice, FitbitFineGrainedType } from '../../interfaces'

export interface FitbitFineGrainedSliceWithType extends FitbitFineGrainedSlice {
  unit: string | null
  type: FitbitFineGrainedType
}

export interface GraphTooltipProps {
  width: number
  height: number
  data: FitbitFineGrainedSliceWithType
}

const HeaderTextWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
`
const HeaderText = styled.span`
  color: var(--text-lighter);
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
`
const MainHeaderText = styled(HeaderText)`
  color: var(--text-primary);
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
`
const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  gap: 0.25rem;
  margin-bottom: 0.5rem;
`
export const GraphTooltip = ({ width, height, data }: GraphTooltipProps) => {
  return (
    <Wrapper width={width} height={height}>
      <Header>
        <HeaderTextWrapper>
          <HeaderText>{t('from')}</HeaderText>
          <MainHeaderText>{dayjs(data.start).format('HH:mm')} </MainHeaderText>
          <HeaderText>{t('to')}</HeaderText>
          <MainHeaderText>{dayjs(data.end).format('HH:mm')}</MainHeaderText>
        </HeaderTextWrapper>
      </Header>
      <Body>
        <MainHeaderText>
          {formatNumber({
            value: data.total,
            decimals: data.type === FitbitFineGrainedType.distance ? 2 : 0
          })}
        </MainHeaderText>
        <HeaderText>{t(data.unit ?? '')}</HeaderText>
      </Body>
    </Wrapper>
  )
}

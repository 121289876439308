import styled from 'styled-components'

const InfoFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  align-self: stretch;
  position: relative;
`

const InfoFieldLabel = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-medium);
  margin-left: 0.5rem;
`
const InfoField = styled.input`
  border: 0.0625rem solid var(--text-lightest);
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-primary);
  &:focus,
  active {
    border-color: var(--brand-primary-color);
    outline: none;
  }
`

interface InfoFieldComponentProps {
  label: string
  value: string | undefined
  register: any
}
export const FormFieldComponent = ({
  label,
  value,
  register
}: InfoFieldComponentProps) => {
  return (
    <InfoFieldWrapper>
      <InfoFieldLabel>{label}</InfoFieldLabel>
      <InfoField
        {...register}
        placeholder={value}
        data-testId={label + 'input'}
      ></InfoField>
    </InfoFieldWrapper>
  )
}

import { faFileArrowUp } from '@fortawesome/pro-regular-svg-icons'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ApplicationUploadActivityType,
  FileUploadType,
  UploadContext
} from '../../constants'
import { Icon, Text } from '../UploadContext'
import { UploadContextGroup } from '../UploadContextGroup/UploadContextGroup'

const fileUploadTypeString = (fileUploadType: FileUploadType) => {
  switch (fileUploadType) {
    case FileUploadType.legacystenopool:
      return 'Legacy Stenopool'
    case FileUploadType.medtroniccarelink:
      return 'Medtronic Carelink'
    case FileUploadType.abbottlibreview:
      return 'Abbott Libreview'
    case FileUploadType.dexcomclarity:
      return 'Dexcom Clarity'
  }
}

export const FileUploadContext = ({
  context
}: {
  context: Extract<
    UploadContext,
    {
      type: ApplicationUploadActivityType.File
    }
  >
}) => {
  const { t } = useTranslation()
  const group: ReactNode[] = [
    <Icon icon={faFileArrowUp}></Icon>,
    <Text>{t('File')}</Text>
  ]
  if (context.fileUploadType !== null) {
    group.push(<Text>{fileUploadTypeString(context.fileUploadType)}</Text>)
  }

  return <UploadContextGroup type={context.type} group={group} />
}

import { Dispatch, SetStateAction } from 'react'
import { UseFormSetValue } from 'react-hook-form'

export enum MRNTypes {
  dkcpr = 'dk-cpr',
  svpersonnummer = 'sv-personnummer',
  nofoedselsnummer = 'no-foedselsnummer',
  foptal = 'fo-ptal',
  dev = 'dev'
}
export interface ValdiationFunctionProps {
  setPatientError: (value: string | undefined) => void
  getValues: (field?: string | string[] | undefined) => any
  setCurrentMRN: Dispatch<SetStateAction<string>>
  currentMRN: string
  setValue: UseFormSetValue<{ MRN: string; [any: string]: string }>
}

import { faCamera } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MutableRefObject, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { CSSProperties } from 'styled-components'
import { usePatientContext } from '../../../core/contexts/patient-context'
import { usePeriodContext } from '../../../core/contexts/period-context'
import { useSiteSettingsContext } from '../../../core/contexts/site-settings-context'
import { MRNTypes } from '../../../helpers/MRNValidation/interfaces'
import { handleScreenshot } from '../handleScreenShot/handleScreenShot'

export enum ScreenShotIconSize {
  small = 'small',
  medium = 'medium',
  large = 'large'
}
interface ScreenShotIconProps {
  $showIcon: boolean
  $iconSize: ScreenShotIconSize
}
export enum PeriodPosition {
  topLeft = 'topLeft',
  topRight = 'topRight',
  bottomLeft = 'bottomLeft',
  bottomRight = 'bottomRight'
}
export type PeriodPlacement =
  | {
      position: PeriodPosition.topLeft
      margin: {
        top: number
        left: number
      }
    }
  | {
      position: PeriodPosition.topRight
      margin: {
        top: number
        right: number
      }
    }
  | {
      position: PeriodPosition.bottomLeft
      margin: {
        bottom: number
        left: number
      }
    }
  | {
      position: PeriodPosition.bottomRight
      margin: {
        bottom: number
        right: number
      }
    }
export interface WrapperPadding {
  width?: number
  height?: number
}

interface ScreenshotComponentProps {
  showIcon: boolean
  index: number
  refs: {
    wrapper: MutableRefObject<HTMLDivElement | null> | null
  }
  size: ScreenShotIconSize
  periodPlacement?: PeriodPlacement
  buttonWrapperStyle?: CSSProperties
  wrapperPadding?: WrapperPadding
  border: boolean
  ignoreTransform?: true
}

const getSize = (size: ScreenShotIconSize) => {
  switch (size) {
    case ScreenShotIconSize.small:
      return { size: '1.5rem', font: '0.625rem', radius: '0.25rem' }
    case ScreenShotIconSize.medium:
      return { size: '2rem', font: '0.875rem', radius: '0.5rem' }
    case ScreenShotIconSize.large:
      return { size: '2.5rem', font: '1rem', radius: '0.5rem' }
  }
}
const ScreenshotWrapper = styled.div<ScreenShotIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  box-sizing: border-box;
  font-size: ${(props) => getSize(props.$iconSize).font};
  width: ${(props) => getSize(props.$iconSize).size};
  height: ${(props) => getSize(props.$iconSize).size};
  padding: 0.25rem 0.5rem;
  background-color: var(--element-bg);
  border-radius: ${(props) => getSize(props.$iconSize).radius};
  border: 0.0625rem solid var(--text-lightest);
  cursor: pointer;
  color: var(--text-medium);
  &:hover {
    color: var(--white-color);
    background-color: var(--blue);
    border: 0.0625rem solid var(--blue);
  }
  opacity: ${(props) => (props.$showIcon ? '1' : '0')};
`
const ScreenShotIcon = styled(FontAwesomeIcon)`
  font-weight: 900;
  font-size: inherit;
  color: inherit;
`
const ScreenshotBorder = styled.div<{
  $showBorder: boolean
  $wrapperPadding: WrapperPadding | undefined
}>`
  border-radius: 1.5rem;
  border: 0.0625rem solid rgba(255, 255, 255, 0);
  height: ${(props) =>
    `calc(100% + ${props.$wrapperPadding?.height ?? '16'}px)`};
  width: ${(props) => `calc(100% + ${props.$wrapperPadding?.width ?? '16'}px)`};
  transform: ${(props) =>
    `translateX(-${
      props.$wrapperPadding?.width ? props.$wrapperPadding?.width / 2 : '8'
    }px) translateY(-${
      props.$wrapperPadding?.height ? props.$wrapperPadding?.height / 2 : '8'
    }px)`};
  position: absolute;
  top: 0;
  left: 0;
  border-color: ${(props) =>
    props.$showBorder ? 'var(--blue)' : 'rgba(255,255,255,0)'};
`

export const ScreenshotComponent = ({
  showIcon,
  index,
  refs,
  periodPlacement,
  buttonWrapperStyle,
  size,
  wrapperPadding,
  border,
  ignoreTransform
}: ScreenshotComponentProps) => {
  const { t } = useTranslation()
  const icon = useRef<HTMLDivElement | null>(null)
  const [showBorder, setShowBorder] = useState<boolean>(false)
  const { period } = usePeriodContext()
  const [toggleBorder, setToggleBorder] = useState<boolean>(false)
  const { patient } = usePatientContext()
  const { siteSettings } = useSiteSettingsContext()
  return (
    <>
      {border && (
        <ScreenshotBorder
          data-testid="screenshotborder"
          $showBorder={toggleBorder === true ? false : showBorder}
          $wrapperPadding={wrapperPadding}
        ></ScreenshotBorder>
      )}
      <ScreenshotWrapper
        ref={icon}
        style={buttonWrapperStyle}
        $iconSize={size}
        $showIcon={showIcon}
        onMouseEnter={() => setShowBorder(true)}
        onMouseLeave={() => setShowBorder(false)}
        onClick={() =>
          handleScreenshot({
            refs: { wrapper: refs.wrapper?.current, icon: icon.current },
            period,
            t,
            setToggleBorder,
            periodPlacement,
            wrapperPadding,
            ignoreTransform,
            cpr: patient?.mrn,
            mrnType: siteSettings?.mrnType ?? MRNTypes.dkcpr
          })
        }
        data-testid={'ScreenShotButton' + index}
      >
        <ScreenShotIcon
          id={'ScreenShotButton' + index}
          icon={faCamera}
        ></ScreenShotIcon>
      </ScreenshotWrapper>
    </>
  )
}

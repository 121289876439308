import { Cell, flexRender, Row } from '@tanstack/react-table'
import { useState } from 'react'
import styled, { css } from 'styled-components'

export const Td = styled.td<{ $options?: boolean; $context?: boolean }>`
  border-bottom: 0.0625rem solid var(--text-lightest);
  border-left: 0.0625rem solid var(--text-lightest);
  &:last-child {
    border-right: 0.0625rem solid var(--text-lightest);
  }
  padding: 0.375rem 0.75rem 0.375rem 0.5rem;
  box-sizing: border-box;
  font-family: inter;
  font-size: 0.875rem;
  color: var(--text-primary);
  font-weight: 600;
  height: 3rem;
  ${(props) =>
    props.$options === true
      ? css`
          border-left: 0 !important;
          text-align: right;
        `
      : css`
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        `}
  ${(props) =>
    props.$context === true
      ? css`
          overflow-x: scroll;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          &::-webkit-scrollbar {
            display: none;
          }
        `
      : css``}
`

export function DataTableRow<T>({ row }: { row: Row<T> }) {
  const [hovered, setHovered] = useState(false)

  function getRowContent<T>({ cell }: { cell: Cell<T, unknown> }) {
    if (cell.column.id === 'options') {
      return hovered ? (
        flexRender(cell.column.columnDef.cell, cell.getContext())
      ) : (
        <></>
      )
    } else {
      return flexRender(cell.column.columnDef.cell, cell.getContext())
    }
  }
  return (
    <tr>
      {row.getVisibleCells().map((cell) => (
        <Td
          key={cell.id}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          $context={cell.column.id === 'upload_context'}
          $options={cell.column.id === 'options'}
        >
          {getRowContent({ cell })}
        </Td>
      ))}
    </tr>
  )
}

import { faBackward } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'
import ScrollContainer from 'react-indiana-drag-scroll'
import styled from 'styled-components'
import dayjs from '../../../core/dayjs/dayjs'
import { PeriodObject } from '../../Header/DevicesAndPeriod/DevicesAndPeriod'
import {
  InformationLogoSize,
  InformationTooltip
} from '../../InformationTooltip/InformationTooltip'
import { timeDataObject } from '../TimeSelector'
import { DateText, Line, TimeStamp } from '../TimeStamp/TimeStamp'

type TimeLineProps = {
  icon: any
  periodState: PeriodObject | null
  data: Array<timeDataObject> | null
  scrollWrapper: MutableRefObject<HTMLDivElement | null>
}
type TimelineDataProps = {
  width: number
  position: number
}

const TodayText = styled.div`
  font-size: 0.75rem;
  white-space: nowrap;
  color: var(--brand-primary-color);
  direction: ltr;
  margin-top: 0.25rem;
`
export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`
export const InfoCircle = styled(FontAwesomeIcon)`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.75rem;
`

const TimelineData = styled.div<TimelineDataProps>`
  width: ${(props) => props.width}%;
  height: calc(100% - 0.125rem);
  background: var(--inRange-50-color);
  border-top: 0.0625rem solid var(--inRange-color);
  border-bottom: 0.0625rem solid var(--inRange-color);
  position: absolute;
  right: ${(props) => props.position}%;
  bottom: 0;
`
const TimelineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: visible;
`
const TimelineScroller = styled(ScrollContainer)`
  overflow-x: scroll;
  overflow-y: visible;
  width: 100%;
  height: 5rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
  direction: rtl;
  dsiplay: flex;
  flex-direction: column;
  cursor: grabbing;
  cursor: -webkit-grabbing;
  &::-webkit-scrollbar {
    display: none;
  }
`

const TimelineFiller = styled.div`
  width: 100%;
  height: calc(100% - 0.125rem);
  background: var(--white-color);
  position: absolute;
  top: 0.0625rem;
  border-radius: inherit;
  right: 0.03125rem;
`
const HideOverflow = styled.div`
  width: 100%;
  height: 2rem;
  background-color: var(--text-lightest);
  border-radius: 1rem;
  position: absolute;
  overflow: hidden;
`

const TimeSpanIndicatorWrapper = styled.div<TimelineDataProps>`
  width: ${(props) => props.width}%;
  right: ${(props) => props.position}%;
  position: absolute;
  height: 100%;
  top: 0.25rem;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const TimeSpanIndicator = styled.div`
  background-color: var(--transparentWhite80-color);
  border: 0.0625rem solid var(--element-stroke);
  border-radius: 0.875rem;
  height: 1.5rem;
  width: 100%;
  font-family: inter;
  color: var(--text-medium);
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  direction: ltr;
  isolation: isolate;
  box-sizing: border-box;
`
const ScrollIcon = styled(FontAwesomeIcon)`
  font-weight: 900;
  font-size: 0.875rem;
  color: var(--brand-primary-12-color);
  position: fixed;
  left: 1.375rem;
  z-index: 1;
  left: 1rem;
  bottom: 4.625rem;
`
const TimeStampsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  margin-right: 0.125rem;
`

const TimeSpanDate = styled.div``
const TimelineHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
  width: 100%;
  margin-bottom: 1.5rem;
`
const RectangleData = styled.div`
  width: 1rem;
  height: 1rem;
  border: 0.0625rem solid var(--inRange-color);
  background: var(--inRange-50-color);
  border-radius: 0.25rem;
`
const RectangleNoData = styled(RectangleData)`
  border: 0.0625rem solid var(--text-lightest);
  background: var(--white-color);
`
const TimelineTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  font-family: inter;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--text-primary);
`
const TimelineSubTitle = styled.div`
  color: var(--text-lighter);
`
const TimelineLegend = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`
const LegendText = styled.div`
  font-family: inter;
  font-weight: 700;
  font-size: 0.625rem;
  color: var(--text-lighter);
`

const TimeSpanIndicatorLine = styled(Line)`
  height: calc(4.5625rem - 0.75rem);
  transform: translateY(20%);
`
const TimeSpanText = styled.div`
  display: flex;
  flex-direction: column;
  font-family: inter;
  font-weight: 600;
  gap: 0.25rem;
  margin-right: 0.25rem;
  margin-top: 0.25rem;
  background-color: var(--white-color);
`
const EndofDataText = styled.div`
  font-family: inter;
  font-weight: 600;
  font-size: 0.75rem;
  color: var(--text-lighter);
  top: 0.5rem;
  left: 0.5rem;
  position: absolute;
  z-index: 1;
  white-space: nowrap;
`

interface DaysProps {
  days: number
}

const DataWrapper = styled.div<DaysProps>`
  width: ${(props) => 'calc((' + props.days + ' / 180) * 100%)'};
  height: 100%;
  position: relative;
`
interface TimeSpanIndicatorProps {
  periodState: PeriodObject | null
  daysSinceToday: number
}
const TimeSpanIndicatorComponent = ({
  periodState,
  daysSinceToday
}: TimeSpanIndicatorProps) => {
  const { t } = useTranslation()
  if (periodState) {
    return (
      <TimeSpanIndicatorWrapper
        width={(periodState.lengthOfPeriod / daysSinceToday) * 100}
        position={
          (dayjs(new Date()).diff(dayjs(periodState.endDate), 'day') /
            daysSinceToday) *
          100
        }
      >
        <TimeSpanIndicator>
          {periodState.lengthOfPeriod}{' '}
          {periodState.lengthOfPeriod > 14 ? `${t('days')}` : 'd'}
        </TimeSpanIndicator>
        <TimeSpanDate>
          <TimeSpanText>
            <TodayText>
              {dayjs(new Date()).diff(dayjs(periodState.endDate), 'days') < 1
                ? `${t('Today')}`
                : dayjs(periodState.endDate).fromNow()}
            </TodayText>
            <DateText>
              {periodState.endDate && periodState.endDate.getDate()}/
              {periodState.endDate && periodState.endDate.getMonth() + 1}/
              {periodState.endDate && periodState.endDate.getFullYear()}
            </DateText>
          </TimeSpanText>
          <TimeSpanIndicatorLine></TimeSpanIndicatorLine>
        </TimeSpanDate>
      </TimeSpanIndicatorWrapper>
    )
  } else {
    return <div>{t('NO DATA')}</div>
  }
}

export const TimeLine = ({
  icon,
  periodState,
  data,
  scrollWrapper
}: TimeLineProps) => {
  const daysSinceToday = data
    ? dayjs(new Date()).diff(dayjs(data[0].start), 'day')
    : 0
  const monthsSinceToday = data
    ? dayjs(new Date()).diff(dayjs(data[0].start), 'month')
    : 0
  const { t } = useTranslation()
  const TimeStamps = new Array(monthsSinceToday + 1)
    .fill(undefined)
    .map((_val, idx) => (
      <TimeStamp
        key={idx}
        index={idx}
        last={idx === monthsSinceToday}
        daysSinceToday={daysSinceToday}
      ></TimeStamp>
    ))
  const TimelineDataObjects =
    data !== null
      ? data.map((timeSlice, idx) => (
          <TimelineData
            key={idx}
            width={
              (dayjs(timeSlice.end).diff(dayjs(timeSlice.start), 'days') /
                daysSinceToday) *
              100
            }
            position={
              (dayjs(new Date()).diff(dayjs(timeSlice.end), 'days') /
                daysSinceToday) *
              100
            }
          ></TimelineData>
        ))
      : null
  setTimeout(() => {
    if (scrollWrapper.current && data) {
      scrollWrapper.current.scrollTo({
        left: periodState
          ? (dayjs(new Date()).diff(dayjs(periodState.endDate), 'day') /
              dayjs(new Date()).diff(dayjs(data[0].start), 'day')) *
            scrollWrapper.current.scrollWidth *
            -1
          : 0,
        top: 0,
        behavior: 'smooth'
      })
    }
  }, 100)

  return (
    <TimelineWrapper data-testid="TimeLineElement">
      <TimelineHeader>
        <TimelineTitle>
          <span>{t('Timeline')}</span>
          <TimelineSubTitle>
            {t('6 months in view - scroll for more')}
          </TimelineSubTitle>
          <InformationTooltip
            paragraph={[
              {
                title: t('Timeline'),
                body: t(
                  'A visual overview of days with and without data for the past 6 months.'
                )
              }
            ]}
            size={InformationLogoSize.small}
          />
        </TimelineTitle>
        <TimelineLegend>
          <Buttons>
            <RectangleData></RectangleData>
            <LegendText>{t('Period with data')}</LegendText>
          </Buttons>
          <Buttons>
            <RectangleNoData></RectangleNoData>
            <LegendText>{t('Period without data')}</LegendText>
          </Buttons>
        </TimelineLegend>
      </TimelineHeader>
      <TimelineScroller
        data-testid="TimeLineScroller"
        innerRef={scrollWrapper}
        vertical={false}
      >
        <ScrollIcon icon={faBackward}></ScrollIcon>
        <DataWrapper days={daysSinceToday}>
          <EndofDataText>{t('End of data')}</EndofDataText>
          <HideOverflow>
            <TimelineFiller />
            {TimelineDataObjects}
          </HideOverflow>
          <TimeSpanIndicatorComponent
            periodState={periodState}
            daysSinceToday={daysSinceToday}
          />
          <TimeStampsWrapper>{TimeStamps}</TimeStampsWrapper>
        </DataWrapper>
      </TimelineScroller>
    </TimelineWrapper>
  )
}

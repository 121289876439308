import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { NoActivePen } from '../../components/PenDosing/NoActivePen/NoActivePen'
import { PenDosing } from '../../components/PenDosing/PenDosing'
import { SortEnum } from '../../components/ProviderPatients/ProviderPatients'
import { Spinner, spinnerSize } from '../../components/Spinner/Spinner'
import { TitrationHistory } from '../../components/TitrationHistory/TitrationHistory'
import {
  getDoseRecommendations,
  getDoseRecommendationsResponse
} from '../../core/api/getDoseRecommendations'
import {
  getTitrationHistory,
  TitrationHistoryObject
} from '../../core/api/getTitrationHistory'
import { usePatientContext } from '../../core/contexts/patient-context'
import { useSessionContext } from '../../core/contexts/session-context'
import { UserType } from '../../core/entities/user.entity'
import { SnackBar, SnackBarType } from '../../libraries/Toast/SnackBar'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 78.5rem;
  min-width: 61.5rem;
  gap: 1rem;
  padding-right: 2rem;
`

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`

const Title = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2.25rem;
  color: var(--text-primary);
`
export const PenDosingPage = () => {
  const [history, setHistory] = useState<TitrationHistoryObject[] | null>(null)
  const [doseRecommendation, setDoseRecommendation] =
    useState<getDoseRecommendationsResponse | null>(null)
  const [error, setError] = useState<boolean>(false)
  const [update, setUpdate] = useState<boolean>(false)
  const [sorting, setSorting] = useState<SortEnum>(SortEnum.Descending)
  const { patient } = usePatientContext()
  const { user } = useSessionContext()
  const { t } = useTranslation()

  useEffect(() => {
    const getHistory = async () => {
      try {
        const historyResponse = await getTitrationHistory(
          patient?.patientId ?? '',
          10000,
          sorting
        )
        setHistory(historyResponse)
      } catch (e) {
        SnackBar({
          type: SnackBarType.Error,
          message: t('Something went wrong when getting titration history')
        })
        setError(true)
      }
    }
    const getDoseRecommendation = async () => {
      try {
        const doseRecommendationResponse = await getDoseRecommendations(
          patient?.patientId ?? ''
        )
        setDoseRecommendation(doseRecommendationResponse)
      } catch (e) {
        SnackBar({
          type: SnackBarType.Error,
          message: t('Something went wrong when getting dose recommendations')
        })
        setError(true)
      }
    }

    setError(false)
    if (patient?.patientId) {
      getHistory()
      getDoseRecommendation()
    }
  }, [patient?.patientId, t, update, sorting])

  const renderPenDosing = () => {
    if (doseRecommendation === null) {
      return <Spinner spinnersize={spinnerSize.large}></Spinner>
    } else if (
      doseRecommendation.doseRecommendation !== null &&
      doseRecommendation.novoPen !== null
    ) {
      return (
        <PenDosing
          smartPen={doseRecommendation.novoPen}
          doseRecommendation={doseRecommendation.doseRecommendation}
          isPatient={user?.type === UserType.Patient}
          updateHistory={() => setUpdate((update) => !update)}
          setDoseRecommendation={setDoseRecommendation}
        ></PenDosing>
      )
    } else {
      return <NoActivePen />
    }
  }
  const Body = () => {
    if (error) {
      return (
        <div data-testid="penDosingError">
          {t('An error has occurred, please reload the page')}
        </div>
      )
    } else {
      return (
        <>
          <Section>
            <Title>{t('Dose recommendation')}</Title>
            {renderPenDosing()}
          </Section>
          <Section>
            <Title>{t('Titration history')}</Title>
            <TitrationHistory
              history={history}
              sorting={sorting}
              setSorting={setSorting}
            />
          </Section>
        </>
      )
    }
  }

  return (
    <Container>
      <Wrapper>
        <Body />
      </Wrapper>
    </Container>
  )
}

import { faArrowRightLong } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DeviceList, devicesObject } from '../../DeviceList/DeviceList'
import { Modal } from '../../Modal/Modal'
import { PeriodObject } from '../DevicesAndPeriod/DevicesAndPeriod'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.125rem;
  box-sizing: border-box;
  cursor: pointer;
  height: 100%;
  border-right: 0.0625rem solid var(--text-lightest);
  color: var(--text-lighter);
  &:hover {
    color: var(--brand-primary-color);
    background-color: var(--element-bg);
  }
`
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items center;
  justify-content: space-between;
`
const Arrow = styled(FontAwesomeIcon)<{ $hovered: boolean }>`
  color: var(--brand-primary-color);
  font-size: 0.6875rem;
  font-weight: 900;
  display: ${(props) => (props.$hovered ? 'block' : 'none')};
`
const Title = styled.span`
  color: inherit;
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
`

const DevicesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
`
const Device = styled.div<{ $hovered: boolean }>`
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: 0.0625rem solid var(--text-lightest);
  background-color: ${(props) =>
    props.$hovered ? 'var(--white-color)' : 'var(--element-bg)'};
  color: var(--text-primary);
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
`
interface CurrentDevicesProps {
  period: PeriodObject | null
  setPeriod: (period: PeriodObject | null) => void
  setPeriodState: (value: SetStateAction<PeriodObject | null>) => void
  devices: devicesObject[] | null
  newestDeviceData: null | Array<string>
}
export const CurrentDevices = ({
  period,
  setPeriod,
  setPeriodState,
  devices,
  newestDeviceData
}: CurrentDevicesProps) => {
  const { t } = useTranslation()
  const [showDevices, setShowDevices] = useState<boolean>(false)
  const [deviceStates, setDeviceStates] = useState<null | Array<boolean>>([])
  const [hovered, setHovered] = useState<boolean>(false)

  const handleShowDevices = () => {
    setShowDevices(!showDevices)
    const states = devices ? devices.map((item) => item.checkBox) : null
    setDeviceStates(states)
  }
  const handleCheckBox = () => {
    let deviceID = ''
    if (deviceStates && devices) {
      for (let i = 0; i < deviceStates.length; i++) {
        devices[i].checkBox = deviceStates[i]
        if (deviceStates[i] === true) {
          if (deviceID.length > 0) deviceID += ','
          deviceID += devices[i].patientDeviceIds.join(',')
        }
      }
    }

    setPeriod(period ? { ...period, deviceID } : null)
    setPeriodState(period ? { ...period, deviceID } : null)
    setShowDevices(!showDevices)
  }

  const indexOfNewDevice = (name: string) =>
    devices
      ? devices.findIndex(function (device) {
          return device.deviceDisplayName === name
        })
      : 999999

  return (
    <Wrapper
      data-testid="currentDevices"
      onClick={() => {
        handleShowDevices()
        setHovered(false)
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Modal isOpen={showDevices}>
        <DeviceList
          checkBoxStates={deviceStates}
          setCheckBoxStates={setDeviceStates}
          devices={devices}
          handleShowDevices={handleShowDevices}
          handleCheckBox={handleCheckBox}
        ></DeviceList>
      </Modal>
      <TitleWrapper>
        <Title>{t('Current Devices')}</Title>
        <Arrow icon={faArrowRightLong} $hovered={hovered} />
      </TitleWrapper>
      <DevicesWrapper>
        {newestDeviceData !== null ? (
          newestDeviceData?.map((device) => (
            <Device
              data-testid="currentDevice"
              key={device}
              $hovered={hovered}
              style={
                deviceStates && indexOfNewDevice(device) !== undefined
                  ? deviceStates[indexOfNewDevice(device)] === false
                    ? {
                        backgroundColor: 'var(--element-bg-dark)',
                        color: 'var(--text-lighter)'
                      }
                    : {}
                  : {}
              }
            >
              {device}
            </Device>
          ))
        ) : (
          <span>{t('No devices')}</span>
        )}
      </DevicesWrapper>
    </Wrapper>
  )
}
